import React from "react";

function IconBuilding(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "17";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M.75 24a.75.75 0 010-1.5H3V3.75a.723.723 0 01.064-.3c.005-.014.012-.028.018-.041L4.579.415A.746.746 0 015.25 0h13.5c.286 0 .543.159.671.415l1.5 3 .017.039a.69.69 0 01.042.126l.003.013A.725.725 0 0121 3.75V22.5h2.25a.75.75 0 010 1.5H.75zm18.75-1.5v-18h-15v18H9V21c0-1.654 1.346-3 3-3s3 1.346 3 3v1.5h4.5zm-6 0V21c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v1.5h3zM19.037 3l-.75-1.5H5.714L4.964 3h14.073z"
      ></path>
      <path
        fill={color}
        d="M7.5 7.875a.717.717 0 01-.131-.012 1.125 1.125 0 01-.982-.982.717.717 0 010-.262c.06-.513.47-.922.982-.982a.717.717 0 01.262 0c.513.06.922.47.982.982a.717.717 0 010 .262c-.06.513-.47.922-.982.982a.717.717 0 01-.131.012zM7.5 12.375a.817.817 0 01-.131-.011 1.126 1.126 0 01-.982-.982.717.717 0 010-.262c.06-.513.47-.922.982-.982a.717.717 0 01.262 0c.513.06.922.47.982.982a.717.717 0 010 .262c-.06.513-.47.922-.982.982a.817.817 0 01-.131.011zM7.5 16.875a.796.796 0 01-.13-.011 1.126 1.126 0 01-.983-.982.717.717 0 010-.262c.06-.513.47-.922.983-.983a.796.796 0 01.26 0c.513.061.923.47.983.983a.717.717 0 010 .262c-.06.513-.47.922-.983.982a.796.796 0 01-.13.011zM12 7.875a.717.717 0 01-.131-.012 1.125 1.125 0 01-.982-.982.717.717 0 010-.262c.06-.513.47-.922.982-.982a.717.717 0 01.262 0c.513.06.922.47.983.983a.796.796 0 010 .26c-.061.513-.47.922-.983.983a.717.717 0 01-.131.012zM12 12.375a.817.817 0 01-.131-.011 1.126 1.126 0 01-.982-.982.717.717 0 010-.262c.06-.513.47-.922.982-.982a.717.717 0 01.262 0c.513.06.922.47.983.983a.796.796 0 010 .26c-.061.513-.47.922-.983.983a.817.817 0 01-.131.011zM12 16.875a.796.796 0 01-.13-.011 1.126 1.126 0 01-.983-.982.717.717 0 010-.262c.06-.513.47-.922.983-.983a.796.796 0 01.26 0c.513.061.923.47.983.983a.808.808 0 010 .262 1.126 1.126 0 01-1.113.993zM16.5 7.875a.817.817 0 01-.131-.011 1.125 1.125 0 01-.982-.983.698.698 0 010-.26c.06-.513.47-.923.982-.983a.717.717 0 01.262 0c.513.06.922.47.983.983a.796.796 0 010 .26c-.061.513-.47.923-.983.983a.817.817 0 01-.131.011zM16.5 12.375a.817.817 0 01-.131-.011 1.126 1.126 0 01-.982-.983.698.698 0 010-.26c.06-.513.47-.923.982-.983a.717.717 0 01.262 0c.513.06.922.47.983.983a.796.796 0 010 .26c-.061.513-.47.922-.983.983a.817.817 0 01-.131.011zM16.5 16.875a.796.796 0 01-.13-.011 1.126 1.126 0 01-.983-.983.796.796 0 010-.261c.06-.513.47-.923.983-.983a.808.808 0 01.262 0c.513.06.923.47.983.983a.808.808 0 010 .262 1.126 1.126 0 01-1.115.993z"
      ></path>
    </svg>
  );
}

export default IconBuilding;
