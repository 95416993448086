import React, { useEffect, useState } from "react";
import "../../scss/components/layout/_notifications_menu.scss";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { IconBell, IconTrash } from "../../assets/icons";
import notificationsApi from "../../api/notifications.api";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import { DateUtils } from "../../utils/DateUtils";
import Loader from "../general/Loader";

function NotificationsMenu(props) {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");

  const { location, isOpen, toggle, excludeLocations } = props;
  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });

  const descriptionTemplates = new Map([
    [
      "ReleaseRulesChange",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `Automatic release rule changed for the captive code ${data.CaptiveCode}.`;
        },
      },
    ],
    [
      "TransferRequestInitiated",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `Funds have been requested from the account ending in *${data.TrustAccountNumber}.`;
        },
      },
    ],
    [
      "TransferRequestProcessing",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `Your ${data.ReleaseType} transfer from account ending in *${data.TrustAccountNumber} is being processed.`;
        },
      },
    ],
    [
      "TransferRequestOverdue",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `Funds transfer bank authorization for the account ending in *${data.TrustAccountNumber} is past due.`;
        },
      },
    ],
    [
      "BankAccountsChange",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `A change has been made to the account ending in *${data.TrustAccountNumber}.`;
        },
      },
    ],
    [
      "NewCessionsAvailable",
      {
        Subtitle: (data) => {
          return `${data.CaptiveName}`;
        },
        Description: (data) => {
          return `New cession statement available for the captive code ${data.CaptiveCode}.`;
        },
      },
    ],
    [
      "PeriodOpening",
      {
        Subtitle: () => t("Transfer Period Open"),
        Description: () => {
          return `Transfer period open. Fund release requests will now be processed.`;
        },
      },
    ],
    [
      "PeriodClosing",
      {
        Subtitle: () => t("Transfer Period Closing"),
        Description: () => {
          return `Transfer period closing in three (3) business days. Make requests now.`;
        },
      },
    ],
  ]);

  const dismissSingleNotification = (id) => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          await notificationsApi.dismissSingleNotification(id);
          await fetchNotificationsData();
          setErrorText("");
        } catch (e) {
          setErrorText(t("Error dismissing the notifications."));
        }
      });
    }
  };

  const dismissAllNotifications = async () => {
    if (authContext.isAuthenticated) {
      try {
        await notificationsApi.dismissAllNotifications();
        await fetchNotificationsData();
        setErrorText("");
      } catch (e) {
        setErrorText(t("Error dismissing the notifications."));
      }
    }
  };

  const renderNotification = (
    id,
    moduleName,
    captiveName,
    description,
    date
  ) => {
    return (
      <div key={id} className="notification-content pt-3">
        <div className="row">
          <div className="col-8">
            <p className="mb-0">{moduleName}</p>
            <p className="mb-0">{captiveName}</p>
          </div>
          <div className="col-4 text-right">
            {DateUtils.formatLocalDate(date)}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-11">
            <p className="pt-1">{description}</p>
          </div>
          <div className="col-1 icon-action">
            <i onClick={() => dismissSingleNotification(id)}>
              <IconTrash height={14} />
            </i>
          </div>
        </div>
      </div>
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotificationsData = async () => {
    setIsLoading(true);
    try {
      const response = await notificationsApi.getUserNotifcations({
        page: 0,
        pageSize: 1500,
      });
      setNotifications(response.data);
      setErrorText("");
    } catch (e) {
      setErrorText(t("Error loading the list of notifications."));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      (async () => {
        await fetchNotificationsData();
      })();
    }
  }, [authContext.isAuthenticated]);

  return !(excludeLocations.includes(location.pathname) || isActivate) ? (
    // TODO: connect to wherever notifications will be coming from.

    <div className="d-flex justify-content-center flex-nowrap">
      {isLoading && <Loader />}
      <div
        className={classNames("notifications-menu-mask", { "is-open": isOpen })}
        onClick={toggle}
      ></div>
      <div
        className={classNames(
          "card",
          "notifications-menu-container",
          "shadow",
          { "is-open": isOpen }
        )}
      >
        <div className="card-header d-flex flex-nowrap justify-content-between">
          <div>
            <i>
              <IconBell />
            </i>{" "}
            {t("Notifications")}
          </div>
          <p>
            <span onClick={dismissAllNotifications} className="text-link">
              Delete All
            </span>
          </p>
        </div>
        <div className="card-body p-3">
          {errorText && (
            <div className="text-center mb-3 text-danger">{errorText}</div>
          )}
          {notifications.map((n) => {
            const parsedData = JSON.parse(n.data);
            const template = descriptionTemplates.get(n.notificationType);
            return renderNotification(
              n.id,
              n.moduleName,
              template.Subtitle(parsedData),
              template.Description(parsedData),
              n.createdAt
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default withRouter(NotificationsMenu);
