import React from "react";
import { IconSortArrowDown, IconSortArrowUp } from "../../assets/icons/";

export function sortCaret(order, column) {
  if (!order) return null;
  else if (order === "asc")
    return (
      <span>
        <i>
          <IconSortArrowUp color="#54698D" />
        </i>
      </span>
    );
  else if (order === "desc")
    return (
      <span>
        <i>
          <IconSortArrowDown color="#54698D" />
        </i>
      </span>
    );
  return null;
}
