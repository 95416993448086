import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SummaryBox from "../../components/general/SummaryBox";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../Auth";
import { useDispatch } from "react-redux";
import {
  IconBankTransfer,
  IconBanksConnected,
  IconBankWithArrows,
} from "../../assets/icons";
import clientsApi from "../../api/clients.api";
import PendingReleases from "../../components/transactions/PendingReleases";

const ClientSummary = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useAuthContext();
  const dispatch = useDispatch();
  const [clientSummary, setClientSummary] = useState({
    banksConnected: 0,
    pendingReleases: 0,
    autoReleases: 0,
    manualReleases: 0,
  });
  const [
    showPendingTransactionsModal,
    setShowPendingTransactionsModal,
  ] = useState(false);

  const viewAccounts = () => {
    history.push("/bankaccounts");
  };

  const viewStatus = () => {
    setShowPendingTransactionsModal(true);
  };

  const viewSettings = () => {
    history.push("/releaserules");
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        const result = await clientsApi.getSummary();
        setClientSummary(result);
      });
    }
  }, [authContext.isAuthenticated, dispatch]);

  const banksTransferIcon = (arrowsColor) => {
    return <IconBankTransfer height="30" arrowsColor={arrowsColor} />;
  };

  const banksConnectedIcon = (arrowsColor) => {
    return <IconBanksConnected height="30" arrowsColor={arrowsColor} />;
  };

  const bankWithArrowsIcon = (arrowsColor) => {
    return <IconBankWithArrows height="30" arrowsColor={arrowsColor} />;
  };

  return (
    <div className="ClientSummary col-xxl-12 col-xl-12 col-lg-6 col-md-12 col-sm-12">
      <PendingReleases
        isOpen={showPendingTransactionsModal}
        onCancel={() => setShowPendingTransactionsModal(false)}
        culture="en-US"
        currentPeriod={new Date()}
      />
      <div className="d-flex justify-content-between">
        <span>{t("Client Summary")}</span>
      </div>
      <div>
        <SummaryBox
          icon={banksConnectedIcon("green")}
          number={clientSummary.banksConnected}
          text={t("Bank(s) Connected")}
          linkAction={viewAccounts}
        />
        <SummaryBox
          icon={banksTransferIcon("green")}
          number={clientSummary.pendingReleases}
          text={t("Pending Release(s)")}
          linkAction={viewStatus}
        />
        <SummaryBox
          icon={bankWithArrowsIcon("green")}
          number={clientSummary.autoReleases}
          text={t("Auto Release(s)")}
          linkAction={viewSettings}
        />
        <SummaryBox
          icon={bankWithArrowsIcon("")}
          number={clientSummary.manualReleases}
          text={t("Manual Release(s)")}
          linkAction={viewSettings}
        />
      </div>
    </div>
  );
};

export default ClientSummary;
