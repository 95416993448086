import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./i18n";
import App from "./App";
import ConfigLoader from "./components/ConfigLoader";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import appStore from "./features/store";
import WebFont from "webfontloader";

// TODO: Find the Trebuchet MS font
WebFont.load({
  google: {
    families: ["Open Sans:300,400,700", "sans-serif"],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <Router>
        <ConfigLoader ready={() => <App />} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
