import api from ".";
import { DateUtils } from "../utils/DateUtils";

const downloadAvailableBalances = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/periodsSummary`;
  return api.download(
    fetchApiUrl,
    params,
    "text/csv",
    `availablebalances_${DateUtils.todayFormatted()}.csv`,
    {}
  );
};

const getNetDues = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/netdue`;
  return api.get(fetchApiUrl, params, {});
};

const downloadNetDues = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/netdue`;
  return api.download(
    fetchApiUrl,
    params,
    "text/csv",
    `netdues_${DateUtils.todayFormatted()}.csv`,
    {}
  );
};

const getPeriods = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/periods`;
  return api.get(fetchApiUrl, params, {});
};

const getPeriodsforBankStatement = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/Bankperiods`;
  return api.get(fetchApiUrl, params, {});
};

const getPeriodsSummary = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/periodsSummary`;
  return api.get(fetchApiUrl, params, {});
};

const getPeriodActivityData = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/${
    params.clientId
  }/periodActivity/${params.cessionPeriodBalanceId}`;
  return api.get(fetchApiUrl, {}, {});
};

const downloadPeriodsSummary = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/periodsSummary/details`;
  return api.download(
    fetchApiUrl,
    params,
    "text/csv",
    `monthlydetails_${DateUtils.todayFormatted()}.csv`,
    {}
  );
};

const getBlackoutPeriodLength = async () => {
  const fetchApiUrl = `${api.getApiUrl()}cession/blackout-length`;
  return api.get(fetchApiUrl, null);
};

const getCurrentCessionBalanceInfo = async (cessionBalanceId) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/${cessionBalanceId}`;
  return api.get(fetchApiUrl, null);
};

const getBlackOutData = async () => {
  const fetchApiUrl = `${api.getApiUrl()}cession/blackout-data`;
  return api.get(fetchApiUrl, null);
};

export default {
  downloadAvailableBalances,
  getNetDues,
  downloadNetDues,
  getPeriods,
  getPeriodsforBankStatement,
  getPeriodsSummary,
  getPeriodActivityData,
  downloadPeriodsSummary,
  getBlackoutPeriodLength,
  getCurrentCessionBalanceInfo,
  getBlackOutData,
};
