import api from ".";

const getUserNotifcations = async (params) => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications`;
    return api.get(fetchApiUrl, params, {});
  };

const dismissAllNotifications = async () => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications`;
    return api.post(fetchApiUrl, {UserNotificationId: null, DismissAll: true}, {});
  };

const dismissSingleNotification = async (id) => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications`;
    return api.post(fetchApiUrl, {UserNotificationId: id, DismissAll: false}, {});
  };

const getUserNotificationsCount = async() => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications/count`;
    return api.get(fetchApiUrl, {}, {});
}

const getUserNotificationsSettings = async() => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications/settings`;
    return api.get(fetchApiUrl, {}, {});
}

const updateUserNotificationSetting = async(params) => {
    const fetchApiUrl = `${api.getApiUrl()}UserNotifications/settings`;
    return api.put(fetchApiUrl, params, {});
}

export default {
    getUserNotifcations,
    dismissAllNotifications,
    dismissSingleNotification,
    getUserNotificationsCount,
    getUserNotificationsSettings,
    updateUserNotificationSetting,
}