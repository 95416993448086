import React from "react";
import { IconTrash } from "../assets/icons";

export default function TooltipButton() {
  return (
    <div style={{ position: "relative" }}>
      <span className="table-header-tooltip-icon mr-3 mt-1">
        <IconTrash color={"red"} />
        <span className="table-header-tooltip button-tooltip">{"Delete"}</span>
      </span>
    </div>
  );
}
