import React from "react";

function IconSMS(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M15.751,23.999c-1.241,0-2.25-1.009-2.25-2.25v-4.5c0-0.96,0.615-1.808,1.5-2.121v-0.879c0-2.068,1.682-3.75,3.75-3.75
            s3.75,1.682,3.75,3.75v0.879c0.885,0.314,1.5,1.162,1.5,2.121v4.5c0,1.241-1.009,2.25-2.25,2.25H15.751z M15.751,16.499
            c-0.414,0-0.75,0.336-0.75,0.75v4.5c0,0.414,0.336,0.75,0.75,0.75h6c0.414,0,0.75-0.336,0.75-0.75v-4.5
            c0-0.414-0.336-0.75-0.75-0.75H15.751z M21.001,14.999v-0.75c0-1.24-1.009-2.25-2.25-2.25s-2.25,1.009-2.25,2.25v0.75H21.001z"
      />
      <circle fill={color} cx="18.751" cy="19.528" r="1.125" />
      <path
        fill={color}
        d="M0.751,23.999c-0.2,0-0.388-0.078-0.53-0.219c-0.224-0.224-0.283-0.567-0.147-0.853l2.908-6.113
            c-0.845-1.481-1.349-3.155-1.46-4.856C1.325,8.961,2.312,6.063,4.298,3.8s4.731-3.618,7.729-3.813
            c0.249-0.016,0.502-0.025,0.751-0.025c2.723,0,5.355,0.994,7.411,2.798c2.261,1.984,3.614,4.731,3.81,7.733
            c0.027,0.413-0.287,0.77-0.7,0.797c-0.016,0.001-0.033,0.002-0.05,0.002c-0.394,0-0.722-0.308-0.747-0.701
            C22.333,7.988,21.16,5.608,19.2,3.887c-1.782-1.564-4.064-2.426-6.426-2.426c-0.215,0-0.434,0.007-0.649,0.021
            c-2.602,0.17-4.983,1.343-6.703,3.303c-1.72,1.96-2.574,4.473-2.404,7.075c0.104,1.597,0.606,3.159,1.45,4.518
            c0.135,0.218,0.151,0.486,0.04,0.718l-2.172,4.566l4.563-2.175c0.1-0.048,0.212-0.073,0.323-0.073c0.14,0,0.278,0.039,0.397,0.114
            c1.133,0.707,2.377,1.166,3.698,1.365c0.198,0.03,0.373,0.135,0.492,0.296c0.119,0.161,0.168,0.359,0.138,0.557
            c-0.055,0.364-0.373,0.638-0.741,0.638c-0.037,0-0.074-0.003-0.112-0.008c-1.386-0.209-2.701-0.667-3.911-1.362l-6.108,2.911
            C0.973,23.974,0.861,23.999,0.751,23.999z"
      />
    </svg>
  );
}

export default IconSMS;
