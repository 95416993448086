import React from "react";

function IconTags(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
    <path fill={color}
        d="M14.2501 23.378C13.9546 23.3788 13.6618 23.3211 13.3887 23.2081C13.1157 23.0952 12.8677 22.9293 12.6591 22.72L1.09805 11.158C0.748591 10.8108 0.47144 10.3977 0.28263 9.94264C0.0938194 9.48761 -0.00290384 8.99966 -0.00194608 8.50701V2.25001C-0.00141596 1.65309 0.236082 1.0808 0.658356 0.658897C1.08063 0.236998 1.65313 8.52055e-06 2.25005 8.75595e-06H8.50705C8.99972 -0.00105941 9.4877 0.0956135 9.94275 0.28443C10.3978 0.473247 10.8109 0.750457 11.1581 1.10001L22.7191 12.659C23.1403 13.0814 23.3768 13.6535 23.3768 14.25C23.3768 14.8465 23.1403 15.4187 22.7191 15.841L15.8401 22.721C15.6316 22.9302 15.3838 23.096 15.1109 23.2088C14.8379 23.3216 14.5454 23.3791 14.2501 23.378ZM2.25005 1.50001C2.05114 1.50001 1.86038 1.57903 1.71972 1.71968C1.57907 1.86033 1.50005 2.0511 1.50005 2.25001V8.50701C1.49923 8.80261 1.55705 9.09543 1.67017 9.36853C1.78329 9.64163 1.94946 9.88957 2.15905 10.098L13.7201 21.66C13.8608 21.8002 14.0514 21.8789 14.2501 21.8789C14.4487 21.8789 14.6393 21.8002 14.7801 21.66L21.6591 14.78C21.7993 14.6391 21.8781 14.4483 21.8781 14.2495C21.8781 14.0507 21.7993 13.8599 21.6591 13.719L10.0981 2.15801C9.88955 1.94884 9.64171 1.78302 9.36882 1.67009C9.09593 1.55715 8.80339 1.49935 8.50805 1.50001H2.25005Z"/>
    <path fill={color}
        d="M6 8.25C5.55499 8.25 5.11998 8.11804 4.74997 7.87081C4.37996 7.62357 4.09157 7.27217 3.92127 6.86104C3.75097 6.44991 3.70642 5.9975 3.79323 5.56105C3.88005 5.12459 4.09434 4.72368 4.40901 4.40901C4.72368 4.09434 5.12459 3.88005 5.56105 3.79323C5.9975 3.70642 6.44991 3.75097 6.86104 3.92127C7.27217 4.09157 7.62357 4.37996 7.87081 4.74997C8.11804 5.11998 8.25 5.55499 8.25 6C8.24947 6.59658 8.01225 7.16856 7.59041 7.59041C7.16856 8.01225 6.59658 8.24947 6 8.25ZM6 5.25C5.85167 5.25 5.70666 5.29399 5.58332 5.3764C5.45999 5.45881 5.36386 5.57594 5.30709 5.71299C5.25033 5.85003 5.23547 6.00083 5.26441 6.14632C5.29335 6.2918 5.36478 6.42544 5.46967 6.53033C5.57456 6.63522 5.7082 6.70665 5.85368 6.73559C5.99917 6.76453 6.14997 6.74968 6.28701 6.69291C6.42406 6.63615 6.54119 6.54002 6.6236 6.41668C6.70601 6.29334 6.75 6.14834 6.75 6C6.75 5.80109 6.67098 5.61032 6.53033 5.46967C6.38968 5.32902 6.19891 5.25 6 5.25Z"/>

    </svg>
  );
}

export default IconTags;
