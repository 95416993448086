import { createSlice } from "@reduxjs/toolkit";
import notificationsApi from "../../api/notifications.api";

export const notificationsSlice = createSlice({
  name: "notificationsStore",
  initialState: {
    isLoading: false,
    count: null,
    notifications: [],
    loadError: null,
  },
  reducers: {
    loadNotificationsCountStart: (state) => {
      state.isLoading = true;
    },
    loadNotificationsCountSuccess: (state, action) => {
      state.count = action.payload;
      state.isLoading = false;
    },
    loadNotificationsCountFailure: (state, action) => {
      state.isLoading = false;
      state.loadError = action.payload.message;
    },
  },
});

export const selectNotificationsCountIsLoading = (state) => state.notificationsStore.isLoading;
export const selectNotificationsCountLoadError = (state) => state.notificationsStore.loadError;
export const selectNotificationsCount = (state) => state.notificationsStore.count;

// Don't expose to rest of the app.
const {
  loadNotificationsCountStart,
  loadNotificationsCountSuccess,
  loadNotificationsCountFailure,
} = notificationsSlice.actions;

export const loadNotificationsCount = () => async (dispatch) => {
  dispatch(loadNotificationsCountStart());
  try {
    let response = await notificationsApi.getUserNotificationsCount();
    dispatch(loadNotificationsCountSuccess(response));
  } catch (err) {
    console.error(err);
    dispatch(loadNotificationsCountFailure({ message: "Unable to load notifications count for UI." }));
  }
};

export default notificationsSlice.reducer;
