import React from "react";
import "../../scss/components/transactions/_transaction_status_indicator.scss";

function PendingStatusIndicator(props) {
  return (
    <div className={`step step-hover`} data-tip={props.tooltipText}>
      <div className="step-indicator">
        <hr />
        <div className="circle"></div>
        <hr />
      </div>
    </div>
  );
}

export default PendingStatusIndicator;
