import { createSlice } from "@reduxjs/toolkit";
import { statusData } from "../../api/index.js";
import transferRequestApi from "../../api/transferRequest.api";

const {
  initialStatus,
  loadStatus,
  successStatus,
  failureStatus,
  copyStatus,
} = statusData;

export const transferRequestSlice = createSlice({
  name: "transferRequestStore",
  initialState: {
    createdTransactionId: "",
    createTransferRequestStatus: copyStatus(initialStatus),
    getTransferRequestDetailsStatus: copyStatus(initialStatus),
    transferRequestDetails: null,
  },
  reducers: {
    createTransferRequestStart: (state) => {
      state.createTransferRequestStatus = copyStatus(loadStatus);
    },
    createTransferRequestSuccess: (state, action) => {
      state.createTransferRequestStatus = copyStatus(successStatus);
      state.createdTransactionId = action.payload;
    },
    createTransferRequestFailure: (state) => {
      state.createTransferRequestStatus = copyStatus(failureStatus);
    },
    resetCreateTransferRequestStatus: (state) => {
      state.createTransferRequestStatus = copyStatus(initialStatus);
    },
    getTransferRequestDetailsStart: (state) => {
      state.getTransferRequestDetailsStatus = copyStatus(loadStatus);
    },
    getTransferRequestDetailsSuccess: (state, action) => {
      state.getTransferRequestDetailsStatus = copyStatus(successStatus);
      state.transferRequestDetails = action.payload;
    },
    getTransferRequestDetailsFailure: (state) => {
      state.getTransferRequestDetailsStatus = copyStatus(failureStatus);
    },
    resetGetTransferRequestDetailsStatus: (state) => {
      state.getTransferRequestDetailsStatus = copyStatus(initialStatus);
    },
  },
});

// the state is the Root state, so you need to reference the transferRequestStore here
// SELECT
export const selectCreateTransferRequestStatus = (state) =>
  state.transferRequestStore.createTransferRequestStatus;

export const selectGetTransferRequestDetailsStatus = (state) =>
  state.transferRequestStore.getTransferRequestDetailsStatus;

export const selectTransferRequestDetails = (state) =>
  state.transferRequestStore.transferRequestDetails;

export const selectCurrentCessionBalance = (state) =>
  state.transferRequestStore.currentCessionBalance;

export const selectCreatedTransactionId = (state) =>
  state.transferRequestStore.createdTransactionId;

// Don't expose to rest of the app.
const {
  createTransferRequestStart,
  createTransferRequestSuccess,
  createTransferRequestFailure,
  getTransferRequestDetailsStart,
  getTransferRequestDetailsSuccess,
  getTransferRequestDetailsFailure,
} = transferRequestSlice.actions;

export const createTransferRequest = (params, challengeHistoryId) => async (
  dispatch
) => {
  dispatch(createTransferRequestStart());
  try {
    var response = await transferRequestApi.postTransaction(
      params,
      challengeHistoryId
    );
    if (response && response.success) {
      dispatch(createTransferRequestSuccess(response.transactionRequestId));
    } else {
      dispatch(createTransferRequestFailure());
    }
  } catch (err) {
    console.error(err);
    dispatch(createTransferRequestFailure());
  }
};

export const resetTransferRequestStatus = () => (dispatch) => {
  dispatch(transferRequestSlice.actions.resetCreateTransferRequestStatus());
};

export const loadTransferRequestDetails = (id) => async (dispatch) => {
  dispatch(getTransferRequestDetailsStart());
  try {
    let res = await transferRequestApi.getTransaction(id);
    dispatch(getTransferRequestDetailsSuccess(res));
  } catch (err) {
    console.error(err);
    dispatch(getTransferRequestDetailsFailure());
  }
};

export default transferRequestSlice.reducer;
