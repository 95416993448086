import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import { Loader } from "react-bootstrap-typeahead";
import "../../scss/components/general/_add_edit_modal.scss";
import { IconRemove, IconTrash, IconInfo } from "../../assets/icons";

const EditModal = (props) => {
  const {
    title,
    text,
    buttonText,
    isOpen,
    isBusy,
    onConfirm,
    onCancel,
    onDelete,
    isValid,
    showCancel,
  } = props;

  const zIndex = props.zIndex !== "undefined" ? props.zIndex : 1050;

  const renderTooltip = (tooltipContent) => {
    return (
      <span className="header-tooltip-icon mr-3 mt-1">
        <IconInfo />
        <span className="header-tooltip">{tooltipContent}</span>
      </span>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      autoFocus
      zIndex={zIndex}
      className="AddEditModal"
    >
      <div onClick={onCancel} className="close-button text-center py-3">
        <i>
          <IconRemove />
        </i>
      </div>
      <ModalBody className="px-5 py-4 height-400-sm">
        {title || text ? (
          <div className="d-flex flex-wrap justify-content-between pb-3">
            {title ? (
              <h3>
                {title}{" "}
                {props.tooltipContent && renderTooltip(props.tooltipContent)}
              </h3>
            ) : (
              ""
            )}
            {text ? <p className="align-self-start">{text}</p> : ""}
            {onDelete ? (
              <p className="align-self-end" onClick={onDelete}>
                <i>
                  <IconTrash />
                </i>
              </p>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {props.children}
        <div className="d-flex flex-wrap justify-content-between mt-3 clear-both">
          {showCancel ? (
            <Button
              className="mb-2 ml-1"
              color="primary"
              outline
              onClick={onCancel}
            >
              Cancel
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            className="mb-2 mr-1"
            color="primary"
            onClick={onConfirm}
            disabled={!isValid || isBusy}
          >
            {isBusy && <Loader color="#2F353A" size="5" />} {buttonText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

EditModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool,
  showCancel: PropTypes.bool,
};

EditModal.defaultProps = {
  buttonText: "Save",
  showCancel: true,
};

export default EditModal;
