import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import "../../scss/useradmin/_invite_user.scss";
import { IconArrowLeft } from "../../assets/icons";
import {
  selectUsers,
  selectPermissionGroup,
  selectClients,
  selectClientsStatus,
  selectSelectedClients,
  setSelectedClients,
  loadClients,
} from "../../features/inviteUsers/inviteUsersSlice";
import { selectIsSaving } from "../../features/users/userDetailsSlice.js";

const SelectClientPositions = (props) => {
  const {
    stepIndicator,
    nextStep,
    previousStep,
    headerText,
    userSelectedClients,
    userSetSelectedClients,
    userPermissionGroup,
    isChangeClientFlow,
  } = props;

  const users = useSelector(selectUsers);
  const permissionGroup =
    userPermissionGroup ?? useSelector(selectPermissionGroup);
  const clients = useSelector(selectClients);
  const clientsStatus = useSelector(selectClientsStatus);
  const selectedClients =
    userSelectedClients ?? useSelector(selectSelectedClients);
  const dispatch = useDispatch();
  const t = useTranslation().t;
  const isSaving = useSelector(selectIsSaving);

  const [clientForm, setClientForm] = useState({
    clients: [
      // {
      //     id,
      //     name,
      //     isSelected
      // }
    ],
    captives: [
      // {
      //     id,
      //     clientId - id of client in the clients array,
      //     name,
      //     code,
      //     isSelected,
      //     isHidden
      // }
    ],
  });

  const [clientSelectedItem, setClientSelectedItem] = useState([]);

  useEffect(() => {
    if (
      !clients.length &&
      !clientsStatus.isLoading &&
      !clientsStatus.isFailure &&
      !clientsStatus.isSuccess
    )
      dispatch(loadClients());

    if (!!clients.length && !clientForm.clients.length) {
      const clientFormClients = clients.map((cFC) => ({
        id: cFC.clientNameId,
        name: cFC.clientName,
        isSelected: cFC.captives.some((cap) =>
          selectedClients.includes(cap.clientId)
        ),
      }));
      const clientFormCaptives = clients.map((cFC) => {
        return cFC.captives.map((cap) => ({
          id: cap.clientId,
          clientName: cFC.clientName,
          clientId: cFC.clientNameId,
          captiveName: cap.captiveName,
          code: cap.captiveCode,
          isSelected: selectedClients.includes(cap.clientId),
          isHidden: !clientFormClients.find(
            (cFC2) => cFC2.id === cFC.clientNameId
          )?.isSelected,
        }));
      });

      //console.log("Edit clientFormCaptives : " + JSON.stringify(clientFormCaptives));
      var captiveArray = clientFormClients.filter(function (el) {
        return el.isSelected == true;
      });
      console.log("Edit Captive : " + JSON.stringify(captiveArray));
      for (var i = 0; i < captiveArray.length; i++) {
        clientSelectedItem.push(captiveArray[i].id);
      }

      setClientForm({
        clients: clientFormClients,
        captives: clientFormCaptives.flat(),
      });
    }
  }, [
    clientForm.clients.length,
    clients,
    clientsStatus,
    dispatch,
    selectedClients,
  ]);

  const internalSetSelectedClients = (newSelectedClients) => {
    userSetSelectedClients
      ? userSetSelectedClients(newSelectedClients)
      : dispatch(setSelectedClients(newSelectedClients));
  };

  const selectClient = (client, checked) => {
    let updatedClients = [];
    let updatedCaptives = [];
    const selectedCaptiveIds = [];
    if (!clientSelectedItem.includes(client.id) && checked)
      clientSelectedItem.push(client.id);

    if (clientSelectedItem.includes(client.id) && !checked) {
      for (var i = 0; i < clientSelectedItem.length; i++) {
        if (clientSelectedItem[i] === client.id) {
          clientSelectedItem.splice(i, 1);
        }
      }
    }

    if (checked) {
      updatedClients = clientForm.clients.map((cli) =>
        cli.id === client.id ? { ...cli, isSelected: true } : { ...cli }
      );
      updatedCaptives = clientForm.captives.map((cap) =>
        clientSelectedItem.includes(cap.clientId)
          ? { ...cap, isHidden: false }
          : { ...cap, isHidden: true }
      );
    } else {
      // updatedClients = clientForm.clients.map((cli) => ({
      //   ...cli,
      //   isSelected: false,
      // }));
      updatedClients = clientForm.clients.map((cli) =>
        cli.id === client.id ? { ...cli, isSelected: false } : { ...cli }
      );
      // updatedCaptives = clientForm.captives.map((cap) => ({
      //   ...cap,
      //   isHidden: true,
      //   isSelected: false,
      // }));
      updatedCaptives = clientForm.captives.map((cap) => {
        // clientSelectedItem.includes(cap.clientId)
        //   ? { ...cap, isHidden: false, isSelected: false }
        //   : { ...cap, isHidden: true }
        if (cap.clientId == client.id) {
          return { ...cap, isHidden: true, isSelected: false };
        } else {
          return { ...cap };
        }
      });
    }

    for (var i = 0; i < updatedCaptives.length; i++) {
      if (updatedCaptives[i].isSelected == true)
        selectedCaptiveIds.push(updatedCaptives[i].id);
    }
    internalSetSelectedClients(selectedCaptiveIds);
    // let result = updatedCaptives.filter(x => x.isSelected == true)
    console.log("updatedCaptivesID 188 " + JSON.stringify(selectedCaptiveIds));
    setClientForm({
      clients: updatedClients,
      captives: updatedCaptives,
    });
  };

  const selectCaptive = (captive, checked) => {
    const updatedCaptives = clientForm.captives.map((cap) =>
      cap.id === captive.id ? { ...cap, isSelected: checked } : cap
    );
    let updatedCaptiveIds = [];
    // const updatedCaptiveIds = checked
    //   ? [...selectedClients, captive.id]
    //   : selectedClients.filter((sC) => sC !== captive.id);
    //const updatedCaptiveIds = updatedCaptives
    let result = updatedCaptives.filter((x) => x.isSelected == true);
    console.log("updatedCaptives 190 " + JSON.stringify(result));
    for (var i = 0; i < updatedCaptives.length; i++) {
      if (updatedCaptives[i].isSelected == true)
        updatedCaptiveIds.push(updatedCaptives[i].id);
    }
    console.log("updatedCaptives 195 " + JSON.stringify(updatedCaptiveIds));
    setClientForm({
      clients: clientForm.clients,
      captives: updatedCaptives,
    });

    internalSetSelectedClients(updatedCaptiveIds);
  };

  const selectAllCaptives = (checked) => {
    const updatedCaptives = getVisibleCaptives().map((cap) => ({
      ...cap,
      isSelected: checked,
    }));
    const updatedCaptiveIds = checked
      ? getVisibleCaptives().map((cap) => cap.id)
      : [];

    setClientForm({
      clients: clientForm.clients,
      captives: updatedCaptives,
    });

    internalSetSelectedClients(updatedCaptiveIds);
  };

  const getVisibleCaptives = () =>
    clientForm.captives.filter((cap) => !cap.isHidden);
  const allClientsSelected = () =>
    clientForm.clients.length && clientForm.clients.every((c) => c.isSelected);
  const allCaptivesSelected = () =>
    getVisibleCaptives().length &&
    getVisibleCaptives().every((c) => c.isSelected);

  return (
    <div className="addUser">
      <div className="MyProfile-header">
        <div className="card addUserCard">
          <div className="card-header d-flex justify-content-between">
            <span>{headerText ? headerText : t("Select Captives")}</span>
            {stepIndicator}
          </div>
          <div
            className="card-body"
            style={{ minHeight: "700px", maxHeight: "700px" }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ flexWrap: "wrap" }}
            >
              {!isChangeClientFlow && (
                <div
                  className="card mr-4"
                  style={{
                    flex: "2 1",
                    minWidth: 400,
                    maxWidth: 400,
                    borderRight: "0.5px solid #dee2e6",
                    borderRadius: "0px",
                    paddingRight: "40px",
                    minHeight: 650,
                    maxHeight: 650,
                  }}
                >
                  <div
                    className="card-header"
                    style={{ padding: 0, paddingBottom: 30 }}
                  >
                    <span>{t("User(s)")}</span>
                  </div>
                  <div className="scrollable">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("Job Title")}</th>
                          <th scope="col">{t("Permission")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => {
                          return (
                            <tr key={`user_${index}`}>
                              <td>
                                {user.firstName} {user.lastName}
                              </td>
                              <td>{user.jobTitle}</td>
                              <td>{permissionGroup.friendlyName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="card d-flex"
                style={{
                  minWidth: 250,
                  maxWidth: 250,
                  flex: "2 1",
                  borderRight: "0.5px solid #dee2e6",
                  marginRight: "40px",
                }}
              >
                <div
                  className="card-header"
                  style={{ padding: 0, paddingBottom: 30 }}
                >
                  <span>{t("Client(s)")}</span>
                </div>
                <div className="scrollable-list">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">{t("Name")}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientForm.clients.map((client) => {
                        return (
                          <>
                            <tr key={`client_${client.id}`}>
                              <td className="text-left">{client.name}</td>
                              <td className="text-center">
                                <input
                                  key={`client_checkbox_${client.id}`}
                                  type="checkbox"
                                  name="client"
                                  checked={client.isSelected}
                                  onChange={(e) =>
                                    selectClient(client, e.target.checked)
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="card d-flex"
                style={{ minWidth: 400, flex: "3 1", marginBottom: 40 }}
              >
                <div
                  className="card-header"
                  style={{ padding: 0, paddingBottom: 30 }}
                >
                  <span>{t("Captive(s)")}</span>
                </div>
                <div className="scrollable-list">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">{t("Client Name")}</th>
                        <th scope="col">{t("Captive Name")}</th>
                        <th scope="col">{t("Captive Code")}</th>
                        <th scope="col">
                          <input
                            key={"select-all-clients"}
                            style={{ marginRight: 5 }}
                            type="checkbox"
                            name="captives"
                            disabled={!getVisibleCaptives().length}
                            checked={allCaptivesSelected()}
                            onChange={(e) =>
                              selectAllCaptives(e.target.checked)
                            }
                          />
                          {t("Select All")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientForm.captives.map((captive, index) => {
                        return captive.isHidden ? (
                          <></>
                        ) : (
                          <tr key={`captive_${index}`}>
                            <td className="text-left">{captive.clientName}</td>
                            <td
                              className="text-left"
                              style={{ minWidth: "145px", maxWidth: "145px" }}
                            >
                              {captive.captiveName}
                            </td>
                            <td className="text-left">{captive.code}</td>
                            <td className="text-center">
                              <input
                                key={`checkbox_${index}`}
                                type="checkbox"
                                name="captive"
                                checked={captive.isSelected}
                                onChange={(e) =>
                                  selectCaptive(captive, e.target.checked)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              props.onCancel
                ? "d-flex flex-nowrap pt-4 justify-content-center w-100"
                : "d-flex justify-content-between"
            }
          >
            {props.onCancel ? null : (
              <Link to={previousStep}>
                <i className="mr-2">
                  <IconArrowLeft />
                </i>
                {t("Back")}
              </Link>
            )}
            {props.onSave ? (
              <Button
                className="mr-3"
                style={{ minWidth: `125px` }}
                color="primary"
                onClick={props.onSave}
                disabled={isSaving}
              >
                {t("Save")}
              </Button>
            ) : (
              <Button
                tag={Link}
                color="primary"
                to={nextStep}
                disabled={selectedClients.length === 0}
              >
                {t("Continue")}
              </Button>
            )}
            {props.onCancel ? (
              <Button
                className="mr-3"
                style={{ minWidth: `125px` }}
                outline
                color="primary"
                onClick={props.onCancel}
              >
                {t("Cancel")}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectClientPositions;
