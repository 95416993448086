export const FORMATTED_TEXT = `
<h1>TERMS OF USE</h1> 
<br/><p>
EFFECTIVE: May 21, 2021 
</p><br/>
<p>
IMPORTANT, READ CAREFULLY: YOUR USE OF AND ACCESS TO THE WEB SITE AND PRODUCTS AND SERVICES AND ASSOCIATED SOFTWARE (COLLECTIVELY, THE "SERVICES") OF COMPANY (AS DEFINED BELOW), IS CONDITIONED UPON YOUR COMPLIANCE WITH AND ACCEPTANCE OF THESE TERMS, WHICH INCLUDE YOUR AGREEMENT TO ARBITRATE CLAIMS AND TO WAIVE CLASS ACTIONS. PLEASE REVIEW THOROUGHLY BEFORE USING THE SITE. 
</p><br/>
<p>
<h1>Use of Site</h1>
</p><br/>
<p>
The entities listed below (collectively, “Company”, “We”, “Us”, or “Our”) are the providers of the Services through use of this Hub Web Site (“Site”).  Such Services include, but are not limited to, viewing and accessing reports and data, and certain other functionality related to reinsurance, actuarial and marketing, and the ability to download and upload certain documents and information. Company requires that each visitor (“You”, “Your”, or "User") to this Site adhere to these Terms of Use. Please read these Terms of Use carefully before using the Site. By accessing this Site, You acknowledge that You have read, understand and accept each of the Terms of Use. You are bound by any revisions to these Terms of Use and should visit this page at each visit to review the current Terms of Use. If You do not agree to all of these Terms of Use, You should not use this Site. 
</p><br/>
<p>
<h1>Company Defined</h1>
</p><br/>
<p>
Company means the following companies and their affiliates: 

American Bankers Insurance Company of Florida 

American Security Insurance Company 

Assurant Service Protection, Inc. 

Automotive Warranty Services, Inc. 

Automotive Warranty Services of Florida, Inc. 

Consumer Program Administrators, Inc. 

Federal Warranty Service Corporation 

First Extended Service Corporation 

Motor Warranty Services of North America 

National Product Care Company 

United Service Protection Corporation 

United Service Protection, Inc. 

Virginia Surety Company, Inc. 
</p><br/>
<p>
<h1>Scope of Site</h1>
</p><br/>
<p>
To use the Site, You must have an electronic device with access to the internet that is compatible with the Site. Company does not warrant that the Site will be compatible with Your electronic device. Some features are only available if You have created an online account with Company. 
</p><br/>
<p>
 
<h1>Access to Password Protected or Secure Areas</h1>
</p><br/>
<p>
Access to and use of password protected or secure areas of this Site are restricted to authorized persons only. Any User who accesses or attempts to access such areas without authorization may be subject to prosecution. 
</p><br/>
<p>
<h1>No Warranty</h1>
</p><br/>
<p>
Company does not warrant the accuracy or completeness of the information, text, graphics, links or other content contained on this Site or any other server. Company makes no representation concerning the suitability of this documentation for any purpose. It is provided "as is" without express or implied warranty.  
</p><br/>
<p>
<h1>Third-Party Sites, Products, and Services</h1>
</p><br/>
<p>
This Site may contain hyperlinks to Web sites operated by parties other than Company and its subsidiaries solely as a convenience to Users. In the course of providing services related to the Site, We may also make recommendations with respect to third-party services or resources that are commonly used or popular. Company does not control such Web sites and is not responsible for the content. The inclusion of hyperlinks to such Web sites does not imply any endorsement, approval or certification by Company of the material on such Web sites or any association with their operators.  Viewing a linked site, accessing and using the information, materials, products or utilizing outside services and resources is done entirely at Your own risk. Accordingly, We encourage You to be aware when You leave the Site or use a recommended resource or service and to read the terms and conditions and privacy policy of each such third-party site, service, or resource that You visit or use.  Furthermore, We make no express or implied warranties with regard to the information, material, products or services that are contained on or accessible through linked sites. 
</p><br/>
<p>
<h1>Limitation of Liability</h1>
</p><br/>
<p>
Company expressly disclaims all warranties, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, security and accuracy. Information in this Site may contain inaccuracies or errors. Information on this Site may be changed or updated without notice. Company has no obligation to update information on this Site, so such information may be out of date at any given time. Company also may make improvements or changes in products or content described on this Site at any time without notice. 
</p><br/>
<p>
Company will not be responsible for any damages or injuries that accompany or result from any use of this Site. Company specifically disclaims any liability (whether based in contract, tort, strict liability or otherwise) for any direct, indirect, incidental, consequential, or special damage arising out of or in any way connected with access to or use of this Site (even if Company has been advised of the possibility of any such damage), including any liability associated with any virus that may infect a User's computer equipment. Under no circumstances will Company be liable for any loss or damage caused by a User's reliance on information obtained through this Site. It is the sole responsibility of each User to evaluate the accuracy, completeness or usefulness of any information, opinion, advice or other content available through this Site. 
</p><br/>
<p>
<h1>No Representation as to Suitability</h1>
</p><br/>
<p>
Company makes no representation as to the financial or other suitability of any product or service described on this Site for any User. 
</p><br/>
<p>
<h1>License Grant</h1>
</p><br/>
<p>
We hereby grant You a non-exclusive, non-transferable, revocable license to access the Site using the account issued to You. We also hereby grant You a non-exclusive, non-transferable, non-sublicensable, revocable license use in connection with Your professional use of the Site and Site materials (other than the Site) and to access, download, and use account solely for (a) internal, business purposes; (b) to view the strategies, data, and other information displayed in the account; and (c) to download or print out information from the Site solely for internal, business purposes, and provided that You maintain all copyright and other policies contained herein.  
</p><br/>
<p>
Except as expressly permitted in these Terms of Use, You may not: (i) copy in whole or in part, modify or create derivative works based on the Site or Site materials; (ii) distribute, transfer, sublicense, lease, lend or rent the Site materials to any third party; (iii) remove any proprietary notices or labels on the Site or Site materials; (iv) allow any third party access to the Site or Site materials; (v) use the Site or Site materials in any manner or for any purpose that infringes, misappropriates or otherwise violates any intellectual property right or other right of any person or party, or that violates any applicable law; or (vi) use the Site or Site materials for any commercial or illegal purpose, or for any purpose not expressly permitted by these Terms of Use. Any password or right given to You to obtain access to the Site or information from the Site is not transferable or assignable.  
</p><br/>
<p>
Company reserves all rights not expressly granted in these Terms of Use.  
</p><br/>
<p>
Company reserves the right to exercise whatever lawful means it deems necessary to prevent unauthorized or prohibited uses.  
</p><br/>
<p>
<h1>Proprietary Rights</h1>
</p><br/>
<p>
The design, content, graphics, compilation, information, organization, products, content, and all other elements of the Site provided by Company are protected under applicable copyrights, trademarks, trade dress, and other proprietary rights, including without limitation, intellectual property rights. You do not acquire ownership rights to the Service, Site materials, or any content included therein. All Site materials are the property of Company or Our affiliated companies and/or third-party licensors. You agree not to sell, license, copy, modify, alter, reverse engineer, disassemble, decompile, translate, edit, adapt, create derivative works from, or otherwise make unauthorized use of the materials. You agree not to disclose, publicly perform or display, transmit, publish, distribute or provide the materials to any other party.  
</p><br/>
<p>
<h1>Electronic (and other) Communication</h1>
</p><br/>
<p>
Company may provide Communications to You by one or more of the following methods: (1) via e-mail; (2) via telephone call; (3) via text message or mobile message service; (4) any other method to the extent permissible by law. With respect to text message and mobile messaging service Communications, Your carrier may charge You a fee for receiving the message. “Communications” means all notices, reports, documents, disclosures or other information that Company is required to provide to You by law, or as reasonably necessary to provide to You. Communications also means any marketing offers Company may send through any of the methods identified or any other electronic medium allowed by law, or surveys or questionnaires seeking input and feedback on the Site. Your consent does not automatically expire and is not limited as to duration.  
</p><br/>
<p>
<h1>Business Account</h1>
</p><br/>
<p>
<u>Employee</u> 
</p><br/>
<p>
If You have access to this Site as a result of Your employment with Your employer, then You must use your business email address when setting up your account and accessing this Site.  It is Your responsibility to comply with all of your Employer’s requirements regarding accessing and securing their data and information.  It is Your responsibility to ensure the security of your access credentials and to take precautions to run appropriate security software on your device.  You agree that Your employer may be notified of the existence of Your account, and that Your employer may control and administer Your account, and access and process Your data, including the contents of Your communications and files, and that We may notify Your employer if the account is compromised. You further agree that Your use of the Site may be subject to the agreements You may have with Your employer.  Should Your employment be terminated, Your access to the Site will immediately be terminated. 
</p><br/>
<p>
<u>Third Party Servicer</u>
</p><br/>
<p>
If You have been given access to this Site as a result of Your business relationship with Your client (“Client”), then You must use your business email address when setting up your account and accessing this Site.  It is Your responsibility to comply with all of the Client’s requirements regarding accessing and securing their data and information.  It is Your responsibility to ensure the security of your access credentials and to take precautions to run appropriate security software on your device.  You agree that the Client may be notified of the existence of Your account, and that the Client may control and administer Your account, and access and process Your data, including the contents of Your communications and files, and that We may notify the Client if the account is compromised. You further agree that Your use of the Site may be subject to the agreements You may have with the Client.  The Client shall have the right to terminate your access to the Site at any time. 
</p><br/>
<p>
<h1>System Requirements.</h1>Use of the Services requires one or more compatible devices, Internet access (fees may apply), and certain software (fees may apply), and may require obtaining updates or upgrades from time to time. Because use of the Services involves hardware, software, and Internet access, Your ability to access and use the Services may be affected by the performance of these factors. High speed Internet access is recommended. You acknowledge and agree that such system requirements, which may be changed from time to time, are Your responsibility. 
</p><br/>
<p>
<h1>Designated Email address and Phone Number:</h1>
</p><br/>
<p>
You certify each e-mail address You provide for the account is a valid business email address for the purpose described herein (“Designated Email Address”). You further certify the phone number provided is a valid phone number and You are the account holder for the phone number You provide. You agree to maintain each Designated Email Address and phone number until You provide Us with a new one.  
</p><br/>
<p>
<h1>SMS Terms</h1>
</p><br/>
<p>
By providing Your phone number, You agree to receive text messages from Us, or others on Our behalf, for 2-factor authentication purposes, marketing or servicing communications by automated SMS, MMS, text message, or other electronic means (collectively, “SMS Messages”). You represent that You are the account holder for the phone number You provide. 
</p><br/>
<p>
The number of messages You will receive per month will vary based on Your activity with the Site. Message and data rates may apply. A “HELP” reply option will also be available on some message types for additional instructions.   We make commercially reasonable efforts to deliver the automated SMS Messages to You through compatible wireless carriers. Carriers are not liable for delayed or undelivered messages.  
</p><br/>
<p>
<h1>Privacy</h1>
</p><br/>
<p>
As part of the normal operation of Our Site, We may collect a limited amount of information about Your visit.  
</p><br/>
<p>
For Our privacy policies, please refer to <a href="https://www.assurant.com/PrivacyPolicy">Assurant Privacy Policy</a>.  
</p><br/>
<p>
<h1>Indemnification.</h1>
</p><br/>
<p>
You agree to indemnify, defend, and hold Company, its affiliates, partners, agents, officers, directors, employees, subcontractors, successors, assigns, and third party suppliers of information harmless from any claims, losses, damages, liabilities, including legal fees and expenses, arising out of Your misuse of the Site or Site materials or any violation by You of these Terms of Use. Company reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Company, and You agree to cooperate with Company’s defense of these claims. Company will use reasonable efforts to notify You of any such claim, action, or proceeding upon becoming aware of it.   
</p><br/>
<p>
<h1>Usage Rules and Prohibited Conduct</h1>
</p><br/>
<p>
<h1>YOU UNDERSTAND AND HEREBY ACKNOWLEDGE AND AGREE THAT YOU MAY NOT AND WARRANT THAT YOU WILL NOT:</h1>
<ul>
<li>
use the Site or any location information displayed within the Site to stalk, harass, abuse, defame, threaten or defraud other users, or collect, attempt to collect, or store location or personal information about others; 
</li><li>
use the Site if You are under the age of 13 years old; 
</li><li>
use the Site for any commercial or non-private use without Company’s written consent, it being understood that the Site is intended for individual, non-commercial use only; 
</li><li>
use the Site for any illegal purpose, or in violation of any local, state national or international law, including, without limitation, laws governing intellectual property and other proprietary rights, data protection and privacy, and import or export control; 
</li><li>
post, store, send, transmit, or disseminate any information or material which a reasonable person could deem to be objectionable, libelous, offensive, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically offensive, or otherwise inappropriate, regardless of whether this material or its dissemination is unlawful; 
</li><li>
post, store, send, transmit, or disseminate any information or material which infringes any patents, trademarks, trade secrets, copyrights, or any other proprietary or intellectual property rights; 
</li><li>
use the Site with any products, systems, or applications installed or otherwise connected to or in communication with vehicles, or otherwise capable of vehicle navigation, positioning, dispatch, real time route guidance, fleet management, or similar applications;  
</li><li>
use the Site in connection with hazardous environments requiring fail- safe performance or any application in which the failure or inaccuracy of that application or the Site could lead directly to death, personal injury, or severe physical or property damage; 
</li><li>
make unsolicited offers, advertisements, proposals, or send junk mail, to other users of the Site. This includes, but is not limited to, unsolicited advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures; 
</li><li>
impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the Services accounts of other users; 
</li><li>
share passwords or access to Your account while the Site is running and/or accessible with any third party or encourage any other user to do so; 
</li><li>
misrepresent the source, identity or content of information transmitted via the Site; 
</li><li>
remove, circumvent, disable, damage, reverse engineer, or otherwise interfere with security-related features of the Site, digital rights management technologies that are integrated in the Site, features that prevent or restrict use or copying of any content accessible through the Site, or features that enforce limitations on use of the Site; 
</li><li>
intentionally interfere with or damage operation of the Site or any user's enjoyment of the Site, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious code; 
</li><li>
attempt to gain unauthorized access to the Site, or any part of it, other accounts, computer systems or networks connected to the Site, or any part of it, through hacking, password mining or any other means or interfere or attempt to interfere with the proper working of the Site or any activities conducted on the Site; 
</li><li>
use any robot, spider, scraper or other automated means to access the Site for any purpose without Company’s express written permission or bypass Company’s robot exclusion headers or other measures Company may use to prevent or restrict access to the Site or modify the Site in any manner or form, nor to use modified versions of the Site, including without limitation for the purpose of obtaining unauthorized access to the Site; or 
</li><li>
sell or transfer or allow another person to access Your account password, profile, or account. 
</li><li>
</ul></p><br/>
<p>
<h1>User Submissions</h1>
</p><br/>
<p>
The Site may allow the submission of content and materials (such as pictures, audio, video, documents, reviews, ratings, ideas, notes, concepts, or creative suggestions) by You and other Users ("User Submissions"), and the hosting, sharing and/or publishing of such User Submissions. You shall be solely responsible for You own User Submissions and the consequences of posting or publishing them. In connection with User Submissions, You affirm, represent, and warrant that: (i) You own, or have the necessary licenses, rights, consents, and permissions to use, and authorize Company to use, all intellectual property and any other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the manner contemplated by the Site and these Terms of Use; (ii) You have the written consent, release, and/or permission of each and every identifiable individual in the User Submission to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the User Submissions in the manner contemplated by the Site and these Terms of Use; and (iii) You agree not to make any User Submissions that (x) are unlawful, harmful, threatening, abusive, harassing, defamatory, libelous, invasive of another's privacy, or is harmful to minors in any way; (y) are pornographic or obscene, or that harasses, degrades, intimidates or are hateful toward an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; or that impersonates any person or entity, including, but not limited to, an officer, director, employee, or agent of Company or Our business associates; or (z) includes personal or identifying information about another person without that person's explicit consent. For clarity, You shall retain all of Your ownership rights in Your User Submissions.  
</p><br/>
<p>
By submitting a User Submission, You hereby grant Company a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submission in connection with the Site and Our (and Our successors’ and assigns’) businesses, including, without limitation, for marketing or promotional purposes, in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after Your termination of Your account, the Site, or Your use of the Site. To the extent any User Submission You submit includes Your name, likeness, voice, or photograph, You acknowledge and agree that this license shall apply to the same.  
</p><br/>
<p>
For clarity, the foregoing license grant does not affect Your ownership or other license rights in Your User Submissions, including the right to grant additional licenses to Your User Submissions, unless otherwise agreed in writing. You represent and warrant that You have all rights to grant such licenses to Company without infringement or violation of any third-party rights including, without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights. 
</p><br/>
<p>
Except where prohibited by applicable law, by submitting a User Submission through the Site, You are waiving and agreeing not to assert any copyrights or “moral” rights or claim resulting from Our alteration of the User Submission or any photograph(s), footage, illustrations, statements or other work contained in the User Submission. 
</p><br/>
<p>
We assume no responsibility whatsoever in connection with or arising from User Submissions. We assume no responsibility for actively monitoring User Submissions for inappropriate content. If at any time Company chooses, in its sole discretion, to monitor User Submissions, We nonetheless assume no responsibility for the content of the User Submissions, no obligation to modify or remove any inappropriate User Submissions, and no responsibility for the conduct of the User submitting User Submissions. Further, Company does not endorse and has no control over the content of User Submissions submitted by other Users. Company makes no warranties, express or implied, as to the content of User Submissions or the accuracy and reliability of any User Submissions. Nonetheless, We reserve the right to prevent You from submitting User Submissions and to edit, restrict or remove User Submissions for any reason at any time. 
</p><br/>
<p>
<h1>Laws and Regulations</h1>
</p><br/>
<p>
Access to and use of this Site are subject to all applicable federal, state and local laws and regulations. 
</p><br/>
<p>
<h1>Governing Law</h1>
</p><br/>
<p>
THESE TERMS OF USE SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF GEORGIA WITHOUT REGARD TO CONFLICT OF LAW RULES OR PRINCIPLES THAT WOULD CAUSE THE APPLICATION OF LAWS OF ANY OTHER JURISDICTION.  
</p><br/>
<p>
<h1>Arbitration</h1> 
</p><br/>
<p>
If any dispute shall arise between Company and User(s), with reference to the interpretation of these Terms of Use or use of the Site, and cannot be settled through informal means, the dispute shall be settled by arbitration in accordance with the rules of the American Arbitration Association.  The dispute shall be referred to three (3) arbitrators.  One arbitrator shall be chosen by each party and the two chosen shall promptly select a third arbitrator.  If either party refuses or neglects to appoint an arbitrator within thirty (30) days after the receipt of written notice from the other party requesting arbitration and naming its arbitrators, the requesting party may name an arbitrator for the other party.  Each party shall submit its case to the three (3) arbitrators within thirty (30) days of the appointment of the third arbitrator unless such time is extended by the arbitrators or a majority of them or by agreement between the parties.  The decision of a majority of the arbitrators shall be final and binding on both Company and User(s).  Company and User(s) shall each bear the expense of its own arbitrator, or one-half of the expense of two (2) arbitrators if both are appointed by the requesting party as provided above, and shall jointly bear and equally bear with the other the expense of the third arbitrator and of the arbitration.  Any such arbitration shall take place in Atlanta, Georgia.  This Section shall survive termination of these Terms of Use. 
</p><br/>
<p>
You and We agree that except as may be required by law, neither You nor We may disclose the existence, content, substance, documents, or information submitted thereunder, or any results of any arbitration hereunder without the prior written consent of other party.  
</p><br/>
<p>
<h1>Class Action Waiver</h1>
</p><br/>
<p>
User(s) shall not be entitled to join or consolidate claims under or relating to these Terms of Use by or against other individuals or entities or litigate or pursue any claim as a representative ‎member of a class or in a private attorney general capacity. THIS MEANS THAT USER(S) WAIVE THE RIGHT TO INITIATE OR PARTICIPATE IN ANY CLASS OR CONSOLIDATED ‎ACTION WHATSOEVER. A court may sever any portion of this dispute resolution provision if ‎it finds such unenforceable.‎ 
</p><br/>
<p>

<h1>Suspension, Violations and Termination of Service</h1>
</p><br/>
<p>
Company reserves the right, but has no obligation, to monitor any User's registration as well as any User's use of or access to the location information and profiles of other Users. Company reserves the right to disable, suspend or terminate access to the Site for any reason and without any notice, unless otherwise prohibited by law. 
</p><br/>
<p>
<h1>Account Information</h1>
</p><br/>
<p>
The Site is not available to any users suspended or removed from the Site by Company. You agree that the information You provide to Us upon registration and at all other times will be true, accurate, current and complete. You also agree that You will ensure that this information is kept accurate and up to date at all times. 
</p><br/>
<p>
<h1>Violations of Terms of Use</h1>
</p><br/>
<p>
Company reserves the right to seek any remedy available at law or in equity for any violation of these Terms of Use, including the right to block access from a particular Internet address to this Site. 
</p><br/>
<p>
<h1>Severability, Waiver, and Entire Agreement</h1>
</p><br/>
<p>
If any provision of these Terms of Use is invalid, unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the rest of the Terms of Use and shall continue to be valid and enforceable. Company’s failure to enforce any part of these Terms of Use shall not constitute a waiver of Company’s right to later enforce that or any other part of these Terms of Use. Waiver of compliance in any particular instance does not mean that Company will waive compliance in the future. In order for any waiver of compliance with these Terms of Use to be binding, Company must provide User(s) with written notice of such waiver through a Company authorized representative. 
</p><br/>
<p>
These Terms of Use state the entire agreement with regard to the Site and supersedes all prior or contemporaneous communications and proposals (whether oral, written or electronic) between Company and User(s) with respect to the Site. Company shall not be liable for any failure to perform Our obligations hereunder where such failure results from any cause beyond Our reasonable control including, without limitation, mechanical, electronic or communications failure or degradation. 
</p><br/>
<p>
These Terms of Use are personal to You as a User, and are not assignable, transferable or sublicensable by You except with Company’s prior written consent. Company may assign, transfer or delegate any of Our rights and obligations hereunder without consent. BY USING THIS SITE, YOU AGREE TO THE PROVISIONS CONTAINED ABOVE. 
</p><br/>
<p>
The Company brand mark is a service mark of Company. No part of this site may be reproduced without written permission from Company. 
</p><br/>
<p>
&copy; 2021 Company
<p>
    `;
