import React, { useState, useEffect, dispatch, t } from "react";
import StatementsList from "../components/statements/StatementsList";
import DocumentsDownload from "../components/statements/DocumentsDownload";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clientsApi from "../api/clients.api";
import cessionApi from "../api/cession.api";
import statementsApi from "../api/statements.api";
import { useAuthContext } from "../Auth";
import { IconDocument } from "../assets/icons";
import {
  formatPeriod,
  generateColumn,
  generateActionsColumn,
} from "../components/general/DataTable";

window.dataLayer = window.dataLayer || [];

let PageLocation = window.location.origin + "/autoHub/excess_reserve";
let PageTitle = "surplus now ";

// console.log("location.pathname " + window.location.pathname);
// console.log("Page Name = " + "cession statements");
// console.log("Page Location = " + PageLocation + "/" + "cession_statements");
// console.log("Page Title = " + PageTitle + "cession statements");

window.dataLayer.push({
  event: "page_view",
  page_data: {
    breadcrumb: "",
    page_category: "",
    page_category2: "",
    page_name: "cession statements",
    page_location: PageLocation + "/" + "cession_statements",
    page_referrer: "",
    page_title: PageTitle + "cession statements",
    module: "surplus now",
    user_login_state: "authenticated",
    dealer_name: "",
  },
});

function CessionStatementsPage() {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [periods, setPeriods] = useState([]);
  const [clientsNames, setClientsNames] = useState([]);
  const [captivesNames, setCaptivesNames] = useState([]);
  const [captivesCodes, setCaptivesCodes] = useState([]);
  const [errorText, setErrorText] = useState("");

  const formatterActions = (_cell, row) => {
    return (
      <div className="icon-action">
        {row.cessionStatementFileName && (
          <i onClick={(e) => handleCessionStatementClick(e, row)}>
            <IconDocument height={20} />
          </i>
        )}
      </div>
    );
  };

  const columns = [
    generateColumn(
      "cessionPeriod",
      t("Cession Period"),
      true,
      { minWidth: "155px", maxWidth: "155px" },
      formatPeriod
    ),
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "265px",
      maxWidth: "265px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn("", "", false, { minWidth: "120px", maxWidth: "120px" }),
    generateColumn("", "", false, { minWidth: "120px", maxWidth: "120px" }),
    generateColumn("", "", false, { minWidth: "120px", maxWidth: "120px" }),
    generateActionsColumn(formatterActions),
  ];

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          const response = await cessionApi.getPeriods();
          setPeriods(response.periods);
        } catch (e) {
          setErrorText(t("Error loading the list of periods."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getClientNames({});
          setClientsNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of clients."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveNames({});
          setCaptivesNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives names."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveCodes({});
          setCaptivesCodes(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives codes."));
        }
      });
    }
  }, [authContext.isAuthenticated]);

  const downloadCessionsStatements = async () => {
    try {
      await statementsApi.downloadCessionStatements(
        selectedCessionStatementsIds
      );
    } catch (e) {
      setErrorText(
        t("Failed to download the cession statements. Please, try again later.")
      );
    }
  };

  const [
    selectedCessionStatementsIds,
    setSelectedCessionStatementsIds,
  ] = useState([]);
  const [
    selectedCessionStatementsNames,
    setSelectedCessionStatementsNames,
  ] = useState([]);

  const handleSelectionChanged = (selectedRows) => {
    const selectedCessionStatementsIds = selectedRows.map((r) => r.id);
    const selectedCessionStatementsNames = selectedRows.map(
      (r) => r.cessionStatementFileName
    );
    setSelectedCessionStatementsIds(selectedCessionStatementsIds);
    setSelectedCessionStatementsNames(selectedCessionStatementsNames);
  };

  const handleCessionStatementClick = async (e, row) => {
    e.stopPropagation();

    try {
      await statementsApi.downloadCessionStatement(row.id);
    } catch (e) {
      setErrorText(
        t("Failed to download the cession statement. Please, try again later.")
      );
    }
  };

  return (
    <div className="text-center container-fluid cession-statement">
      {errorText && <div className="text-center text-danger">{errorText}</div>}
      <div className="row header-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="col-xl-9 col-lg-12 padding-0">
          <StatementsList
            columns={columns}
            title={t("Cession Statements")}
            periods={periods}
            clientsNames={clientsNames}
            captivesNames={captivesNames}
            captivesCodes={captivesCodes}
            handleSelectionChanged={handleSelectionChanged}
            getData={statementsApi.getCessionStatementsList}
            formatterActions={formatterActions}
          />
        </div>
        <div className="col-xl-3 col-lg-12 card">
          <div className="card-header d-flex justify-content-between">
            <span>{t("Download Summary")}</span>
          </div>
          <div className="card-body">
            <DocumentsDownload
              selectedDocuments={selectedCessionStatementsNames}
              onClick={downloadCessionsStatements}
              text={t("Cession Statements")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CessionStatementsPage;
