import React from "react";

function IconMobileMenu(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M2.25 18.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25zM2.25 12.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25zM2.25 6.753a.75.75 0 010-1.5h19.5a.75.75 0 010 1.5H2.25z"
      ></path>
    </svg>
  );
}

export default IconMobileMenu;
