import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./users/usersSlice";
import bankContactsReducer from "./bankContacts/bankContactsSlice";
import clientsReducer from "./clients/clientsSlice";
import pagesReducer from "./pages/pagesSlice";
import moduleConfigReducer from "./moduleConfig/moduleConfigSlice";
import emailBodiesReducer from "./emailBodies/emailBodiesSlice";
import entitiesReducer from "./clients/entitiesSlice";
import inviteUsersReducer from "./inviteUsers/inviteUsersSlice";
import userDetailsReducer from "./users/userDetailsSlice";
import user2FAReducer from "./users/user2FASlice";
import payeeAccountReducer from "./payeeAccount/payeeAccountSlice";
import transferRequestReducer from "./transferRequest/transferRequestSlice";
import notificationsReducer from "./notifications/notificationsSlice";
import reportsReducer from "./reports/reportsSlice";

export default configureStore({
  reducer: {
    usersStore: usersReducer,
    bankContactsStore: bankContactsReducer,
    clientsStore: clientsReducer,
    pagesStore: pagesReducer,
    moduleConfigStore: moduleConfigReducer,
    emailBodiesStore: emailBodiesReducer,
    entitiesStore: entitiesReducer,
    inviteUsersStore: inviteUsersReducer,
    userDetailsStore: userDetailsReducer,
    user2FAStore: user2FAReducer,
    payeeAccountStore: payeeAccountReducer,
    transferRequestStore: transferRequestReducer,
    notificationsStore: notificationsReducer,
    reportsStore: reportsReducer,
  },
});
