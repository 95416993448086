import React, { useState, useEffect } from "react";
import ReleaseRulesList from "../components/releaserules/ReleaseRulesList";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../Auth";
import clientsApi from "../api/clients.api";
import { useTranslation } from "react-i18next";
import { selectCurrentUserInfo } from "../features/users/userDetailsSlice.js";
import AlertBox from "../components/general/AlertBox";
import IconWarning from "../assets/icons/IconWarning";
import { Button } from "reactstrap";

function ReleaseRulesPage() {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [clientsNames, setClientsNames] = useState([]);
  const [captivesNames, setCaptivesNames] = useState([]);
  const [captivesCodes, setCaptivesCodes] = useState([]);
  const [errorText, setErrorText] = useState("");
  const history = useHistory();

  const currentUserInfo = useSelector(selectCurrentUserInfo);
  // console.log(
  //   "currentUserInfo on Release Rules Page " + JSON.stringify(currentUserInfo)
  // );

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          const response = await clientsApi.getClientNames({});
          setClientsNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of clients."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveNames({});
          setCaptivesNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives names."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveCodes({});
          setCaptivesCodes(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives codes."));
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, t]);

  return (
    <div className="text-center container-fluid release-rule">
      {errorText && <div className="text-center text-danger">{errorText}</div>}
      <div className="row header-row">
        <div className="col-10">
          <ReleaseRulesList
            clientsNames={clientsNames}
            captivesNames={captivesNames}
            captivesCodes={captivesCodes}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </div>
  );
}

export default ReleaseRulesPage;
