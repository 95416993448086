import api from ".";

const getClients = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}client`;
  return api.get(fetchApiUrl, params, {});
};

const getClient = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}client/${id}`;
  return api.get(fetchApiUrl, null, {});
};

const getClientNames = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}client/lookup/clientnames`;
  return api.get(fetchApiUrl, params, {});
};

const getCaptiveNames = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}client/lookup/captivenames`;
  return api.get(fetchApiUrl, params, {});
};

const getCaptiveCodes = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}client/lookup/captivecodes`;
  return api.get(fetchApiUrl, params, {});
};

const addClient = async (client) => {
  const fetchApiUrl = `${api.getApiUrl()}client`;
  return api.post(fetchApiUrl, null, client);
};

const editClient = async (client) => {
  const fetchApiUrl = `${api.getApiUrl()}client/${client.id}`;
  return api.put(fetchApiUrl, null, client);
};

const deleteClient = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}client/${id}`;
  return api.del(fetchApiUrl, null, null, {});
};

const getSummary = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}client/summary`;
  return api.get(fetchApiUrl, params, {});
};

export default {
  getClients,
  getClient,
  getClientNames,
  getCaptiveNames,
  getCaptiveCodes,
  addClient,
  editClient,
  deleteClient,
  getSummary
};
