import React from "react";

function IconBanksConnected(props) {
  let color = props.color ? props.color : "";
  let arrowsColor = props.arrowsColor ? props.arrowsColor : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
        <path fill={arrowsColor}
            d="M15.45 15.78h-1.59a2.21 2.21 0 01-2.2-2.2v-.29A1.7 1.7 0 0010 11.6H8.27a.26.26 0 01-.27-.26.25.25 0 01.25-.25H10a1.66 1.66 0 001.7-1.66v-.28A2.21 2.21 0 0113.86 7h1.59a.25.25 0 01.25.25.26.26 0 01-.25.26h-1.59a1.69 1.69 0 00-1.69 1.69v.28A2.14 2.14 0 0111 11.35a2.21 2.21 0 011.17 1.94v.29a1.69 1.69 0 001.69 1.69h1.59a.26.26 0 010 .51z" />      
        <path fill={color}
            d="M7.56 16.1H.18a.18.18 0 01-.18-.18.19.19 0 01.18-.19h7.38a.19.19 0 01.18.19.18.18 0 01-.18.18zM7.56 15.05H.18a.19.19 0 01-.18-.19.18.18 0 01.18-.18h7.38a.18.18 0 01.18.18.19.19 0 01-.18.19zM.71 14a.18.18 0 01-.18-.18v-2.65a.18.18 0 01.36 0v2.64a.18.18 0 01-.18.19zM1.76 14a.18.18 0 01-.18-.18v-2.65a.18.18 0 01.18-.17.19.19 0 01.19.18v2.64a.19.19 0 01-.19.18zM3.34 14a.18.18 0 01-.18-.18v-2.65a.18.18 0 01.18-.17.18.18 0 01.19.18v2.64a.18.18 0 01-.19.18zM4.4 14a.18.18 0 01-.18-.18v-2.65a.18.18 0 01.36 0v2.64a.18.18 0 01-.18.19zM6 14a.18.18 0 01-.18-.18v-2.65a.18.18 0 01.36 0v2.64A.18.18 0 016 14zM7 14a.18.18 0 01-.18-.18v-2.65A.18.18 0 017 11a.19.19 0 01.19.18v2.64A.19.19 0 017 14zM7.56 10.3H.18a.17.17 0 01-.18-.13.18.18 0 01.09-.17l3.4-2a.71.71 0 01.77 0l3.39 2a.2.2 0 01.09.21.19.19 0 01-.18.09zM.85 9.94H6.9L4.06 8.25a.35.35 0 00-.37 0z"/>
        <path fill={color}
            d="M23.82 10.14h-7.38a.19.19 0 01-.18-.19.18.18 0 01.18-.18h7.38A.18.18 0 0124 10a.19.19 0 01-.18.14zM23.82 9.08h-7.38a.18.18 0 010-.36h7.38a.18.18 0 010 .36zM17 8a.19.19 0 01-.19-.19v-2.6A.19.19 0 0117 5a.18.18 0 01.18.18v2.66A.18.18 0 0117 8zM18 8a.18.18 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.63A.18.18 0 0118 8zM19.6 8a.18.18 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.63a.18.18 0 01-.18.16zM20.66 8a.18.18 0 01-.19-.19v-2.6a.18.18 0 01.19-.21.18.18 0 01.18.18v2.66a.18.18 0 01-.18.16zM22.24 8a.19.19 0 01-.19-.19v-2.6a.19.19 0 01.19-.21.18.18 0 01.18.18v2.66a.18.18 0 01-.18.16zM23.29 8a.18.18 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.63a.18.18 0 01-.18.16zM23.82 4.34h-7.38a.19.19 0 01-.18-.14.18.18 0 01.09-.2l3.4-2a.72.72 0 01.76 0l3.4 2a.16.16 0 01.08.2.18.18 0 01-.17.14zM17.1 4h6l-2.78-1.71a.33.33 0 00-.38 0z"/>
        <path fill={color}
            d="M23.82 21.64h-7.38a.19.19 0 01-.18-.19.18.18 0 01.18-.18h7.38a.18.18 0 01.18.18.19.19 0 01-.18.19zM23.82 20.58h-7.38a.18.18 0 010-.36h7.38a.18.18 0 010 .36zM17 19.53a.19.19 0 01-.19-.19v-2.63a.19.19 0 01.19-.18.18.18 0 01.18.18v2.63a.18.18 0 01-.18.19zM18 19.53a.18.18 0 01-.18-.19v-2.63a.18.18 0 01.36 0v2.63a.18.18 0 01-.18.19zM19.6 19.53a.18.18 0 01-.18-.19v-2.63a.18.18 0 01.36 0v2.63a.18.18 0 01-.18.19zM20.66 19.53a.18.18 0 01-.19-.19v-2.63a.18.18 0 01.19-.18.18.18 0 01.18.18v2.63a.18.18 0 01-.18.19zM22.24 19.53a.19.19 0 01-.19-.19v-2.63a.19.19 0 01.19-.18.18.18 0 01.18.18v2.63a.18.18 0 01-.18.19zM23.29 19.53a.18.18 0 01-.18-.19v-2.63a.18.18 0 01.36 0v2.63a.18.18 0 01-.18.19zM23.82 15.84h-7.38a.19.19 0 01-.18-.14.18.18 0 01.09-.2l3.4-2a.72.72 0 01.76 0l3.4 2a.16.16 0 01.08.2.18.18 0 01-.17.14zm-6.72-.37h6l-2.83-1.68a.35.35 0 00-.38 0z"/>
    </svg>
  );
}

export default IconBanksConnected;