import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../scss/components/bankcontacts/_bank_contact_list.scss";

import { Form, Input, FormGroup } from "reactstrap";
import { debounce } from "throttle-debounce";
import { sortCaret } from "../general/BootstrapTableSettings";
import {
  IconTrash,
  IconPencil,
  IconAdd,
  IconSearch,
  IconEye,
} from "../../assets/icons";

import {
  loadBankContacts,
  selectBankContacts,
  selectIsLoading,
  selectDeleteError,
  selectDeleteSuccess,
  selectIsDeleting,
  selectShowBankContactModal,
  selectTotal,
  showEditBankContactModal,
  showNewBankContactModal,
  loadBankNames,
  selectBankNames,
  deleteBankContact,
  resetBankContactDeleteStatus,
  selectLastBankContactsSearchParams,
} from "../../features/bankContacts/bankContactsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../Auth";
import AddEditBankContact from "./AddEditBankContact";
import Loader from "../general/Loader";
import AlertBox from "../general/AlertBox";
import ConfirmationBox from "../general/ConfirmationBox";

function BankContactsList() {
  const t = useTranslation().t;
  const [sortField, setSortField] = useState("bankName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sizePerPage, setSizePerPage] = useState(10);
  const [bankId, setBankId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const bankContacts = useSelector(selectBankContacts);
  const bankNames = useSelector(selectBankNames);
  const [page, setPage] = useState(1);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);
  const [bankContactToDelete, setBankContactToDelete] = useState(null);
  const totalSize = useSelector(selectTotal);
  const isDeleting = useSelector(selectIsDeleting);
  const deleteSuccess = useSelector(selectDeleteSuccess);
  const deleteError = useSelector(selectDeleteError);
  const isLoading = useSelector(selectIsLoading);
  const authContext = useAuthContext();
  const dispatch = useDispatch();

  const showBankContactModal = useSelector(selectShowBankContactModal);

  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  const lastSearchParams = useSelector(selectLastBankContactsSearchParams);

  const debouncedSearch = debounce(500, (searchValue) => {
    setPage(1);
    setSearchValue(searchValue);
  });

  useEffect(() => {
    let params = {
      page: page - 1,
      bankId: bankId,
      searchText: searchValue,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder === "desc",
    };

    if (authContext.isAuthenticated) {
      dispatch(loadBankContacts(params));
    }
  }, [
    dispatch,
    authContext.isAuthenticated,
    page,
    sizePerPage,
    sortField,
    sortOrder,
    bankId,
    searchValue,
  ]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(loadBankNames()); // Load bank names once on initial load
    }
  }, [authContext.isAuthenticated, dispatch]);

  const handleTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const handleSearch = (e) => {
    e.persist();
    e.preventDefault();
    debouncedSearch(e.target.value);
  };

  const handleBankSelected = (e) => {
    setBankId(e.target.value);
  };

  const addContact = () => {
    dispatch(showNewBankContactModal());
  };

  const editContact = (bankContact) => {
    dispatch(showEditBankContactModal(bankContact));
  };

  const handleDeleteBankContact = (bankContact) => {
    dispatch(resetBankContactDeleteStatus());
    setBankContactToDelete(bankContact);
    setShowDeleteConfirmationModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmationModal(false);
    setBankContactToDelete(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteBankContact(bankContactToDelete.id, lastSearchParams));
  };

  const formatterActions = (_cell, row, _rowIndex, _formatExtraData) => {
    const hasNoClientsLinked = row.linkedClients === 0;

    return (
      <>
        <i onClick={() => editContact(row)}>
          <IconEye />
        </i>
        <i
          onClick={() => handleDeleteBankContact(row)}
          className={`${hasNoClientsLinked ? "" : "action-disabled"}`}
        >
          <IconTrash />
        </i>
      </>
    );
  };

  const formatterCityStateZip = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <span>
        {row.city}, {row.state} {row.zip}
      </span>
    );
  };

  const displayShowBankDetailsModal = (row) => {
    setShowBankDetailsModal(true);
    setBankDetails(row);
  };

  const hideBankDetailsModal = () => {
    setShowBankDetailsModal(false);
  };

  const formatBankDetails = (bankDetails) => {
    if (bankDetails) {
      return (
        <div className="bankDetails w-100">
          <div className="text-center">
            {bankDetails.bankName ? (
              <div className="font-weight-bold">{bankDetails.bankName}</div>
            ) : (
              false
            )}
            {bankDetails.bankName ? <div>{bankDetails.address}</div> : false}
            {bankDetails.city || bankDetails.state || bankDetails.zip ? (
              <div>
                <span>
                  {bankDetails.city ? `${bankDetails.city},` : false}{" "}
                  {bankDetails.state ? bankDetails.state : false}{" "}
                  {bankDetails.zip ? bankDetails.zip : false}
                </span>
              </div>
            ) : (
              false
            )}
          </div>
          <div className="d-flex justify-content-center mt-3 align-contents-start">
            <div className="w-50 text-right pr-2">
              <p>Primary Email:</p>
            </div>
            <div className="w-50 pl-2 d-flex align-content-start">
              <div>
                {bankDetails.primaryEmail ? bankDetails.primaryEmail : "n/a"}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-contents-start">
            <div className="w-50 text-right pr-2">
              <p>CC:</p>
            </div>
            <div className="w-50 pl-2 d-flex align-content-start flex-wrap">
              {bankDetails.ccEmails.map((ccEmail, index) => {
                return <div key={index}>{ccEmail ? ccEmail : "n/a"}</div>;
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const formatterCcEmails = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <span>
        {row.ccEmails && row.ccEmails.length > 0 ? (
          <span
            className="text-link text-capitalize"
            onClick={() => displayShowBankDetailsModal(row)}
          >
            Yes - {row.ccEmails.length}
          </span>
        ) : (
          "No"
        )}
      </span>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t("ID"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "bankName",
      text: t("Bank Name"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "address",
      text: t("Address"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "citystatezip",
      text: t("City/State/Zip"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
      formatter: formatterCityStateZip,
    },
    {
      dataField: "salutation",
      text: t("Salutation"),
      sort: true,
      title: true,
      headerClasses: "bank-contact-group",
      sortCaret: sortCaret,
    },
    {
      dataField: "contactName",
      text: t("Name"),
      sort: true,
      title: true,
      headerClasses: "bank-contact-group bank-contact-group-title",
      sortCaret: sortCaret,
    },
    {
      dataField: "primaryEmail",
      text: t("Primary Email"),
      sort: true,
      title: true,
      headerClasses: "bank-contact-group",
      sortCaret: sortCaret,
    },
    {
      dataField: "ccEmails",
      text: t("CC EMAIL"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
      formatter: formatterCcEmails,
    },
    {
      dataField: "linkedClients",
      text: t("Linked Clients"),
      sort: true,
      title: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "formatterActions",
      text: t(""),
      formatter: formatterActions,
      classes: "edit-delete-column",
    },
  ];

  return (
    <div className="BankContactList">
      <div className="BankContactList-header">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            {t("Banks & Letters")}
          </div>

          <div className="card-body">
            {/* Removed +Bank functionality from ER
            <div className="text-link mb-5" onClick={addContact}>
              <i>
                <IconAdd height="12" />
              </i>{" "}
              <span className="align-add-link">{t("Bank")}</span>
            </div>*/}

            <Form
              className="d-flex justify-content-lg-between flex-xl-wrap flex-lg-wrap flex-md-wrap flex-sm-wrap flex-xs-wrap"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="d-flex justify-content-start flex-xl-nowrap flex-lg-wrap flex-md-wrap flex-sm-wrap flex-xs-wrap">
                <FormGroup>
                  <Input
                    type="select"
                    name="bank"
                    id="bank"
                    onChange={handleBankSelected}
                  >
                    <option key="-1" value="">
                      {t("Bank")}
                    </option>
                    {bankNames.map((b) => {
                      return (
                        <option key={b.id} value={b.id}>
                          {b.name}
                        </option>
                      );
                    })}
                  </Input>
                  <label
                    className="floating-label filter-dropdown"
                    htmlFor="bank"
                  >
                    {t("Bank")}
                  </label>
                </FormGroup>
              </div>
              <div>
                <FormGroup className="d-flex justify-content-end">
                  <Input
                    type="search"
                    placeholder={t("Search...")}
                    name="search"
                    className="ml-15"
                    onChange={handleSearch}
                  />
                  <label className="floating-label search" htmlFor="search">
                    {t("Search...")}
                  </label>
                  <div className="form-icon" onClick={handleSearch}>
                    <i>
                      <IconSearch />
                    </i>
                  </div>
                </FormGroup>
              </div>
            </Form>

            <div className="table-responsive">
              <BootstrapTable
                remote
                keyField="id"
                data={bankContacts}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={handleTableChange}
                hover
                striped
                condensed
                noDataIndication={t("No bank contacts.")}
                bordered={false}
                hideSizePerPage={true}
                defaultSorted={[{ dataField: "bankName", order: "asc" }]}
              />
              {isLoading && <Loader />}
            </div>

            <AddEditBankContact isOpen={showBankContactModal} />
            <AlertBox
              isOpen={showBankDetailsModal}
              onClose={hideBankDetailsModal}
            >
              {formatBankDetails(bankDetails)}
            </AlertBox>
            <ConfirmationBox
              title={t("Confirmation")}
              text={
                t("You are about to delete") +
                ` ${bankContactToDelete?.bankName}`
              }
              buttonText={t("Continue")}
              onCancel={handleCancelDelete}
              isOpen={showDeleteConfirmationModal}
              onConfirm={handleConfirmDelete}
              isValid={true}
              isProcessing={isDeleting}
              errorText={deleteError}
              isSuccess={deleteSuccess}
              successTitle={t("Bank Contact Deleted")}
              successText={t("The bank contact has been deleted.")}
            ></ConfirmationBox>

            {/* <AddEditBankContact isOpen={showBankContactModal}></AddEditBankContact> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankContactsList;
