import { createSlice } from "@reduxjs/toolkit";
import bankContactApi from "../../api/bankContact.api";

export const bankContactsSlice = createSlice({
  name: "bankContactsStore",
  initialState: {
    isLoading: false,
    bankContacts: [],
    page: 1,
    totalCount: 0,
    showBankContactModal: false,
    isSaving: false,
    saveError: null,
    bankContact: null,
    isDeleting: false,
    deleteSuccess: false,
    deletingId: null,
    deleteError: null,
    bankNames: [],
    bankContactsLookup: [],
    isLoadingBankContactsLookup: false,
    lastSearchParams: {},
  },
  reducers: {
    loadBankContactsStart: (state) => {
      state.isLoading = true;
    },
    loadBankContactsSuccess: (state, data) => {
      const { dataPage, params } = data.payload;
      state.bankContacts = dataPage.data;
      state.totalCount = dataPage.totalCount;
      state.page = dataPage.page + 1;
      state.isLoading = false;
      state.lastSearchParams = params;
    },
    loadBankContactsFailure: (state) => {
      state.isLoading = false;
    },
    showBankContactModal: (state, action) => {
      state.showBankContactModal = true;
      state.bankContact = action.payload;
    },
    closeBankContactModal: (state) => {
      state.showBankContactModal = false;
    },
    saveBankContactStart: (state) => {
      state.isSaving = true;
      state.saveError = null;
    },
    saveBankContactSuccess: (state, action) => {
      state.bankContact = action.payload;
      state.isSaving = false;
      state.saveError = null;
      state.showBankContactModal = false;
    },
    saveBankContactFailure: (state, action) => {
      state.isSaving = false;
      state.saveError = action.payload.message;
    },
    resetBankContactDelete: (state) => {
      state.isDeleting = false;
      state.deleteSuccess = false;
      state.deletingId = null;
      state.deleteError = "";
    },
    deleteBankContactStart: (state, action) => {
      state.isDeleting = true;
      state.deleteSuccess = false;
      state.deletingId = action.payload.id;
      state.deleteError = "";
    },
    deleteBankContactSuccess: (state) => {
      state.isDeleting = false;
      state.deleteSuccess = true;
      state.deletingId = null;
      state.deleteError = "";
    },
    deleteBankContactFailure: (state, action) => {
      state.isSaving = false;
      state.deleteSuccess = false;
      state.deletingId = null;
      state.deleteError = action.payload.message;
    },
    loadBankNamesSuccess: (state, action) => {
      state.bankNames = action.payload.data;
    },
    loadBankContactsLookupStart: (state) => {
      state.isLoadingBankContactsLookup = true;
    },
    loadBankContactsLookupSuccess: (state, action) => {
      state.bankContactsLookup = action.payload.data;
      state.isLoadingBankContactsLookup = false;
    },
    loadBankContactsLookupFailure: (state) => {
      state.isLoadingBankContactsLookup = false;
    },
  },
});

// the state is the Root state, so you need to reference the usersStore here
export const selectBankContacts = (state) =>
  state.bankContactsStore.bankContacts;
export const selectPage = (state) => state.bankContactsStore.page;
export const selectTotal = (state) => state.bankContactsStore.totalCount;
export const selectIsLoading = (state) => state.bankContactsStore.isLoading;
export const selectIsSaving = (state) => state.bankContactsStore.isSaving;
export const selectBankContact = (state) => state.bankContactsStore.bankContact;
export const selectShowBankContactModal = (state) =>
  state.bankContactsStore.showBankContactModal;
export const selectSaveError = (state) => state.bankContactsStore.saveError;
export const selectIsDeleting = (state) => state.bankContactsStore.isDeleting;
export const selectDeleteSuccess = (state) =>
  state.bankContactsStore.deleteSuccess;
export const selectDeleteError = (state) => state.bankContactsStore.deleteError;
export const selectBankNames = (state) => state.bankContactsStore.bankNames;
export const selectBankContactsLookup = (state) =>
  state.bankContactsStore.bankContactsLookup;
export const selectIsLoadingBankContactsLookup = (state) =>
  state.bankContactsStore.isLoadingBankContactsLookup;
export const selectLastBankContactsSearchParams = (state) =>
  state.bankContactsStore.lastSearchParams;

// Don't expose to rest of the app.
const {
  loadBankContactsStart,
  loadBankContactsSuccess,
  loadBankContactsFailure,
  saveBankContactStart,
  saveBankContactSuccess,
  saveBankContactFailure,
  showBankContactModal,
  resetBankContactDelete,
  deleteBankContactStart,
  deleteBankContactSuccess,
  deleteBankContactFailure,
  loadBankNamesSuccess,
  loadBankContactsLookupStart,
  loadBankContactsLookupSuccess,
  loadBankContactsLookupFailure,
} = bankContactsSlice.actions;

export const loadBankContacts = (params) => async (dispatch) => {
  dispatch(loadBankContactsStart(params));
  try {
    let pagedDataResponse = await bankContactApi.getBankContacts(params);
    dispatch(loadBankContactsSuccess({dataPage: pagedDataResponse, params: params}));
  } catch (err) {
    console.error(err);
    dispatch(loadBankContactsFailure());
  }
};

export const loadBankNames = () => async (dispatch) => {
  try {
    let response = await bankContactApi.getBankNames();
    dispatch(loadBankNamesSuccess(response));
  } catch (err) {
    console.error(err);
  }
};

export const loadBankContactsLookup = (params) => async (dispatch) => {
  dispatch(loadBankContactsLookupStart());
  try {
    let response = await bankContactApi.getBankContactsLookup(params);
    dispatch(loadBankContactsLookupSuccess(response));
  } catch (err) {
    console.error(err);
    dispatch(loadBankContactsLookupFailure());
  }
};

export const showNewBankContactModal = () => (dispatch) => {
  dispatch(
    showBankContactModal({
      id: null,
      bankName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      contactName: "",
      salutation: "",
      primaryEmail: "",
      ccEmails: [],
    })
  );
};

export const showEditBankContactModal = (bankContact) => (dispatch) => {
  dispatch(showBankContactModal(bankContact));
};

export const closeBankContactModal = () => (dispatch) => {
  dispatch(bankContactsSlice.actions.closeBankContactModal());
};

export const addBankContact = (bankContact, lastSearchParams) => async (dispatch) => {
  dispatch(saveBankContactStart());
  try {
    let newBankContact = await bankContactApi.addBankContact(bankContact);
    dispatch(saveBankContactSuccess(newBankContact));
  } catch (err) {
    console.error(err);
    dispatch(saveBankContactFailure({ message: "Error saving" }));
  }
  dispatch(loadBankContacts(lastSearchParams));
  dispatch(loadBankNames());
};

export const editBankContact = (bankContact, lastSearchParams) => async (dispatch) => {
  dispatch(saveBankContactStart());
  try {
    let newBankContact = await bankContactApi.editBankContact(bankContact);
    dispatch(saveBankContactSuccess(newBankContact));
  } catch (err) {
    console.error(err);
    dispatch(saveBankContactFailure({ message: "Error saving" }));
  }
  dispatch(loadBankContacts(lastSearchParams));
  dispatch(loadBankNames());
};

export const deleteBankContact = (id, lastSearchParams) => async (dispatch) => {
  dispatch(deleteBankContactStart({ id }));
  try {
    await bankContactApi.deleteBankContact(id);
    dispatch(deleteBankContactSuccess());
  } catch (err) {
    console.error(err);
    dispatch(
      deleteBankContactFailure({ message: "Error deleting bank contact" })
    );
  }
  dispatch(loadBankContacts(lastSearchParams));
};

export const resetBankContactDeleteStatus = () => (dispatch) => {
  dispatch(resetBankContactDelete());
};

export default bankContactsSlice.reducer;
