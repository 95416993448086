import api from ".";

const getConfig = async () => {
  const fetchApiUrl = `${api.getApiUrl()}module/config`;
  return api.get(fetchApiUrl, null, {});
};

export default {
  getConfig,
};
