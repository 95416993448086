import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Navbar } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../scss/components/layout/_topbar.scss";
import { withAuthContext } from "../../Auth";
import { IconBell, IconMobileMenu, IconVerticalMenu } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { loadNotificationsCount, selectNotificationsCount } from "../../features/notifications/notificationsSlice";

const TopBar = (props) => {
    const {
      t,
      toggleSidebar,
      toggleProfileMenu,
      location,
      excludeLocations,
      authContext,
      toggleNotificationsMenu,
    } = props;

    const dispatch = useDispatch();

    const userName = authContext.user ? authContext.user.name : null;
    const firstName = authContext.user ? authContext.user.given_name : null;
    const lastName = authContext.user ? authContext.user.family_name : null;
    const initials =
      firstName && lastName
        ? `${firstName.charAt(0)}${lastName.charAt(0)}`
        : null;
    let isActivate = excludeLocations.some(function (loc) {
      return location.pathname.startsWith(loc);
    });


  useEffect(() => {
    if (authContext.isAuthenticated) {
        dispatch(loadNotificationsCount());
    }
  }, [authContext.isAuthenticated, dispatch]);

    const notificationsCount = useSelector(selectNotificationsCount);
    return !(excludeLocations.includes(location.pathname) || isActivate) ? (
      <Navbar
        color="light"
        light
        className="navbar shadow p-2 bg-white topbar"
        expand="md"
      >
        <div className="logo text-left">
          <Link to="/">
            <img alt={t("Assurant")} src="../images/assurant-logo.svg"></img>
          </Link>
        </div>
        <div className="toggle-menu toggle-sidebar" onClick={toggleSidebar}>
          <i className="d-flex">
            <IconMobileMenu />
          </i>
        </div>

        <div className="topbar-user d-flex flex-nowrap justify-content-center align-items-center">
          <div className="notification mr-5" onClick={toggleNotificationsMenu}>
            <i>
              <IconBell height="25" />
            </i>
            <span className="badge badge-">{notificationsCount}</span>
          </div>

          <Link
            to="/user-profile"
            className="profile-link pl-5 d-flex flex-nowrap justify-content-center align-items-center"
          >
            <span className="profile-image">{initials}</span>
            <span className="profile-name">{userName}</span>
          </Link>
        </div>

        <div
          className="toggle-menu toggle-profile-menu"
          onClick={toggleProfileMenu}
        >
          <i>
            <IconVerticalMenu />
          </i>
        </div>
      </Navbar>
    ) : (
      ""
    );
}

export default withTranslation()(withAuthContext(withRouter(TopBar)));
