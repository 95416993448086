import React from "react";

function IconDownload(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
    <path fill={color}
      d="M11.9992 13.5C11.9104 13.4997 11.8224 13.4838 11.7392 13.453L11.7172 13.445C11.6237 13.4077 11.539 13.3515 11.4682 13.28L6.9682 8.78C6.89451 8.71134 6.83541 8.62854 6.79442 8.53654C6.75343 8.44454 6.73139 8.34523 6.72961 8.24452C6.72783 8.14382 6.74636 8.04379 6.78408 7.9504C6.8218 7.85701 6.87794 7.77218 6.94916 7.70096C7.02038 7.62974 7.10521 7.5736 7.1986 7.53588C7.29199 7.49816 7.39202 7.47963 7.49272 7.48141C7.59343 7.48318 7.69274 7.50523 7.78474 7.54622C7.87674 7.58721 7.95954 7.64631 8.0282 7.72L11.2482 10.94V0.75C11.2482 0.551088 11.3272 0.360322 11.4679 0.21967C11.6085 0.0790175 11.7993 0 11.9982 0C12.1971 0 12.3879 0.0790175 12.5285 0.21967C12.6692 0.360322 12.7482 0.551088 12.7482 0.75V10.939L15.9682 7.719C16.0369 7.64531 16.1197 7.58621 16.2117 7.54522C16.3037 7.50423 16.403 7.48219 16.5037 7.48041C16.6044 7.47863 16.7044 7.49716 16.7978 7.53488C16.8912 7.5726 16.976 7.62874 17.0472 7.69996C17.1185 7.77118 17.1746 7.85601 17.2123 7.9494C17.25 8.04279 17.2686 8.14282 17.2668 8.24352C17.265 8.34423 17.243 8.44354 17.202 8.53554C17.161 8.62754 17.1019 8.71034 17.0282 8.779L12.5282 13.279C12.4577 13.3492 12.3741 13.4049 12.2822 13.443L12.2562 13.453C12.1741 13.4841 12.087 13.5 11.9992 13.5Z" />
    <path fill={color}
      d="M3.75002 18C2.75561 17.9992 1.80214 17.6039 1.0989 16.9008C0.395648 16.1978 8.26947e-05 15.2444 -0.000976562 14.25L-0.000976562 12.75C-0.000976562 12.5511 0.0780411 12.3603 0.218693 12.2197C0.359346 12.079 0.550111 12 0.749023 12C0.947936 12 1.1387 12.079 1.27935 12.2197C1.42001 12.3603 1.49902 12.5511 1.49902 12.75V14.25C1.49955 14.8466 1.73678 15.4186 2.15862 15.8404C2.58046 16.2622 3.15245 16.4995 3.74902 16.5H20.249C20.8456 16.4995 21.4176 16.2622 21.8394 15.8404C22.2613 15.4186 22.4985 14.8466 22.499 14.25V12.75C22.499 12.5511 22.578 12.3603 22.7187 12.2197C22.8593 12.079 23.0501 12 23.249 12C23.4479 12 23.6387 12.079 23.7794 12.2197C23.92 12.3603 23.999 12.5511 23.999 12.75V14.25C23.998 15.2441 23.6027 16.1971 22.8999 16.9001C22.197 17.6031 21.2441 17.9987 20.25 18H3.75002Z" />
    </svg>
  );
}

export default IconDownload;
