import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditModal from "../general/AddEditModal";
import StepIndicator from "../general/StepIndicator";
import {
  Form,
  FormGroup,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import PayeeAccountsList from "../releaserules/PayeeAccountsList";
import "../../scss/components/releaserules/_release_rules_wizard.scss";

const ReleaseRulesWizard = (props) => {
  const { t } = useTranslation();
  const [releaseConfig, setReleaseConfig] = useState({
    schedule: null,
    accountsOrder: props.autoReleaseAccounts,
  });

  const isSaving = false;
  const handleContinue = () => {
    props.handleContinueWizard();
  };

  const handleSave = () => {
    props.persistAutoRelease(props.selectedRule, releaseConfig);
  };

  const setAccountsOrder = (accounts) => {
    setReleaseConfig({ ...releaseConfig, accountsOrder: accounts });
  };

  const handleCancel = () => {
    setReleaseConfig({ schedule: "", accountsOrder: [] });
    props.handleCloseWizard();
  };

  const isValid = () => {
    const wizardStep = props.wizardStep;
    return (
      wizardStep !== 1 ||
      (wizardStep === 1 &&
        (props.selectedRule.autoRelease || releaseConfig.schedule))
    );
  };

  const invokeDropdownHandler = (selectedSchedule) => {
    setReleaseConfig({ ...releaseConfig, schedule: selectedSchedule });
  };

  const scheduleOptions = [
    {
      title: t("Annually"),
      description:
        t("Request will be submitted on 12/21/") +
        new Date().getFullYear() +
        " " +
        t("and every 21st of December thereafter."),
    },
    {
      title: t("Quarterly"),
      description: t(
        "Requests are submitted on the 21st of March, June, September, and December."
      ),
    },
    {
      title: t("Monthly"),
      description: t("Requests are submitted on the 21st of each month."),
    },
  ];

  const wizardButtonLabels = [t("Continue"), t("Continue"), t("Save")];
  const wizardStep = props.wizardStep;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  let indicatorSteps = [
    {
      stepLabel: t("Distribution Schedule"),
      stepCompleted: wizardStep > 1,
      back: "",
      next: "",
    },
    {
      stepLabel: t("Transfer Order"),
      stepCompleted: false,
      back: "",
      next: "",
    },
  ];

  const tooltipContent = (
    <div>
      <p>
        {t(
          "Funds will be released from the accounts below based on their position (prioritization) below."
        )}
      </p>
      <p>
        {t(
          "In the case where there is more than one account, if the release amount exceeds that available in the first account, it will go on to the second account, and so forth."
        )}
      </p>
      <p>
        {t(
          "Funds will be dispersed to the payee accounts based on the allocation you have set up in ‘Bank Accounts’."
        )}
      </p>
    </div>
  );

  const configuredSchedule = releaseConfig.schedule
    ? releaseConfig.schedule
    : props.selectedRule?.autoRelease;

  return (
    <>
      <EditModal
        isOpen={props.showWizard}
        buttonText={wizardButtonLabels[wizardStep]}
        isBusy={isSaving}
        showCancel={true}
        onConfirm={(e) => (wizardStep !== 2 ? handleContinue() : handleSave())}
        onCancel={handleCancel}
        isValid={isValid()}
        title={t("Auto Release")}
        zIndex={0}
        tooltipContent={wizardStep !== 2 ? null : tooltipContent}
      >
        <Form
          className="search-form d-flex justify-content-lg-between flex-xl-wrap flex-lg-wrap flex-md-wrap flex-sm-wrap flex-xs-wrap"
          onSubmit={(e) => e.preventDefault()}
        >
          {wizardStep === 1 && (
            <FormGroup>
              <p>
                {t(
                  "Manual requests can also be made, subject to funds availability"
                )}
              </p>
            </FormGroup>
          )}
          {wizardStep === 2 && (
            <FormGroup>
              <p>
                {t("Allocation percentages may be updated in 'Bank Accounts'")}
              </p>
            </FormGroup>
          )}

          {wizardStep !== 0 && (
            <FormGroup>
              <StepIndicator
                steps={indicatorSteps}
                currentStep={wizardStep - 1}
              />
            </FormGroup>
          )}

          {wizardStep === 0 && (
            <FormGroup>
              <p>
                {t(
                  "By activating auto-release, You are requesting that Assurant approve all available funds be released automatically from your trust account on the frequency you choose. Once a withdrawal request has been made, the transaction may not be reversed."
                )}
              </p>
              <p>
                {t(
                  "If your agreement with the bank states that bank fees may be deducted from available balances, the bank may elect to do so at their discretion."
                )}
              </p>
              <p>
                {t(
                  "You confirm that the bank account information you have provided is accurate."
                )}
              </p>
              <p>
                {t(
                  "Assurant is solely responsible for sending your bank its written approval of your withdrawal request. Assurant is NOT responsible for actual releases by the bank, including but not limited to the timing of the releases. If your agreement with the bank states that bank fees may be deducted from available balances, the bank may elect to do so at their discretion. For any questions about the release from your trust account, please contact your bank."
                )}
              </p>
            </FormGroup>
          )}
          {wizardStep === 1 && (
            <FormGroup>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="schedule-dropdown"
              >
                <DropdownToggle caret>
                  {configuredSchedule || t("Select a Release Schedule")}
                </DropdownToggle>
                <DropdownMenu>
                  {scheduleOptions.map((ars) => {
                    return (
                      <DropdownItem
                        key={ars.title}
                        onClick={() => invokeDropdownHandler(ars.title)}
                      >
                        <p>
                          <strong>{ars.title}</strong>
                        </p>
                        <p>{ars.description}</p>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <p className="pl-1 pb-3 pt-3">
                {
                  scheduleOptions.find((e) => e.title === configuredSchedule)
                    ?.description
                }
              </p>
            </FormGroup>
          )}
          {wizardStep === 2 && (
            <>
              <FormGroup>
                <p>
                  {t("Drag and drop banks in the order of desired transfer.")}
                </p>
              </FormGroup>
              <FormGroup>
                <PayeeAccountsList
                  client={props.selectedRule.clientName}
                  trustAccounts={props.autoReleaseAccounts}
                  setAccountsOrder={setAccountsOrder}
                />
              </FormGroup>
            </>
          )}
        </Form>
      </EditModal>
    </>
  );
};

export default ReleaseRulesWizard;
