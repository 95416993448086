import { createSlice } from "@reduxjs/toolkit";
import { statusData } from "../../api/index.js";
import userApi from "../../api/user.api";
import inviteUsersApi from "../../api/inviteUsers.api";
import { getPermissionGroupByName } from "../moduleConfig/moduleConfigSlice";

const {
  initialStatus,
  loadStatus,
  successStatus,
  failureStatus,
  copyStatus,
} = statusData;

export const userDetailsSlice = createSlice({
  name: "userDetailsStore",
  initialState: {
    userId: null,
    isMyProfile: false,
    currentUserInfo: null,
    newUserInfo: null,
    permissionGroup: null,
    selectedClients: [], // array of client ids (a.k.a. captives)
    loadUserInfoStatus: copyStatus(initialStatus),
    updateUserInfoStatus: copyStatus(initialStatus),
    updateClientsStatus: copyStatus(initialStatus),
    updatePermissionGroupStatus: copyStatus(initialStatus),
    inviteUserStatus: copyStatus(initialStatus),
    isSaving: false,
  },
  reducers: {
    loadUserInfoStart: (state) => {
      state.loadUserInfoStatus = copyStatus(loadStatus);
    },
    loadUserInfoSuccess: (state, action) => {
      state.loadUserInfoStatus = copyStatus(successStatus);
      state.currentUserInfo = action.payload.user;
      state.newUserInfo = Object.assign({}, action.payload.user);
      state.permissionGroup = action.payload.permissionGroup;
      state.selectedClients = action.payload.clients ?? [];
    },
    loadUserInfoFailure: (state) => {
      state.loadUserInfoStatus = copyStatus(failureStatus);
    },
    updateUserInfoStart: (state) => {
      state.updateUserInfoStatus = copyStatus(loadStatus);
    },
    updateUserInfoSuccess: (state) => {
      state.updateUserInfoStatus = copyStatus(successStatus);
    },
    updateUserInfoFailure: (state) => {
      state.updateUserInfoStatus = copyStatus(failureStatus);
    },
    updateClientsStart: (state) => {
      state.isSaving = true;
      state.updateClientsStatus = copyStatus(loadStatus);
    },
    updateClientsSuccess: (state) => {
      state.isSaving = false;
      state.updateClientsStatus = copyStatus(successStatus);
    },
    updateClientsFailure: (state) => {
      state.isSaving = false;
      state.updateClientsStatus = copyStatus(failureStatus);
    },
    updatePermissionGroupStart: (state) => {
      state.isSaving = true;
      state.updatePermissionGroupStatus = copyStatus(loadStatus);
    },
    updatePermissionGroupSuccess: (state) => {
      state.isSaving = false;
      state.updatePermissionGroupStatus = copyStatus(successStatus);
    },
    updatePermissionGroupFailure: (state) => {
      state.isSaving = false;
      state.updatePermissionGroupStatus = copyStatus(failureStatus);
    },
    inviteUserStart: (state) => {
      state.inviteUserStatus = copyStatus(loadStatus);
    },
    inviteUserSuccess: (state) => {
      state.inviteUserStatus = copyStatus(successStatus);
    },
    inviteUserFailure: (state) => {
      state.inviteUserStatus = copyStatus(failureStatus);
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsMyProfile: (state, action) => {
      state.isMyProfile = action.payload;
    },
    setNewUserInfo: (state, action) => {
      state.newUserInfo = action.payload;
    },
    setPermissionGroup: (state, action) => {
      state.permissionGroup = action.payload;
    },
    setSelectedClients: (state, action) => {
      state.selectedClients = action.payload;
    },
    reset: (state) => {
      // Set everything to the init state
      state.userId = null;
      state.isMyProfile = false;
      state.currentUserInfo = null;
      state.newUserInfo = null;
      state.permissionGroup = null;
      state.selectedClients = [];
      state.loadUserInfoStatus = copyStatus(initialStatus);
      state.updateUserInfoStatus = copyStatus(initialStatus);
      state.updateClientsStatus = copyStatus(initialStatus);
      state.updatePermissionsStatus = copyStatus(initialStatus);
      state.inviteUserStatus = copyStatus(initialStatus);
    },
  },
});

// the state is the Root state, so you need to reference the userDetailsStore here
// SELECT
export const selectLoadUserInfoStatus = (state) =>
  state.userDetailsStore.loadUserInfoStatus;
export const selectInviteUserStatus = (state) =>
  state.userDetailsStore.inviteUserStatus;

export const selectCurrentUserInfo = (state) =>
  state.userDetailsStore.currentUserInfo;
export const selectNewUserInfo = (state) => state.userDetailsStore.newUserInfo;
export const selectIsMyProfile = (state) => state.userDetailsStore.isMyProfile;
export const selectIsSaving = (state) => state.userDetailsStore.isSaving;
export const selectPermissionGroup = (state) =>
  state.userDetailsStore.permissionGroup;
export const selectSelectedClients = (state) =>
  state.userDetailsStore.selectedClients;

// SET
export const setUserId = (userId) => (dispatch) =>
  dispatch(userDetailsSlice.actions.setUserId(userId));
export const setIsMyProfile = (isMyProfile) => (dispatch) =>
  dispatch(userDetailsSlice.actions.setIsMyProfile(isMyProfile));
export const setNewUserInfo = (newUserInfo) => (dispatch) =>
  dispatch(userDetailsSlice.actions.setNewUserInfo(newUserInfo));
export const setPermissionGroup = (permissionGroup) => (dispatch) =>
  dispatch(userDetailsSlice.actions.setPermissionGroup(permissionGroup));
export const setSelectedClients = (clients) => (dispatch) =>
  dispatch(userDetailsSlice.actions.setSelectedClients(clients));

// Don't expose to rest of the app.
const {
  loadUserInfoStart,
  loadUserInfoSuccess,
  loadUserInfoFailure,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFailure,
  updatePermissionGroupStart,
  updatePermissionGroupSuccess,
  updatePermissionGroupFailure,
  updateClientsStart,
  updateClientsSuccess,
  updateClientsFailure,
  inviteUserStart,
  inviteUserSuccess,
  inviteUserFailure,
  reset,
} = userDetailsSlice.actions;

export const resetUserInfo = () => (dispatch) => dispatch(reset());

export const loadUserInfo = (userId, user, moduleConfig) => async (
  dispatch
) => {
  dispatch(loadUserInfoStart());
  try {
    if (!userId) {
      dispatch(setIsMyProfile(true));
      dispatch(setUserId(user.sub));
    } else {
      dispatch(setIsMyProfile(userId === user.sub));
      dispatch(setUserId(userId));
    }

    const userDetails = await userApi.getUser(userId);
    const permissionGroup = getPermissionGroupByName(
      moduleConfig,
      userDetails.permission
    );
    const selectedClients = userDetails.clients;

    dispatch(
      loadUserInfoSuccess({
        user: userDetails,
        permissionGroup,
        clients: selectedClients,
      })
    );
  } catch (err) {
    console.error(err);
    dispatch(loadUserInfoFailure());
  }
};

export const updateUserInfo = (newUserInfo) => async (dispatch) => {
  dispatch(updateUserInfoStart());
  try {
    const updatedUser = await userApi.putUser(newUserInfo);

    dispatch(updatedUser ? updateUserInfoSuccess() : updateUserInfoFailure());

    return !!updatedUser;
  } catch (err) {
    console.log(err);
    dispatch(updateUserInfoFailure());
    return false;
  }
};

export const updateUserPermissionGroup = (userId, permissionGroup) => async (
  dispatch
) => {
  dispatch(updatePermissionGroupStart());
  try {
    await userApi.putUserPermissionGroup({
      userId,
      permissionGroup: permissionGroup.oktaName,
    });
    dispatch(updatePermissionGroupSuccess());
    return true;
  } catch (err) {
    console.log(err);
    dispatch(updatePermissionGroupFailure());
    return false;
  }
};

export const updateUserClients = (userId, captiveIds) => async (dispatch) => {
  dispatch(updateClientsStart());
  try {
    await userApi.putUserClients({ userId, clients: captiveIds });
    dispatch(updateClientsSuccess());
    return true;
  } catch (err) {
    console.log(err);
    dispatch(updateClientsFailure());
    return false;
  }
};

export const inviteUser = (userId) => async (dispatch) => {
  dispatch(inviteUserStart());
  try {
    const inviteUserStatus = await inviteUsersApi.resendUserInvite(userId);

    dispatch(inviteUserStatus ? inviteUserSuccess() : inviteUserFailure());

    return !!inviteUserStatus;
  } catch (err) {
    console.log(err);
    dispatch(inviteUserFailure());
    return false;
  }
};

export default userDetailsSlice.reducer;
