import React from "react";

function IconBankWithArrows(props) {
  let arrowsColor = props.arrowsColor ? props.arrowsColor : "";
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
    <path fill={arrowsColor}
        d="M3.1 12a.16.16 0 01-.1 0L.05 9.06a.19.19 0 010-.26.19.19 0 01.26 0l2.79 2.79L5.89 8.8a.18.18 0 01.25 0 .17.17 0 010 .26L3.23 12a.18.18 0 01-.13 0zM18 15.24a.18.18 0 01-.12-.05.17.17 0 010-.26L20.77 12a.19.19 0 01.23 0l3 2.93a.18.18 0 01-.26.26L20.9 12.4l-2.79 2.79a.19.19 0 01-.11.05z"/>
    <path fill={color}
        d="M15.83 16.28H8.17a.18.18 0 010-.36h7.66a.18.18 0 110 .36zM15.83 15.19H8.17a.18.18 0 010-.36h7.66a.18.18 0 010 .36zM8.72 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 01.36 0v2.73a.19.19 0 01-.18.19zM9.81 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 01.36 0v2.73a.19.19 0 01-.18.19zM11.45 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 11.36 0v2.73a.19.19 0 01-.18.19zM12.55 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 11.36 0v2.73a.19.19 0 01-.18.19zM14.19 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 01.36 0v2.73a.19.19 0 01-.18.19zM15.28 14.1a.19.19 0 01-.18-.19v-2.73a.18.18 0 11.36 0v2.73a.19.19 0 01-.18.19zM15.83 10.27H8.17a.18.18 0 01-.17-.14.16.16 0 01.08-.2l3.53-2.1a.73.73 0 01.78 0l3.53 2.1a.16.16 0 01.08.2.18.18 0 01-.17.14zm-7-.37h6.34l-3-1.76a.36.36 0 00-.4 0z"/>
    <path fill={arrowsColor}
        d="M12 21.12h-.19a9.12 9.12 0 01-8.25-5.64.18.18 0 01.1-.24.19.19 0 01.24.1 8.76 8.76 0 0016.86-3.16.19.19 0 01.18-.18.19.19 0 01.18.19A9.15 9.15 0 0112 21.12zM3.06 12a.18.18 0 01-.18-.18 9.13 9.13 0 0117.56-3.29.19.19 0 01-.1.23.18.18 0 01-.24-.1 8.76 8.76 0 00-16.86 3.16.17.17 0 01-.18.18z"/>
    </svg>
  );
}

export default IconBankWithArrows;