import api from ".";

const getCessionStatementsList = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/cession-statements`;
  return api.get(fetchApiUrl, params, {});
};

const getBankStatementsList = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/bank-statements`;
  return api.get(fetchApiUrl, params, {});
};

const downloadBankStatements = async (ids) => {
  const params = ids.join("&ids=");
  const fetchApiUrl = `${api.getApiUrl()}cession/bank-statements/file?ids=${params}`;
  return api.download(fetchApiUrl, {}, "");
};

const downloadBankStatement = async (trustAccountId) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/bank-statement/${trustAccountId}/file`;
  return api.download(fetchApiUrl, {}, "");
};

const downloadCessionStatements = async (ids) => {
  const params = ids.join("&ids=");
  const fetchApiUrl = `${api.getApiUrl()}cession/cession-period-statements/file?ids=${params}`;
  return api.download(fetchApiUrl, {}, "");
};

const downloadCessionStatement = async (trustAccountId) => {
  const fetchApiUrl = `${api.getApiUrl()}cession/cession-statement/${trustAccountId}/file`;
  return api.download(fetchApiUrl, {}, "");
};

export default {
  getCessionStatementsList,
  getBankStatementsList,
  downloadBankStatements,
  downloadCessionStatements,
  downloadBankStatement,
  downloadCessionStatement,
};
