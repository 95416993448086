import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable, {
  generateColumn,
  generateActionsColumn,
  formatAccount,
  formatMoney,
  formatPeriod,
  formatTransactionStatus,
} from "../general/DataTable";
import { IconDocument } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import cessionApi from "../../api/cession.api";
import transferRequestApi from "../../api/transferRequest.api";
import { DateUtils } from "../../utils/DateUtils";
import { BarLoader } from "react-spinners";

const NetDues = (props) => {
  const { t } = useTranslation();
  const [downloadingRowId, setDownloadingRowId] = useState(0);

  const showDocument = async (row) => {
    setDownloadingRowId(row.id);
    try {
      await transferRequestApi.downloadLetter(row.id);
    } finally {
      setDownloadingRowId(0);
    }
  };

  const [errorText, setErrorText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const formatterActions = (_cell, row, _, extraData) => (
    <div className="icon-action">
      <i
        disabled={extraData.downloadingRowId}
        onClick={() => showDocument(row)}
      >
        {extraData.downloadingRowId === row.id ? (
          <BarLoader height={4} width="75%"></BarLoader>
        ) : (
          <IconDocument height={20} />
        )}
      </i>
    </div>
  );

  const downloadAction = async () => {
    await cessionApi.downloadNetDues({
      ...searchParams,
      page: 0,
      pageSize: 1500,
    });
  };

  const columns = [
    generateColumn(
      "cessionPeriod",
      t("Cession Period"),
      true,
      { minWidth: "155px", maxWidth: "155px" },
      formatPeriod
    ),
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "265px",
      maxWidth: "265px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn("bankName", t("Account #"), false, {}, (_, row) =>
      formatAccount(row.bankName, row.trustAccountNumber)
    ),
    generateColumn("netDue", t("Amount"), false, {}, formatMoney),
    generateColumn(
      "transactionStatus",
      t("Status"),
      false,
      {},
      formatTransactionStatus
    ),
    generateActionsColumn(formatterActions, { downloadingRowId }),
  ];

  const [page, setPage] = useState({ data: [], page: 0, totalCount: 0 });

  const [searchParams, setSearchParams] = useState({
    cessionPeriod: "",
    clientNameId: "",
    captiveName: "",
    captiveCode: "",
    searchText: null,
    page: 1,
    pageSize: 6,
    sortBy: "cessionPeriod",
    descending: false,
  });

  const handleSearch = (e) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: e.target.value,
    });
  };

  const onTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  const handleDropdownSelection = (id, value) => {
    const newSearchParams = {
      ...searchParams,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const dispatch = useDispatch();
  const authContext = useAuthContext();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setIsLoadingData(true);
      dispatch(async () => {
        try {
          const result = await cessionApi.getNetDues({
            ...searchParams,
            page: searchParams.page - 1,
          });
          setPage(result);
          setErrorText("");
        } catch (e) {
          setErrorText(t("Error loading net dues data."));
        } finally {
          setIsLoadingData(false);
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, searchParams, t]);

  const dropdowns = [
    {
      id: "cessionPeriod",
      title: t("Cession Period"),
      values: props.periods.map((c) => {
        return { id: c, name: DateUtils.formatPeriod(c) };
      }),
      handleSelected: handleDropdownSelection,
      width: "155px",
    },
    {
      id: "clientNameId",
      title: t("Client"),
      values: props.clientsNames.map((c) => {
        return { id: c.id, name: c.name };
      }),
      handleSelected: handleDropdownSelection,
    },
    {
      id: "captiveName",
      title: t("Captive Name"),
      values: props.captivesNames.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "180px",
    },
    {
      id: "captiveCode",
      title: t("Captive Code"),
      values: props.captivesCodes.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
    },
  ];

  return (
    <div className="NetDuesList">
      <DataTable
        errorText={errorText}
        columns={columns}
        data={page.data}
        defaultSorted={[{ dataField: "cessionPeriod", order: "asc" }]}
        isLoading={false}
        keyField={"id"}
        defaultSortField="cessionPeriod"
        totalSize={page.totalCount}
        pageSize={searchParams.pageSize}
        page={searchParams.page}
        onTableChange={onTableChange}
        title={t("Collection of Net Due of Ceding Statements")}
        handleSearch={handleSearch}
        dropdowns={dropdowns}
        downloadAction={downloadAction}
        noDataIndication={
          isLoadingData
            ? t("Loading data...")
            : t("No data for the selected period.")
        }
      />
    </div>
  );
};

export default NetDues;
