import React from "react";

function IconListNumbers(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M8.25 4.49805C7.836 4.49805 7.5 4.16205 7.5 3.74805C7.5 3.33405 7.836 2.99805 8.25 2.99805H23.25C23.664 2.99805 24 3.33405 24 3.74805C24 4.16205 23.664 4.49805 23.25 4.49805H8.25Z"
      />
      <path
        fill={color}
        d="M8.25 13.498C7.836 13.498 7.5 13.162 7.5 12.748C7.5 12.334 7.836 11.998 8.25 11.998H23.25C23.664 11.998 24 12.334 24 12.748C24 13.162 23.664 13.498 23.25 13.498H8.25Z"
      />
      <path
        fill={color}
        d="M8.25 22.498C7.836 22.498 7.5 22.162 7.5 21.748C7.5 21.334 7.836 20.998 8.25 20.998H23.25C23.664 20.998 24 21.334 24 21.748C24 22.162 23.664 22.498 23.25 22.498H8.25Z"
      />
      <path
        fill={color}
        d="M2.25139 23.9981C1.24339 23.9981 0.351394 23.3191 0.0823945 22.3481C0.0293945 22.1551 0.0543945 21.9531 0.152394 21.7781C0.251394 21.6041 0.412394 21.4781 0.605394 21.4251C0.670394 21.4071 0.738394 21.3981 0.805394 21.3981C1.14139 21.3981 1.43839 21.6241 1.52839 21.9491C1.61739 22.2721 1.91539 22.4991 2.25139 22.4991C2.45139 22.4991 2.63939 22.4211 2.78139 22.2791C2.92339 22.1371 3.00139 21.9491 3.00139 21.7491C3.00139 21.5491 2.92339 21.3601 2.78139 21.2191C2.63939 21.0771 2.45139 21.0001 2.25139 21.0001C1.83739 21.0001 1.50039 20.6641 1.50039 20.2501C1.50039 19.8361 1.83639 19.5001 2.24939 19.5001C2.58839 19.5001 2.88639 19.2711 2.97339 18.9441C3.02539 18.7511 2.99839 18.5491 2.89739 18.3751C2.79639 18.2011 2.63539 18.0771 2.44239 18.0251C2.37739 18.0071 2.31139 17.9991 2.24639 17.9991C2.11639 17.9991 1.98739 18.0341 1.87339 18.1001C1.69939 18.2001 1.57539 18.3621 1.52439 18.5551C1.43739 18.8811 1.13939 19.1101 0.800394 19.1101C0.735394 19.1101 0.671394 19.1021 0.608394 19.0851C0.207394 18.9771 -0.0306055 18.5651 0.0763945 18.1661C0.231394 17.5861 0.604394 17.1001 1.12439 16.7991C1.46739 16.6011 1.85539 16.4971 2.24739 16.4971C2.44339 16.4971 2.64039 16.5231 2.83239 16.5741C4.03039 16.8951 4.74439 18.1311 4.42339 19.3291C4.33239 19.6691 4.16039 19.9841 3.92739 20.2451C4.29839 20.6581 4.50139 21.1861 4.50239 21.7461C4.50139 22.9871 3.49239 23.9971 2.25139 23.9981C2.25239 23.9981 2.25139 23.9981 2.25139 23.9981Z"
      />
      <path
        fill={color}
        d="M0.75 14.998C0.416 14.998 0.127 14.783 0.031 14.463C-0.063 14.148 0.063 13.802 0.338 13.621L2.759 12.03C2.915 11.801 3 11.525 3 11.248C3 10.834 2.664 10.498 2.25 10.498C1.836 10.498 1.5 10.834 1.5 11.248C1.5 11.662 1.164 11.998 0.75 11.998C0.336 11.998 0 11.662 0 11.248C0 10.007 1.009 8.99805 2.25 8.99805C3.491 8.99805 4.5 10.007 4.5 11.248C4.5 11.901 4.276 12.542 3.868 13.052C3.818 13.114 3.76 13.167 3.694 13.21L3.256 13.498H3.75C4.164 13.498 4.5 13.834 4.5 14.248C4.5 14.662 0.75 14.998 0.75 14.998Z"
      />
      <path
        fill={color}
        d="M0.75 7.49805C0.336 7.49805 0 7.16205 0 6.74805C0 6.33405 0.336 5.99805 0.75 5.99805H1.5V1.49805H0.75C0.336 1.49805 0 1.16205 0 0.748047C0 0.334047 0.336 -0.00195312 0.75 -0.00195312H1.5C2.327 -0.00195312 3 0.671047 3 1.49805V5.99805H3.75C4.164 5.99805 4.5 6.33405 4.5 6.74805C4.5 7.16205 4.164 7.49805 3.75 7.49805H0.75Z"
      />
    </svg>
  );
}

export default IconListNumbers;
