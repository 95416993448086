import { createSlice } from "@reduxjs/toolkit";
import { statusData } from "../../api/index.js";
import payeeAccountApi from "../../api/payeeAccount.api";

const {
  initialStatus,
  loadStatus,
  successStatus,
  failureStatus,
  copyStatus,
} = statusData;

export const payeeAccountSlice = createSlice({
  name: "payeeAccountStore",
  initialState: {
    payeeAccounts: [],
    payeeAccount: null,
    selectPayeeAccountsStatus: copyStatus(initialStatus),
    savePayeeAccountStatus: copyStatus(initialStatus),
    deletePayeeAccountStatus: copyStatus(initialStatus),
    openEditPayeeAccountStatus: copyStatus(initialStatus),
    editPayeeAccountAllocationsStatus: copyStatus(initialStatus),
    showPayeeAccountModal: false,
  },
  reducers: {
    loadPayeeAccountsStart: (state) => {
      state.selectPayeeAccountsStatus = copyStatus(loadStatus);
    },
    loadPayeeAccountsSuccess: (state, action) => {
      state.payeeAccounts = action.payload.data;
      state.selectPayeeAccountsStatus = copyStatus(successStatus);
    },
    loadPayeeAccountsFailure: (state) => {
      state.selectPayeeAccountsStatus = copyStatus(failureStatus);
    },
    editPayeeAccountStart: (state) => {
      state.savePayeeAccountStatus = copyStatus(loadStatus);
    },
    editPayeeAccountSuccess: (state) => {
      state.savePayeeAccountStatus = copyStatus(successStatus);
      state.payeeAccount = null;
      state.showPayeeAccountModal = false;
    },
    editPayeeAccountFailure: (state) => {
      state.savePayeeAccountStatus = copyStatus(failureStatus);
    },
    resetSavePayeeAccountStatus: (state) => {
      state.savePayeeAccountStatus = copyStatus(initialStatus);
    },
    editPayeeAccountAllocationsStart: (state) => {
      state.editPayeeAccountAllocationsStatus = copyStatus(loadStatus);
    },
    editPayeeAccountAllocationsSuccess: (state) => {
      state.editPayeeAccountAllocationsStatus = copyStatus(successStatus);
    },
    editPayeeAccountAllocationsFailure: (state) => {
      state.editPayeeAccountAllocationsStatus = copyStatus(failureStatus);
    },
    createPayeeAccountStart: (state) => {
      state.savePayeeAccountStatus = copyStatus(loadStatus);
    },
    createPayeeAccountSuccess: (state) => {
      state.savePayeeAccountStatus = copyStatus(successStatus);
      state.payeeAccount = null;
      state.showPayeeAccountModal = false;
    },
    createPayeeAccountFailure: (state) => {
      state.savePayeeAccountStatus = copyStatus(failureStatus);
    },
    deletePayeeAccountStart: (state) => {
      state.deletePayeeAccountStatus = copyStatus(loadStatus);
    },
    deletePayeeAccountSuccess: (state) => {
      state.deletePayeeAccountStatus = copyStatus(successStatus);
    },
    deletePayeeAccountFailure: (state) => {
      state.deletePayeeAccountStatus = copyStatus(failureStatus);
    },
    resetDeletePayeeAccountStatus: (state) => {
      state.deletePayeeAccountStatus = copyStatus(initialStatus);
    },
    openEditPayeeAccountStart: (state) => {
      state.openEditPayeeAccountStatus = copyStatus(loadStatus);
    },
    openEditPayeeAccountSuccess: (state) => {
      state.openEditPayeeAccountStatus = copyStatus(successStatus);
    },
    openEditPayeeAccountFailure: (state) => {
      state.openEditPayeeAccountStatus = copyStatus(failureStatus);
    },
    showPayeeAccountModal: (state, action) => {
      state.showPayeeAccountModal = true;
      state.payeeAccount = action.payload;
    },
    closePayeeAccountModal: (state) => {
      state.showPayeeAccountModal = false;
      state.payeeAccount = null;
    },
    reset: (state) => {
      state.payeeAccounts = [];
      state.payeeAccount = null;
      state.selectPayeeAccountsStatus = copyStatus(initialStatus);
      state.savePayeeAccountStatus = copyStatus(initialStatus);
      state.deletePayeeAccountStatus = copyStatus(initialStatus);
      state.openEditPayeeAccountStatus = copyStatus(initialStatus);
      state.editPayeeAccountAllocationsStatus = copyStatus(initialStatus);
      state.showPayeeAccountModal = false;
    },
  },
});

// the state is the Root state, so you need to reference the payeeAccountsStore here
// SELECT
export const selectPayeeAccounts = (state) =>
  state.payeeAccountStore.payeeAccounts;
export const selectPayeeAccount = (state) =>
  state.payeeAccountStore.payeeAccount;
export const selectSelectPayeeAccountsStatus = (state) =>
  state.payeeAccountStore.selectPayeeAccountsStatus;
export const selectSavePayeeAccountStatus = (state) =>
  state.payeeAccountStore.savePayeeAccountStatus;
export const selectEditPayeeAccountAllocationsStatus = (state) =>
  state.payeeAccountStore.editPayeeAccountAllocationsStatus;
export const selectDeletePayeeAccountStatus = (state) =>
  state.payeeAccountStore.deletePayeeAccountStatus;
export const selectShowPayeeAccountModal = (state) =>
  state.payeeAccountStore.showPayeeAccountModal;

// Don't expose to rest of the app.
const {
  loadPayeeAccountsStart,
  loadPayeeAccountsSuccess,
  loadPayeeAccountsFailure,
  editPayeeAccountStart,
  editPayeeAccountSuccess,
  editPayeeAccountFailure,
  editPayeeAccountAllocationsStart,
  editPayeeAccountAllocationsSuccess,
  editPayeeAccountAllocationsFailure,
  createPayeeAccountStart,
  createPayeeAccountSuccess,
  createPayeeAccountFailure,
  deletePayeeAccountStart,
  deletePayeeAccountSuccess,
  deletePayeeAccountFailure,
  openEditPayeeAccountStart,
  openEditPayeeAccountSuccess,
  openEditPayeeAccountFailure,
  showPayeeAccountModal,
} = payeeAccountSlice.actions;

export const loadPayeeAccounts = (params) => async (dispatch) => {
  dispatch(loadPayeeAccountsStart());
  try {
    let res = await payeeAccountApi.getPayeeAccounts(params);
    dispatch(loadPayeeAccountsSuccess(res));
  } catch (err) {
    console.error(err);
    dispatch(loadPayeeAccountsFailure());
  }
};

export const editPayeeAccount = (params, challengeHistoryId) => async (
  dispatch
) => {
  dispatch(editPayeeAccountStart());
  try {
    await payeeAccountApi.putPayeeAccount(params, challengeHistoryId);
    dispatch(payeeAccountSlice.actions.closePayeeAccountModal());
    dispatch(editPayeeAccountSuccess());
  } catch (err) {
    console.error(err);
    dispatch(editPayeeAccountFailure());
  }
};

export const editPayeeAccountAllocations = (
  params,
  clientTrustAccountId
) => async (dispatch) => {
  dispatch(editPayeeAccountAllocationsStart());
  try {
    await payeeAccountApi.putPayeeAccountAllocations(
      params,
      clientTrustAccountId
    );
    dispatch(editPayeeAccountAllocationsSuccess());
  } catch (err) {
    console.error(err);
    dispatch(editPayeeAccountAllocationsFailure());
  }
};

export const createPayeeAccount = (params, challengeHistoryId) => async (
  dispatch
) => {
  dispatch(createPayeeAccountStart());
  try {
    await payeeAccountApi.postPayeeAccount(params, challengeHistoryId);
    dispatch(payeeAccountSlice.actions.closePayeeAccountModal());
    dispatch(createPayeeAccountSuccess());
  } catch (err) {
    console.error(err);
    dispatch(createPayeeAccountFailure());
  }
};

export const deletePayeeAccount = (id) => async (dispatch) => {
  dispatch(deletePayeeAccountStart());
  try {
    await payeeAccountApi.deletePayeeAccount(id);
    dispatch(payeeAccountSlice.actions.closePayeeAccountModal());
    dispatch(deletePayeeAccountSuccess());
  } catch (err) {
    console.error(err);
    dispatch(deletePayeeAccountFailure());
  }
};

export const showEditPayeeAccountModal = (id) => async (dispatch) => {
  dispatch(openEditPayeeAccountStart());
  try {
    let res = await payeeAccountApi.getPayeeAccountById(id);
    dispatch(showPayeeAccountModal(res));
    dispatch(openEditPayeeAccountSuccess());
  } catch (err) {
    console.error(err);
    dispatch(openEditPayeeAccountFailure());
  }
};

export const showNewPayeeAccountModal = () => (dispatch) => {
  dispatch(
    showPayeeAccountModal({
      id: null,
      bankName: "",
      nickName: "",
      contactName: "",
      hasACHInstructions: false,
      hasWireInstructions: true,
      hasCheckInstructions: false,
      address: "",
      city: "",
      state: "",
      zip: "",
      ffcAccountName: null,
      ffcAccountNumber: null,
      abaNumber: null,
      attention: null,
      ismasked: false,
    })
  );
};

export const closePayeeAccountModal = () => (dispatch) => {
  dispatch(payeeAccountSlice.actions.closePayeeAccountModal());
};

export const resetSavePayeeAccountStatus = () => (dispatch) => {
  dispatch(payeeAccountSlice.actions.resetSavePayeeAccountStatus());
};

export const resetDeletePayeeAccountStatus = () => (dispatch) => {
  dispatch(payeeAccountSlice.actions.resetDeletePayeeAccountStatus());
};

export const resetPayeeAccounts = () => (dispatch) =>
  dispatch(payeeAccountSlice.actions.reset());

export default payeeAccountSlice.reducer;
