import api from ".";

const getUsers = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}users`;
  return api.get(fetchApiUrl, params, {});
};

const getUser = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}users/${id}`;
  return api.get(fetchApiUrl, null, null);
};

const putUser = async (data) => {
  const fetchApiUrl = `${api.getApiUrl()}users`;
  return api.put(fetchApiUrl, null, data);
};

const putUserPermissionGroup = async (data) => {
  const fetchApiUrl = `${api.getApiUrl()}users/updatepermissionlevel`;
  return api.put(fetchApiUrl, null, data);
};

const putUserClients = async (data) => {
  // TODO: Once this route has been created update this
  const fetchApiUrl = `${api.getApiUrl()}users/updateclients`;
  return api.put(fetchApiUrl, null, data);
};

const deleteUser = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}users/${id}`;
  return api.del(fetchApiUrl, null, null);
};

const getCompanies = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}users/companies`;
  return api.get(fetchApiUrl, params, {});
};

const resendInvite = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}users/resendInvitation`;
  return api.post(fetchApiUrl, null, id);
};

const getUser2FAStatus = async () => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Status`;
  return api.get(fetchApiUrl);
};

const enrollUser = async (params) => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Enrollments`;
  return api.post(fetchApiUrl, null, params);
};

const activateUser = async (params) => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Activate`;
  return api.post(fetchApiUrl, null, params);
};

const challengeUser = async (params) => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Challenge`;
  return api.post(fetchApiUrl, null, params);
};

const verifyUser = async (params) => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Challenge/Verify`;
  return api.post(fetchApiUrl, null, params);
};

const disableUser = async (params) => {
  let fetchApiUrl = `${api.getApiUrl()}users/2FA/Enrollments`;
  return api.del(fetchApiUrl, null, params);
};

export default {
  getUsers,
  getUser,
  putUser,
  putUserPermissionGroup,
  putUserClients,
  deleteUser,
  getCompanies,
  resendInvite,
  getUser2FAStatus,
  enrollUser,
  activateUser,
  challengeUser,
  verifyUser,
  disableUser,
};
