import React from "react";

function IconBank(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path fill={color} 
            d="M0.75 24C0.551088 24 0.360322 23.921 0.21967 23.7803C0.0790177 23.6397 0 23.4489 0 23.25C0 23.0511 0.0790177 22.8603 0.21967 22.7197C0.360322 22.579 0.551088 22.5 0.75 22.5H21.75C21.9489 22.5 22.1397 22.579 22.2803 22.7197C22.421 22.8603 22.5 23.0511 22.5 23.25C22.5 23.4489 22.421 23.6397 22.2803 23.7803C22.1397 23.921 21.9489 24 21.75 24H0.75Z"/>
      <path fill={color} 
            d="M0.75 21C0.551088 21 0.360322 20.921 0.21967 20.7803C0.0790177 20.6397 0 20.4489 0 20.25C0 20.0511 0.0790177 19.8603 0.21967 19.7197C0.360322 19.579 0.551088 19.5 0.75 19.5H21.75C21.9489 19.5 22.1397 19.579 22.2803 19.7197C22.421 19.8603 22.5 20.0511 22.5 20.25C22.5 20.4489 22.421 20.6397 22.2803 20.7803C22.1397 20.921 21.9489 21 21.75 21H0.75Z"/>
      <path fill={color} 
            d="M2.25 18C2.05109 18 1.86032 17.921 1.71967 17.7803C1.57902 17.6397 1.5 17.4489 1.5 17.25V9.75C1.5 9.55109 1.57902 9.36032 1.71967 9.21967C1.86032 9.07902 2.05109 9 2.25 9C2.44891 9 2.63968 9.07902 2.78033 9.21967C2.92098 9.36032 3 9.55109 3 9.75V17.25C2.99974 17.4488 2.92063 17.6394 2.78004 17.78C2.63944 17.9206 2.44883 17.9997 2.25 18Z"/>
      <path fill={color} 
            d="M5.25 18C5.05109 18 4.86032 17.921 4.71967 17.7803C4.57902 17.6397 4.5 17.4489 4.5 17.25V9.75C4.5 9.55109 4.57902 9.36032 4.71967 9.21967C4.86032 9.07902 5.05109 9 5.25 9C5.44891 9 5.63968 9.07902 5.78033 9.21967C5.92098 9.36032 6 9.55109 6 9.75V17.25C5.99974 17.4488 5.92063 17.6394 5.78004 17.78C5.63944 17.9206 5.44883 17.9997 5.25 18Z"/>
      <path fill={color} 
            d="M9.75 18C9.55109 18 9.36032 17.921 9.21967 17.7803C9.07902 17.6397 9 17.4489 9 17.25V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75V17.25C10.4997 17.4488 10.4206 17.6394 10.28 17.78C10.1394 17.9206 9.94883 17.9997 9.75 18Z"/>
      <path fill={color} 
            d="M12.75 18C12.5511 18 12.3603 17.921 12.2197 17.7803C12.079 17.6397 12 17.4489 12 17.25V9.75C12 9.55109 12.079 9.36032 12.2197 9.21967C12.3603 9.07902 12.5511 9 12.75 9C12.9489 9 13.1397 9.07902 13.2803 9.21967C13.421 9.36032 13.5 9.55109 13.5 9.75V17.25C13.4997 17.4488 13.4206 17.6394 13.28 17.78C13.1394 17.9206 12.9488 17.9997 12.75 18Z"/>
      <path fill={color} 
            d="M17.25 18C17.0511 18 16.8603 17.921 16.7197 17.7803C16.579 17.6397 16.5 17.4489 16.5 17.25V9.75C16.5 9.55109 16.579 9.36032 16.7197 9.21967C16.8603 9.07902 17.0511 9 17.25 9C17.4489 9 17.6397 9.07902 17.7803 9.21967C17.921 9.36032 18 9.55109 18 9.75V17.25C17.9997 17.4488 17.9206 17.6394 17.78 17.78C17.6394 17.9206 17.4488 17.9997 17.25 18Z"/>
      <path fill={color} 
            d="M20.25 18C20.0511 18 19.8603 17.921 19.7197 17.7803C19.579 17.6397 19.5 17.4489 19.5 17.25V9.75C19.5 9.55109 19.579 9.36032 19.7197 9.21967C19.8603 9.07902 20.0511 9 20.25 9C20.4489 9 20.6397 9.07902 20.7803 9.21967C20.921 9.36032 21 9.55109 21 9.75V17.25C20.9997 17.4488 20.9206 17.6394 20.78 17.78C20.6394 17.9206 20.4488 17.9997 20.25 18Z"/>
      <path fill={color} 
            d="M0.750066 7.50004C0.586019 7.49973 0.426595 7.44565 0.296224 7.34607C0.165854 7.2465 0.0717261 7.10692 0.0282646 6.94874C-0.0151969 6.79055 -0.00559504 6.62248 0.0555989 6.47027C0.116793 6.31806 0.226203 6.19012 0.367066 6.10604L10.0561 0.344037C10.4127 0.117022 10.8273 -0.00240944 11.2501 3.68403e-05C11.6825 -0.000136403 12.1059 0.123765 12.4701 0.357037L22.1371 6.10504C22.2787 6.18908 22.3888 6.31741 22.4502 6.47023C22.5117 6.62304 22.5211 6.79183 22.4771 6.95054C22.433 7.10925 22.338 7.24906 22.2066 7.34837C22.0752 7.44768 21.9148 7.50098 21.7501 7.50004H0.750066ZM19.0211 6.00004L11.6771 1.63304C11.5505 1.5486 11.4022 1.50243 11.2501 1.50004C11.1082 1.50054 10.9695 1.54148 10.8501 1.61804L3.47807 6.00004H19.0211Z"/>
    </svg>
  );
}

export default IconBank;
