import React from "react";

function IconParaghraphLeftAlign(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M0.75 3.74805C0.336 3.74805 0 3.41205 0 2.99805C0 2.58405 0.336 2.24805 0.75 2.24805H21.75C22.164 2.24805 22.5 2.58405 22.5 2.99805C22.5 3.41205 22.164 3.74805 21.75 3.74805H0.75Z"
      />
      <path
        fill={color}
        d="M0.75 8.24805C0.336 8.24805 0 7.91205 0 7.49805C0 7.08405 0.336 6.74805 0.75 6.74805H18.75C19.164 6.74805 19.5 7.08405 19.5 7.49805C19.5 7.91205 19.164 8.24805 18.75 8.24805H0.75Z"
      />
      <path
        fill={color}
        d="M0.75 12.748C0.336 12.748 0 12.412 0 11.998C0 11.584 0.336 11.248 0.75 11.248H23.25C23.664 11.248 24 11.584 24 11.998C24 12.412 23.664 12.748 23.25 12.748H0.75Z"
      />
      <path
        fill={color}
        d="M0.75 17.248C0.336 17.248 0 16.912 0 16.498C0 16.084 0.336 15.748 0.75 15.748H18.75C19.164 15.748 19.5 16.084 19.5 16.498C19.5 16.912 19.164 17.248 18.75 17.248H0.75Z"
      />
      <path
        fill={color}
        d="M0.75 21.748C0.336 21.748 0 21.412 0 20.998C0 20.584 0.336 20.248 0.75 20.248H21.75C22.164 20.248 22.5 20.584 22.5 20.998C22.5 21.412 22.164 21.748 21.75 21.748H0.75Z"
      />
    </svg>
  );
}

export default IconParaghraphLeftAlign;
