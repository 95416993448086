import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  FormGroup,
  Input,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../scss/useradmin/_invite_user.scss";
import AlertBox from "../general/AlertBox";
import EditModal from "../general/AddEditModal";
import {
  IconUser,
  IconArrowLeft,
  IconBuilding,
  IconEmail,
  IconPencil,
  IconToolBox,
  IconTrash,
} from "../../assets/icons";
import SearchUsers from "./SearchUsers";
import classnames from "classnames";
import {
  selectUsers,
  addUser,
  removeUser,
  updateUser,
  checkEmail,
  selectUserType,
  setUserType,
} from "../../features/inviteUsers/inviteUsersSlice";
import { UserTypes, useAuthContext } from "../../Auth";
import { selectModuleConfig } from "../../features/moduleConfig/moduleConfigSlice";


const baseUser = {
  firstName: "",
  lastName: "",
  companyName: "",
  jobTitle: "",
  email: "",
};

const AddUsers = (props) => {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const t = useTranslation().t;
  const { isUserInAnyGroups } = useAuthContext();
  const moduleConfig = useSelector(selectModuleConfig);

  const [userCreateData, setUserCreateData] = useState({
    ...baseUser,
  });
  const [userEditData, setUserEditData] = useState({
    ...baseUser,
    index: -1,
  });
  const userType = useSelector(selectUserType);
  const [
    showEmailValidationAlertBox,
    setShowEmailValidationAlertBox,
  ] = useState(false);
  const [showNetworkFailureAlertBox, setShowNetworkFailureAlertBox] = useState(
    false
  );
  const [showEditUserAlertBox, setShowEditUserAlertBox] = useState(false);

  const { stepIndicator } = props;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      companyName,
      jobTitle,
    } = userCreateData;

    const newUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      companyName: companyName,
      jobTitle: jobTitle,
      login: email,
    };

    const emailValidationResult = await dispatch(checkEmail(email, users, -1));
    if (!emailValidationResult) {
      return;
    }

    if (emailValidationResult.validation) {
      setShowEmailValidationAlertBox(true);
      return;
    }

    setUserCreateData({ ...baseUser });
    dispatch(addUser({ ...newUser, immutable: false }));
  };

  // Edit User Functions
  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const emailValidationResult = await dispatch(
      checkEmail(userEditData.email, users, userEditData.index)
    );
    if (!emailValidationResult) {
      return;
    }

    if (emailValidationResult.validation) {
      setShowEmailValidationAlertBox(true);
      return;
    }

    dispatch(updateUser(userEditData.index, userEditData));
    setUserEditData({
      ...baseUser,
      index: -1,
    });
    setShowEditUserAlertBox(false);
  };

  const onChangeNewUser = (key, value) => {
    setUserCreateData({
      ...userCreateData,
      [key]: value,
    });
  };

  const onChangeEditUser = (key, value) => {
    setUserEditData({
      ...userEditData,
      [key]: value,
    });
  };

  const onStartUserEdit = (user, index) => {
    setShowEditUserAlertBox(true);
    setUserEditData({
      index,
      ...user,
    });
  };

  const selectTab = (selectedUserType) => {
    dispatch(setUserType(selectedUserType));
  };

  const closeAlertBox = () => {
    setShowEditUserAlertBox(false);
    setUserEditData({
      index: -1,
      ...baseUser,
    });
  };

  const closeNetworkFailureAlertBox = () =>
    setShowNetworkFailureAlertBox(false);

  const closeEmailAlertBox = () => setShowEmailValidationAlertBox(false);

  return (
    <div className="addUser">
      <div className="MyProfile-header">
        <div className="card addUserCard">
          <div className="card-header d-flex justify-content-between">
            <span>{t("Invite Users")}</span>
            {stepIndicator}
          </div>
          <div className="card-body">
            <div
              className="d-flex justify-content-between"
              style={{ flexWrap: "wrap" }}
            >
              <div
                className="card card-one mr-4"
                style={{
                  flex: 1,
                  minWidth: 300,
                  borderRight: "0.5px solid #dee2e6",
                  borderRadius: "0px",
                  paddingRight: "40px",
                }}
              >
                <div
                  className="card-header"
                  style={{ padding: 0, paddingBottom: 30 }}
                >
                  <span>{t("User Information")}</span>
                </div>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: userType === UserTypes.Internal,
                        })}
                        disabled={
                          !isUserInAnyGroups([
                            moduleConfig.internalAdmin.oktaName,
                          ]) || !!users.length
                        }
                        onClick={() => {
                          if (!users.length) selectTab(UserTypes.Internal);
                        }}
                      >
                        Assurant
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: userType === UserTypes.External,
                        })}
                        disabled={!!users.length}
                        onClick={() => {
                          if (!users.length) selectTab(UserTypes.External);
                        }}
                      >
                        Client
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <AlertBox
                    title={t("Email Already Exists")}
                    text={t(
                      "Please select another email for the user you wish to add."
                    )}
                    onClose={closeEmailAlertBox}
                    isOpen={showEmailValidationAlertBox}
                    isSuccess={false}
                    alertIcon="IconEmail"
                  />
                  <AlertBox
                    title={t("Network Failure")}
                    text={t("A network failure occurred, please try again.")}
                    onClose={closeNetworkFailureAlertBox}
                    isOpen={showNetworkFailureAlertBox}
                    isSuccess={false}
                  />
                </div>
                <div className="card-body d-flex align-items-start py-5">
                  <TabContent activeTab={userType} style={{ width: "100%" }}>
                    <TabPane tabId={UserTypes.Internal}>
                      <SearchUsers />
                    </TabPane>
                    <TabPane tabId={UserTypes.External}>
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <div className="form-icon">
                            <i>
                              <IconUser />
                            </i>
                          </div>
                          <Input
                            type="text"
                            name="firstName"
                            placeholder={t("First Name*")}
                            area-label={t("First Name")}
                            value={userCreateData.firstName}
                            onChange={(event) =>
                              onChangeNewUser("firstName", event.target.value)
                            }
                            required
                          />
                          <label className="floating-label" htmlFor="firstName">
                            {t("First Name")}
                          </label>
                        </FormGroup>

                        <FormGroup>
                          <div className="form-icon">
                            <i>
                              <IconUser />
                            </i>
                          </div>
                          <Input
                            type="text"
                            name="lastName"
                            value={userCreateData.lastName}
                            area-label={t("Last Name")}
                            onChange={(event) =>
                              onChangeNewUser("lastName", event.target.value)
                            }
                            placeholder={t("Last Name*")}
                            required
                          />
                          <label className="floating-label" htmlFor="lastName">
                            {t("Last Name")}
                          </label>
                        </FormGroup>

                        <FormGroup>
                          <div className="form-icon">
                            <i>
                              <IconBuilding />
                            </i>
                          </div>
                          <Input
                            type="text"
                            name="companyName"
                            value={userCreateData.companyName}
                            area-label={t("Company Name")}
                            onChange={(event) =>
                              onChangeNewUser("companyName", event.target.value)
                            }
                            placeholder={t("Company Name")}
                          />
                          <label
                            className="floating-label"
                            htmlFor="companyName"
                          >
                            {t("Company Name")}
                          </label>
                        </FormGroup>

                        <FormGroup>
                          <div className="form-icon">
                            <i>
                              <IconToolBox />
                            </i>
                          </div>
                          <Input
                            type="text"
                            name="jobTitle"
                            value={userCreateData.jobTitle}
                            onChange={(event) =>
                              onChangeNewUser("jobTitle", event.target.value)
                            }
                            area-label={t("Job Title")}
                            placeholder={t("Job Title")}
                          />
                          <label className="floating-label" htmlFor="jobTitle">
                            {t("Job Title")}
                          </label>
                        </FormGroup>

                        <FormGroup>
                          <div className="form-icon">
                            <i>
                              <IconEmail />
                            </i>
                          </div>
                          <Input
                            type="email"
                            name="email"
                            value={userCreateData.email}
                            onChange={(event) =>
                              onChangeNewUser("email", event.target.value)
                            }
                            area-label={t("Email")}
                            placeholder={t("Email*")}
                            required
                          />
                          <label className="floating-label" htmlFor="email">
                            {t("Email")}
                          </label>
                        </FormGroup>

                        <Button type="submit" color="primary" outline>
                          Add
                        </Button>
                      </Form>
                    </TabPane>
                  </TabContent>
                </div>
              </div>

              <div
                className="card card-two"
                style={{ flex: 3, minWidth: 400, minHeight: 650, maxHeight: 650, paddingTop: 40 }}
              >
                <div className="scrollable-list">
                  <table className="table table-bordered fixed_header">
                    <thead>
                      <tr>
                        <th scope="col">{t("Name")}</th>
                        <th scope="col">{t("Email")}</th>
                        <th scope="col">{t("Company Name")}</th>
                        <th scope="col">{t("Job Title")}</th>
                        <th scope="col">{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                {user.firstName} {user.lastName}
                              </td>
                              <td>{user.email}</td>
                              <td>{user.companyName}</td>
                              <td>{user.jobTitle}</td>
                              <td>
                                {!user.immutable && (
                                  <button
                                    className="btn-link btn-sm trash-btn mr-2"
                                    onClick={() => onStartUserEdit(user, index)}
                                  >
                                    <i>
                                      <IconPencil />
                                    </i>
                                  </button>
                                )}
                                <button
                                  className="btn-link btn-sm trash-btn"
                                  onClick={() => dispatch(removeUser(user))}
                                >
                                  <i>
                                    <IconTrash />
                                  </i>
                                </button>
                              </td>
                            </tr>
                            <EditModal
                              isOpen={showEditUserAlertBox}
                              buttonText="Save"
                              isBusy={false}
                              onConfirm={(e) =>
                                handleEditSubmit(e, {
                                  ...userEditData,
                                  login: userEditData.email,
                                })}
                              onCancel={closeAlertBox}
                              isValid={true}
                              title={t(
                                `Edit ${user.firstName} ${user.lastName}`
                              )}
                            >
                              <Form>
                                <FormGroup>
                                  <div className="form-icon">
                                    <i>
                                      <IconUser />
                                    </i>
                                  </div>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    value={userEditData.firstName}
                                    area-label={t("First Name")}
                                    onChange={(event) =>
                                      onChangeEditUser(
                                        "firstName",
                                        event.target.value
                                      )
                                    }
                                    placeholder={t("First Name*")}
                                    required
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <div className="form-icon">
                                    <i>
                                      <IconUser />
                                    </i>
                                  </div>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    value={userEditData.lastName}
                                    area-label={t("Last Name")}
                                    onChange={(event) =>
                                      onChangeEditUser(
                                        "lastName",
                                        event.target.value
                                      )
                                    }
                                    placeholder={t("Last Name*")}
                                    required
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <div className="form-icon">
                                    <i>
                                      <IconBuilding />
                                    </i>
                                  </div>
                                  <Input
                                    type="text"
                                    name="companyName"
                                    value={userEditData.companyName}
                                    area-label={t("Company Name")}
                                    onChange={(event) =>
                                      onChangeEditUser(
                                        "companyName",
                                        event.target.value
                                      )
                                    }
                                    placeholder={t("Company Name")}
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <div className="form-icon">
                                    <i>
                                      <IconToolBox />
                                    </i>
                                  </div>
                                  <Input
                                    type="text"
                                    name="jobTitle"
                                    value={userEditData.jobTitle}
                                    onChange={(event) =>
                                      onChangeEditUser(
                                        "jobTitle",
                                        event.target.value
                                      )
                                    }
                                    area-label={t("Job Title")}
                                    placeholder={t("Job Title")}
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <div className="form-icon">
                                    <i>
                                      <IconEmail />
                                    </i>
                                  </div>
                                  <Input
                                    type="text"
                                    name="email"
                                    value={userEditData.email}
                                    area-label={t("Email")}
                                    onChange={(event) =>
                                      onChangeEditUser(
                                        "email",
                                        event.target.value
                                      )
                                    }
                                    placeholder={t("Email*")}
                                    required
                                  />
                                </FormGroup>
                              </Form>
                            </EditModal>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Link to="/admin/users">
              <i className="mr-2">
                <IconArrowLeft />
              </i>
              {t("Back")}
            </Link>
            <Button
              color="primary"
              tag={Link}
              to="/users/add/permissions"
              disabled={users.length === 0}
            >
              {t("Continue")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
