import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import FormFeedback from "reactstrap/lib/FormFeedback";
import * as yup from "yup";
import {
  IconAdd,
  IconBank,
  IconBuilding,
  IconUser,
  IconEmail,
} from "../../assets/icons";

import {
  addBankContact,
  closeBankContactModal,
  editBankContact,
  selectBankContact,
  selectIsSaving,
  selectShowBankContactModal,
  selectSaveError,
  selectBankNames,
  selectLastBankContactsSearchParams,
} from "../../features/bankContacts/bankContactsSlice";
import i18n from "../../i18n";
import EditModal from "../general/AddEditModal";

const bankContactSchema = yup.object().shape({
  bankName: yup.string().required(i18n.t("Bank Name is required")),
  address: yup.string().required(i18n.t("Address is required")),
  city: yup.string().required(i18n.t("City is required")),
  state: yup
    .string()
    .required(i18n.t("State is required"))
    .max(2, i18n.t("Enter a 2-letter State abbreviation")),
  zip: yup
    .string()
    .required(i18n.t("Zip is required"))
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
      message: i18n.t("Enter a valid Zip Code"),
    }),
  contactName: yup.string().required(i18n.t("Contact Name is required")),
  salutation: yup.string().required(i18n.t("Salutation is required")),
  primaryEmail: yup
    .string()
    .required(i18n.t("Primary Email is required"))
    .email(i18n.t("Enter a valid email address")),
  ccEmails: yup
    .array()
    .of(yup.string().email(i18n.t("Enter a valid email address"))),
});

const AddEditBankContact = () => {
  const t = useTranslation().t;

  const [validationResult, setValidationResult] = useState({});
  const [touched, setTouched] = useState({});

  const [id, setId] = useState(null);
  const [bankNameId, setBankNameId] = useState(-1);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [contactName, setContactName] = useState("");
  const [salutation, setSalutation] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [ccEmails, setCcEmails] = useState([]);

  const dispatch = useDispatch();

  const isSaving = useSelector(selectIsSaving);
  const isOpen = useSelector(selectShowBankContactModal);
  const saveError = useSelector(selectSaveError);
  const bankNames = useSelector(selectBankNames);

  const currentBankContact = useSelector(selectBankContact);

  const lastSearchParams = useSelector(selectLastBankContactsSearchParams);

  useEffect(() => {
    setId(currentBankContact?.id);
    setBankNameId(currentBankContact?.bankNameId);
    setAddress(currentBankContact?.address);
    setCity(currentBankContact?.city);
    setState(currentBankContact?.state);
    setZip(currentBankContact?.zip);
    setContactName(currentBankContact?.contactName);
    setSalutation(currentBankContact?.salutation);
    setPrimaryEmail(currentBankContact?.primaryEmail);
    setCcEmails(currentBankContact?.ccEmails ?? []);
  }, [currentBankContact]);

  useEffect(() => {
    const validate = async () => {
      try {
        await bankContactSchema.validate(
          {
            id,
            bankName: bankNameId,
            address,
            city,
            state,
            zip,
            contactName,
            salutation,
            primaryEmail,
            ccEmails,
          },
          {
            abortEarly: false,
          }
        );
        setValidationResult({});
      } catch (err) {
        // TODO: Write a utility function to map these validation results to something more useful
        setValidationResult(err);
      }
    };
    validate();
  }, [
    id,
    bankNameId,
    address,
    city,
    state,
    zip,
    contactName,
    salutation,
    primaryEmail,
    ccEmails,
  ]);

  useEffect(() => {
    setTouched({});
  }, [isOpen]);

  const handleCcEmailChange = (updatedIndex, newEmail) => {
    setCcEmails(
      ccEmails.map((email, i) => (i === updatedIndex ? newEmail : email))
    );
  };

  const handleAddCcEmailClick = () => {
    setCcEmails([...ccEmails, ""]);
  };

  const handleRemoveCcEmailClick = (index) => {
    setCcEmails(ccEmails.filter((_, i) => i !== index));
  };

  const handleBlur = (path) => {
    const updatedTouched = { ...touched };
    updatedTouched[path] = true;
    setTouched(updatedTouched);
  };

  const isTouched = (path) => !!touched[path];
  const isValid = () => !validationResult?.errors?.length;
  const showPropertyValidation = (path) =>
    isTouched(path) && !!propertyErrorMessage(path);
  const propertyErrorMessage = (path) =>
    validationResult?.inner?.find((e) => e.path === path)?.message;

  const handleSave = async () => {
    const bankContact = {
      id,
      bankNameId,
      address,
      city,
      state,
      zip,
      contactName,
      salutation,
      primaryEmail,
      ccEmails,
    };
    if (id) {
      await dispatch(editBankContact(bankContact, lastSearchParams));
    } else {
      await dispatch(addBankContact(bankContact, lastSearchParams));
    }
  };

  const handleCancel = () => {
    dispatch(closeBankContactModal());
  };

  return (
    <EditModal
      isOpen={isOpen}
      buttonText="OK"
      isBusy={isSaving}
      onConfirm={handleSave}
      onCancel={handleCancel}
      isValid={isValid()}
      title={id ? t("View Bank Contact") : t("Add Bank Contact")}
    >
      <Form autoComplete="off">
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconBank />
            </i>
          </div>
          <Input
            style={{ paddingLeft: "20px" }}
            type="select"
            onBlur={() => handleBlur("bankName")}
            invalid={showPropertyValidation("bankName")}
            name="bankName"
            value={bankNameId}
            onChange={(e) => setBankNameId(parseInt(e.target.value))}
            disabled
          >
            <option key="-1" value="-1">
              {t("Bank Name")}
            </option>
            {bankNames.map((b) => {
              return (
                <option key={b.id} value={b.id}>
                  {b.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{propertyErrorMessage("bankName")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("bankName") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("address")}
            onBlur={() => handleBlur("address")}
            name="address"
            placeholder={t("Address")}
            area-label={t("Address")}
            required
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="address">
            {t("Address")}
          </label>
          <FormFeedback>{propertyErrorMessage("address")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("address") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("city")}
            onBlur={() => handleBlur("city")}
            name="city"
            placeholder={t("City")}
            area-label={t("City")}
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="city">
            {t("City")}
          </label>
          <FormFeedback>{propertyErrorMessage("city")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("city") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("state")}
            onBlur={() => handleBlur("state")}
            name="state"
            placeholder={t("State")}
            area-label={t("State")}
            required
            value={state}
            onChange={(e) => setState(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="state">
            {t("State")}
          </label>
          <FormFeedback>{propertyErrorMessage("state")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("state") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconBuilding />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("zip")}
            onBlur={() => handleBlur("zip")}
            name="zip"
            placeholder={t("Zip")}
            area-label={t("Zip")}
            required
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="zip">
            {t("Zip")}
          </label>
          <FormFeedback>{propertyErrorMessage("zip")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("zip") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconUser />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("contactName")}
            onBlur={() => handleBlur("contactName")}
            name="contactName"
            placeholder={t("Name")}
            area-label={t("Name")}
            required
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="contactName">
            {t("Name")}
          </label>
          <FormFeedback>{propertyErrorMessage("contactName")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("contactName") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconUser />
            </i>
          </div>
          <Input
            type="text"
            invalid={showPropertyValidation("salutation")}
            onBlur={() => handleBlur("salutation")}
            name="salutation"
            placeholder={t("Salutation")}
            area-label={t("Salutation")}
            required
            value={salutation}
            onChange={(e) => setSalutation(e.target.value)}
            readOnly
          />
          <label className="floating-label" htmlFor="salutation">
            {t("Salutation")}
          </label>
          <FormFeedback>{propertyErrorMessage("salutation")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("salutation") ? "none" : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>
        <FormGroup style={{ marginBottom: "1.5em" }}>
          <div className="form-icon">
            <i>
              <IconEmail />
            </i>
          </div>
          <Input
            type="email"
            invalid={showPropertyValidation("primaryEmail")}
            onBlur={() => handleBlur("primaryEmail")}
            autocomplete="off"
            name="primaryEmail"
            placeholder={t("Email")}
            area-label={t("Email")}
            required
            value={primaryEmail}
            onChange={(e) => setPrimaryEmail(e.target.value)}
            disabled
          />
          <label className="floating-label" htmlFor="email">
            {t("Email")}
          </label>
          <FormFeedback>{propertyErrorMessage("primaryEmail")}</FormFeedback>
          <div
            style={{
              display: showPropertyValidation("primaryEmail")
                ? "none"
                : "block",
              height: "calc(16px + .25rem)",
            }}
          ></div>
        </FormGroup>

        {ccEmails.map((ccEmail, index) => (
          <FormGroup key={index}>
            <div className="form-icon">
              <i>
                <IconEmail />
              </i>
            </div>
            <InputGroup>
              <Input
                type="email"
                invalid={showPropertyValidation(`ccEmails[${index}]`)}
                onBlur={() => handleBlur(`ccEmails[${index}]`)}
                autocomplete="off"
                name="ccEmail"
                placeholder={t("CC Email") + ` ${index + 1}`}
                area-label={t("CC Email") + ` ${index + 1}`}
                required
                value={ccEmail}
                onChange={(e) => handleCcEmailChange(index, e.target.value)}
                readOnly
              />
              <label className="floating-label ccemail" htmlFor="cCemail">
                {t("CC Email")}
              </label>
              <InputGroupAddon addonType="append">
                <Button
                  title={t("CC Email")}
                  close
                  onClick={() => handleRemoveCcEmailClick(index)}
                  color="secondary"
                  disabled
                ></Button>
              </InputGroupAddon>
              <FormFeedback>
                {propertyErrorMessage(`ccEmails[${index}]`)}
              </FormFeedback>
              <div
                style={{
                  display: showPropertyValidation(`ccEmails[${index}]`)
                    ? "none"
                    : "block",
                  height: "calc(16px + .25rem)",
                }}
              ></div>
            </InputGroup>
          </FormGroup>
        ))}

        <div className="row m-0">
          {/*<div className="text-link mb-5" onClick={handleAddCcEmailClick}>
              <i>
                <IconAdd height="12" />
              </i>{" "}
              <span className="align-add-link">{t("Email CC")}</span>
              </div>*/}
        </div>

        {saveError && <div>{saveError}</div>}
      </Form>
    </EditModal>
  );
};

export default AddEditBankContact;
