import React from "react";

function IconEye(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M11.8419 15C7.50892 15 3.20592 11.96 0.62292 9.116C0.22231 8.67311 0.000488281 8.0972 0.000488281 7.5C0.000488281 6.9028 0.22231 6.32689 0.62292 5.884C3.20492 3.038 7.51292 0 11.8549 0H12.1549C16.4829 0 20.7889 3.038 23.3739 5.881C23.7748 6.32395 23.9968 6.90007 23.9968 7.4975C23.9968 8.09493 23.7748 8.67105 23.3739 9.114C20.7879 11.96 16.4739 15 12.1289 15H11.8359H11.8419ZM11.8529 1.5C7.99992 1.5 4.09992 4.283 1.73292 6.889C1.58184 7.05625 1.4982 7.27361 1.4982 7.499C1.4982 7.72438 1.58184 7.94175 1.73292 8.109C4.09992 10.714 7.99992 13.5 11.8549 13.5H12.1219C15.9889 13.5 19.8979 10.715 22.2669 8.108C22.418 7.94075 22.5016 7.72338 22.5016 7.498C22.5016 7.27262 22.418 7.05525 22.2669 6.888C19.8999 4.283 15.9929 1.5 12.1419 1.5H11.9859H11.8529Z"
      ></path>
      <path
        fill={color}
        d="M11.9999 12C10.9588 11.9998 9.95001 11.6385 9.14537 10.9779C8.34074 10.3172 7.79008 9.39795 7.58723 8.37679C7.38437 7.35563 7.54188 6.29572 8.0329 5.37767C8.52392 4.45961 9.31808 3.74022 10.2801 3.34207C11.242 2.94392 12.3123 2.89164 13.3085 3.19414C14.3047 3.49665 15.1652 4.13522 15.7433 5.00105C16.3215 5.86688 16.5815 6.90639 16.4792 7.94246C16.3768 8.97853 15.9184 9.94706 15.1819 10.683C14.765 11.102 14.269 11.4341 13.7228 11.6602C13.1766 11.8862 12.5911 12.0017 11.9999 12ZM11.9999 4.5C11.3058 4.50008 10.6331 4.74088 10.0967 5.18136C9.56015 5.62183 9.19301 6.23472 9.05778 6.91558C8.92255 7.59643 9.02762 8.30311 9.35507 8.91518C9.68252 9.52724 10.2121 10.0068 10.8535 10.2722C11.495 10.5375 12.2086 10.5722 12.8727 10.3704C13.5369 10.1685 14.1105 9.74256 14.4958 9.16515C14.881 8.58775 15.0542 7.8946 14.9857 7.20384C14.9171 6.51307 14.6112 5.86745 14.1199 5.377C13.5574 4.81533 12.7949 4.49991 11.9999 4.5Z"
      ></path>
    </svg>
  );
}

export default IconEye;
