import React from "react";

function IconBankTransfer(props) {
  let arrowsColor = props.arrowsColor ? props.arrowsColor : "";
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >

   
        <path fill={color} 
            d="M7.52 16.11H.18a.18.18 0 110-.36h7.34a.18.18 0 110 .36zM7.52 15.06H.18a.18.18 0 010-.36h7.34a.18.18 0 010 .36zM.71 14a.2.2 0 01-.19-.19v-2.6A.19.19 0 01.71 11a.18.18 0 01.18.18v2.62a.19.19 0 01-.18.2zM1.75 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.62a.19.19 0 01-.18.17zM3.32 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.18-.21.18.18 0 01.19.18v2.62a.19.19 0 01-.19.2zM4.37 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.62a.19.19 0 01-.18.17zM5.94 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.18-.21.19.19 0 01.19.18v2.62a.2.2 0 01-.19.2zM7 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.62A.19.19 0 017 14zM7.52 10.35H.18a.18.18 0 01-.18-.14.17.17 0 01.09-.21l3.38-2a.7.7 0 01.76 0l3.38 2a.18.18 0 01-.09.34zM.84 10h6L4 8.31a.33.33 0 00-.38 0z"/>
        <path fill={color} 
            d="M23.82 16.11h-7.34a.18.18 0 110-.36h7.34a.18.18 0 010 .36zM23.82 15.06h-7.34a.18.18 0 010-.36h7.34a.18.18 0 010 .36zM17 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.62A.19.19 0 0117 14zM18.06 14a.2.2 0 01-.19-.19v-2.6a.19.19 0 01.19-.18.18.18 0 01.18.18v2.62a.19.19 0 01-.18.17zM19.63 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.36 0v2.62a.19.19 0 01-.18.17zM20.67 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.18-.18.18.18 0 01.19.18v2.62a.19.19 0 01-.19.17zM22.25 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 11.36 0v2.62a.19.19 0 01-.18.17zM23.29 14a.19.19 0 01-.18-.19v-2.6a.18.18 0 01.18-.18.19.19 0 01.19.18v2.62a.2.2 0 01-.19.17zM23.82 10.35h-7.34a.18.18 0 01-.09-.34l3.38-2a.7.7 0 01.76 0l3.38 2a.17.17 0 01.08.2.18.18 0 01-.17.14zM17.14 10h6l-2.8-1.69a.32.32 0 00-.37 0z"/>
        <path fill={arrowsColor} 
            d="M14.72 12.18H9.28a.18.18 0 110-.36h5.44a.18.18 0 110 .36z"/>
        <path fill={color} 
            d="M13.82 13.09a.19.19 0 01-.13-.05.19.19 0 010-.26l.78-.78-.78-.78a.19.19 0 010-.26.18.18 0 01.25 0l.91.91a.19.19 0 010 .26l-.91.91a.18.18 0 01-.12.05z"/>
    </svg>
  );
}

export default IconBankTransfer;