import React from "react";

function IconLock(props) {
  let color = props.color ? props.color : "#fff";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M5.25 24A2.252 2.252 0 013 21.75v-10.5A2.252 2.252 0 015.25 9H6V6c0-3.308 2.692-6 6-6s6 2.692 6 6v3h.75A2.252 2.252 0 0121 11.25v10.5A2.252 2.252 0 0118.75 24H5.25zm0-13.5a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-10.5a.75.75 0 00-.75-.75H5.25zM16.5 9V6c0-2.481-2.019-4.5-4.5-4.5A4.505 4.505 0 007.5 6v3h9z"
      ></path>
      <path
        fill={color}
        d="M12 18.75a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v3a.75.75 0 01-.75.75z"
      ></path>
    </svg>
  );
}

export default IconLock;
