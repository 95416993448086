import { createSlice } from "@reduxjs/toolkit";
import entitiesApi from "../../api/entities.api";

export const entitiesSlice = createSlice({
  name: "entitiesStore",
  initialState: {
    isLoading: false,
    entities: [],
    loadError: null,
  },
  reducers: {
    loadEntitiesStart: (state) => {
      state.isLoading = true;
    },
    loadEntitiesSuccess: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    loadEntitiesFailure: (state, action) => {
      state.isLoading = false;
      state.loadError = action.payload.message;
    },
    resetEntities: (state) => {
      state.isLoading = false;
      state.entities = [];
      state.loadError = null;
    },
  },
});

export const selectIsLoading = (state) => state.entitiesStore.isLoading;
export const selectLoadError = (state) => state.entitiesStore.loadError;
export const selectEntities = (state) => state.entitiesStore.entities;

// Don't expose to rest of the app.
const {
  loadEntitiesStart,
  loadEntitiesSuccess,
  loadEntitiesFailure,
} = entitiesSlice.actions;

export const loadEntities = () => async (dispatch) => {
  dispatch(loadEntitiesStart());
  try {
    let response = await entitiesApi.getEntities();
    dispatch(loadEntitiesSuccess(response));
  } catch (err) {
    console.error(err);
    dispatch(
      loadEntitiesFailure({ message: "Unable to load entities for UI." })
    );
  }
};

export const resetEntities = () => (dispatch) => {
  dispatch(entitiesSlice.actions.resetEntities());
};

export default entitiesSlice.reducer;
