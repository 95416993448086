import React from "react";
import "../../scss/components/transactions/_transaction_status_indicator.scss";

function SuccessStatusIndicator(props) {
  return (
    <div className={`step step-hover`} data-tip={props.tooltipText}>
      <div className="step-indicator">
        <hr />
        <div className="circle-completed"></div>
        <hr />
      </div>
    </div>
  );
}

export default SuccessStatusIndicator;
