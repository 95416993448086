import React, { useEffect, useState } from "react";
import CessionsCalendar from "../components/transactions/CessionsCalendar";
import ClientSummary from "../components/transactions/ClientSummary";
import AvailableBalances from "../components/transactions/AvailableBalances";
import NetDues from "../components/transactions/NetDues";
import MonthlyDetails from "../components/transactions/MonthlyDetails";
import { useAuthContext } from "../Auth";
import cessionApi from "../api/cession.api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clientsApi from "../api/clients.api";
import { startOfMonth } from "date-fns";
import "../scss/homepage/_homepage.scss";

function HomePage() {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [blackoutData, setBlackoutData] = useState({});
  const [periods, setPeriods] = useState([]);
  const [clientsNames, setClientsNames] = useState([]);
  const [captivesNames, setCaptivesNames] = useState([]);
  const [captivesCodes, setCaptivesCodes] = useState([]);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          const response = await cessionApi.getPeriods();
          setPeriods(response.periods);
        } catch (e) {
          setErrorText(t("Error loading the list of periods."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getClientNames({});
          setClientsNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of clients."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveNames({});
          setCaptivesNames(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives names."));
        }
      });

      dispatch(async () => {
        try {
          const response = await clientsApi.getCaptiveCodes({});
          setCaptivesCodes(response);
        } catch (err) {
          setErrorText(t("Error loading the list of captives codes."));
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, t]);

  const getBlackOutData = async () => {
    try {
      const response = await cessionApi.getBlackOutData();
      setBlackoutData(response);
    } catch (err) {
      setErrorText(t("Error retrieving the blackout data configuration"));
    }
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      getBlackOutData();
    }
  }, []);

  const cessionPeriod = startOfMonth(
    new Date(blackoutData?.cessionPeriod ?? "")
  );

  return (
    <>
      <div className="container-fluid">
        {errorText && (
          <div className="text-center text-danger">{errorText}</div>
        )}
        <div className="row header-row">
          <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12">
            <AvailableBalances
              clientsNames={clientsNames}
              captivesNames={captivesNames}
              captivesCodes={captivesCodes}
              remainingTransferDays={
                blackoutData?.remainingDays?.businessDays ?? ""
              }
            />
            <MonthlyDetails
              periods={periods}
              clientsNames={clientsNames}
              captivesNames={captivesNames}
              captivesCodes={captivesCodes}
            />
            <NetDues
              periods={periods}
              clientsNames={clientsNames}
              captivesNames={captivesNames}
              captivesCodes={captivesCodes}
            />
          </div>
          {Object.keys(blackoutData).length > 0 && (
            <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 row height-fit-content">
              <CessionsCalendar
                currentDate={new Date(blackoutData?.currentDate ?? "")}
                culture="en-US"
                calendarType="US"
                blackoutPeriodLengthInDays={
                  blackoutData?.blackoutPeriodLength ?? ""
                }
                remainingTransferDays={
                  blackoutData?.remainingDays?.businessDays ?? ""
                }
                blackoutPeriod={blackoutData?.blackoutDate ?? ""}
                cessionPeriod={cessionPeriod}
              />
              <ClientSummary />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default HomePage;
