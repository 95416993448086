import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import "../../scss/useradmin/_invite_user.scss";
import { selectModuleConfig } from "../../features/moduleConfig/moduleConfigSlice";
import {
  selectProfileMenuPages,
  selectSideNavPages,
  selectOtherPages,
} from "../../features/pages/pagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { IconCheckmark, IconArrowLeft } from "../../assets/icons";
import {
  selectPermissionGroup,
  selectUserType,
  setPermissionGroup,
  selectUsers,
} from "../../features/inviteUsers/inviteUsersSlice";
import {
  selectIsSaving,
} from "../../features/users/userDetailsSlice.js";

const UserPermissions = (props) => {
  const { userPermissionGroup, userSetPermissionGroup } = props;

  const users = useSelector(selectUsers);
  const permissionGroup =
    userPermissionGroup ?? useSelector(selectPermissionGroup);
  const userType = props.userType ?? useSelector(selectUserType);
  const dispatch = useDispatch();

  const t = useTranslation().t;
  const { headerText, stepIndicator, isChangeClientFlow } = props;
  const profileMenuPages = useSelector(selectProfileMenuPages);
  const sideNavPages = useSelector(selectSideNavPages);
  const otherPages = useSelector(selectOtherPages);
  const [pages, setPages] = useState([]);
  const moduleConfig = useSelector(selectModuleConfig);

  const [permissionGroups, setPermissionGroups] = useState([]);
  const isSaving = useSelector(selectIsSaving);

  useEffect(() => {
    if (moduleConfig && moduleConfig.permissionGroups) {
      setPermissionGroups(
        moduleConfig.permissionGroups.filter((g) => g.userType === userType)
      );
    }
  }, [moduleConfig, userType]);

  useEffect(() => {
    const permissionGroupNames = permissionGroups.map((g) => g.oktaName);
    setPages(
      [...profileMenuPages, ...otherPages, ...sideNavPages].filter(
        (p) =>
          p.groups.filter((g) => permissionGroupNames.includes(g)).length > 0
      )
    );
  }, [profileMenuPages, sideNavPages, permissionGroups, otherPages]);

  const onSelectPermissionGroup = (group) =>
    userSetPermissionGroup
      ? userSetPermissionGroup(group)
      : dispatch(setPermissionGroup(group));

  return (
    moduleConfig && (
      <div className="InternalUserPermissions userPermissions">
        <div className="MyProfile-header">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <span>
                {headerText ? headerText : t("Select Permission Level")}
              </span>
              {stepIndicator}
            </div>
            <div className="card-body" style={{minHeight: "700px", maxHeight: "700px"}}>
            <div
              className="d-flex justify-content-between"
              style={{ flexWrap: "wrap" }}
            >
              {!isChangeClientFlow && (
                <div
                  className="card mr-4"
                  style={{
                    flex: "2 1",
                    minWidth: 400,
                    maxWidth: 400,
                    borderRight: "0.5px solid #dee2e6",
                    borderRadius: "0px",
                    paddingRight: "40px",
                    minHeight: 650,
                    maxHeight: 650,
                  }}
                >
                  <div
                    className="card-header"
                    style={{ padding: 0, paddingBottom: 30 }}
                  >
                    <span>{t("User(s)")}</span>
                  </div>
                  <div className="scrollable">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("Job Title")}</th>
                          <th scope="col">{t("Permission")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => {
                          return (
                            <tr key={`user_${index}`}>
                              <td>
                                {user.firstName} {user.lastName}
                              </td>
                              <td>{user.jobTitle}</td>
                              <td>{permissionGroup.friendlyName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="card d-flex PermissionMatrix"
                style={{ minWidth: 200, flex: "2 1" }}
              >
                <div
                  className="card-header"
                  style={{ padding: 0, paddingBottom: 30 }}
                >
                  <span>{t("Permission Levels")}</span>
                </div>
                <div className="scrollable">
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <td></td>
                      {permissionGroups.map((group) => (
                        <td
                          key={`header-${group.oktaName}`}
                          onClick={() => onSelectPermissionGroup(group)}
                          className={
                            permissionGroup.oktaName === group.oktaName
                              ? "selected first"
                              : ""
                          }
                        >
                          <div
                            className={
                              "d-flex flex-column align-items-center justify-content-center"
                            }
                          >
                            <input
                              type="radio"
                              name="plan_name"
                              checked={
                                permissionGroup.oktaName === group.oktaName
                              }
                            ></input>
                            <p>{t(group.friendlyName)}</p>
                          </div>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {pages.map((page) => (
                      <tr>
                        <td className="text-left font-weight-bold">
                          {t(page.title)}
                        </td>
                        {permissionGroups.map((group) => (
                          <td
                            className={
                              permissionGroup.oktaName === group.oktaName
                                ? "selected"
                                : ""
                            }
                          >
                            {page.groups.includes(group.oktaName) ? (
                              <IconCheckmark height="10" color="#1575C5" />
                            ) : null}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      {permissionGroups.map((group) => (
                        <td
                          key={`footer-${group.oktaName}`}
                          className={
                            permissionGroup.oktaName === group.oktaName
                              ? "selected last"
                              : ""
                          }
                        ></td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
              </div>

              </div>
              <div
                className={
                  props.onCancel
                    ? "d-flex flex-nowrap pt-4 justify-content-center w-100"
                    : "d-flex justify-content-between"
                }
              >
                {props.onCancel ? null : (
                  <Link to="/users/add/info">
                    <i className="mr-2">
                      <IconArrowLeft />
                    </i>
                    {t("Back")}
                  </Link>
                )}
                {props.onSave ? (
                  <Button
                    className="mr-3"
                    style={{ minWidth: `125px` }}
                    color="primary"
                    onClick={props.onSave}
                    disabled={isSaving}
                  >
                    {t("Save")}
                  </Button>
                ) : (
                  <Button
                    tag={Link}
                    color="primary"
                    to={
                      permissionGroup.oktaName ===
                      moduleConfig.internalAdmin.oktaName
                        ? "/users/add/confirm"
                        : "/users/add/captives"
                    }
                    disabled={!permissionGroup}
                  >
                    {t("Continue")}
                  </Button>
                )}
                {props.onCancel ? (
                  <Button
                    className="mr-3"
                    style={{ minWidth: `125px` }}
                    outline
                    color="primary"
                    onClick={props.onCancel}
                  >
                    {t("Cancel")}
                  </Button>
                ) : null}
              </div>
          
          </div>
        </div>
      </div>
    )
  );
};

export default UserPermissions;