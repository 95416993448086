import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import DataTable, {
  formatMoney,
  formatMoneyNegative,
  formatPeriod,
  formatAccount,
  formatTransactionStatus,
  generateColumn,
  generateActionsColumnForMonthlyDetails,
} from "../general/DataTable";
import { debounce } from "throttle-debounce";
import { IconAdd, IconDocumentEdit, IconSubtract } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import cessionApi from "../../api/cession.api";
import TooltipButton from "../../utils/TooltipButton";

const MonthlyDetails = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [errorText, setErrorText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const downloadAction = async () => {
    try {
      await cessionApi.downloadPeriodsSummary({
        ...searchParams,
        page: 0,
        pageSize: 1500,
      });
    } catch (e) {
      setErrorText(
        t("Failed to download the report. Please, try again later.")
      );
    }
  };

  const columns = [
    generateColumn(
      "cessionPeriod",
      t("Cession Period"),
      true,
      { minWidth: "155px", maxWidth: "155px" },
      formatPeriod
    ),
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "240px",
      maxWidth: "240px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn("beginningBalance", t("Beginning"), false, {}, formatMoney),
    generateColumn("released", t("Released"), false, {}, formatMoneyNegative),
    generateColumn("endingBalance", t("Ending"), false, {}, formatMoney),
    generateColumn("", "", false, {}),
  ];

  const [data, setData] = useState({ data: [], page: 0, totalCount: 0 });

  const [searchParams, setSearchParams] = useState({
    cessionPeriod: "",
    clientName: "",
    captiveName: "",
    captiveCode: "",
    searchText: null,
    page: 1,
    pageSize: 6,
    sortBy: "cessionPeriod",
    descending: true,
  });

  const debouncedSearch = debounce(500, (searchValue) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: searchValue,
    });
  });

  const handleSearch = (e) => {
    e.persist();
    e.preventDefault();
    debouncedSearch(e.target.value);
  };

  const onTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      ...searchParams,
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  const handleDropdownSelection = (id, value) => {
    let newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const dispatch = useDispatch();
  const authContext = useAuthContext();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setIsLoadingData(true);
      dispatch(async () => {
        try {
          const result = await cessionApi.getPeriodsSummary({
            ...searchParams,
            page: searchParams.page - 1,
          });
          setData(result);
          setErrorText("");
        } catch (e) {
          setErrorText(
            t("Error loading period summary data. Please, try again later.")
          );
        } finally {
          setIsLoadingData(false);
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, searchParams, t]);

  const dropdowns = [
    {
      id: "cessionPeriod",
      title: t("Cession Period"),
      values: props.periods.map((c) => {
        return { id: c, name: formatPeriod(c) };
      }),
      handleSelected: handleDropdownSelection,
      width: "165px",
    },
    {
      id: "clientNameId",
      title: t("Client"),
      values: props.clientsNames.map((c) => {
        return { id: c.id, name: c.name };
      }),
      handleSelected: handleDropdownSelection,
    },
    {
      id: "captiveName",
      title: t("Captive Name"),
      values: props.captivesNames.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "180px",
    },
    {
      id: "captiveCode",
      title: t("Captive Code"),
      values: props.captivesCodes.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
    },
  ];

  const showTransferRequestDetails = (id) => {
    history.push("/transferrequest/" + id);
  };

  const formatterActionsExpanded = (_cell, row) => {
    return (
      <div className="icon-action">
        <TooltipButton />
        <i onClick={() => showTransferRequestDetails(row.id)}>
          <IconDocumentEdit height={20} />
        </i>
      </div>
    );
  };

  const expandedColumns = [
    generateColumn("1", "", false, { minWidth: "20px", maxWidth: "20px" }),
    generateColumn("2", "", false, { minWidth: "155px", maxWidth: "155px" }),
    generateColumn("3", "", false, { minWidth: "120px", maxWidth: "120px" }),
    generateColumn(
      "trustAccountNumber",
      t("Account #"),
      false,
      { minWidth: "240px", maxWidth: "240px" },
      (_, row) => formatAccount(row.bankName, row.trustAccountNumber)
    ),
    generateColumn(
      "transactionStatus",
      "Status",
      false,
      { minWidth: "120px", maxWidth: "120px" },
      formatTransactionStatus
    ),
    generateColumn(
      "beginning",
      t("Beginning"),
      false,
      { minWidth: "120px", maxWidth: "120px" },
      formatMoney
    ),
    generateColumn(
      "released",
      t("Released"),
      false,
      { minWidth: "120px", maxWidth: "120px" },
      formatMoneyNegative
    ),
    generateColumn(
      "ending",
      t("Ending"),
      false,
      { minWidth: "120px", maxWidth: "120px" },
      formatMoney
    ),
    generateActionsColumnForMonthlyDetails(formatterActionsExpanded),
  ];

  const [periodActivity, setPeriodActivity] = useState({});

  const fetchPeriodActivity = (parentRowId, clientId) => {
    dispatch(async () => {
      try {
        const activities = await cessionApi.getPeriodActivityData({
          clientId,
          cessionPeriodBalanceId: parentRowId,
        });
        const newPeriodActivity = { ...periodActivity };
        console.log(newPeriodActivity, "new Periodactivity");
        newPeriodActivity[parentRowId] = activities;
        setPeriodActivity(newPeriodActivity);
      } catch (e) {
        setErrorText(
          t(
            "Error loading transactions data shown after expanding a row. Please, try again later."
          )
        );
      }
    });
  };

  const expandRow = {
    renderer: function RowDetails(row) {
      const activities = periodActivity[row.id] ?? [];
      return (
        <div className="CaptivesList">
          <BootstrapTable
            remote
            keyField="id"
            data={activities}
            columns={expandedColumns}
            hover
            noDataIndication={
              periodActivity[row.id] == undefined
                ? t("Loading data...")
                : t("No data for the selected period.")
            }
            bordered={false}
            hideSizePerPage={true}
          />
        </div>
      );
    },
    showExpandColumn: true,
    expandHeaderColumnRenderer: function RowExpandHeaderColumn() {
      return <></>;
    },
    expandColumnRenderer: function RowExpandColumn({ expanded }) {
      if (expanded) {
        return <IconSubtract height="11"></IconSubtract>;
      }
      return <IconAdd height="11"></IconAdd>;
    },
    onExpand: (row, isExpand) => {
      if (isExpand && !periodActivity[row.id]) {
        fetchPeriodActivity(row.id, row.clientId);
      }
    },
  };

  return (
    <div className="MonthlyDetailsList  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-wrap">
      <DataTable
        errorText={errorText}
        columns={columns}
        data={data.data}
        defaultSorted={[{ dataField: "cessionPeriod", order: "desc" }]}
        isLoading={false}
        defaultSortField="cessionPeriod"
        totalSize={data.totalCount}
        pageSize={searchParams.pageSize}
        page={searchParams.page}
        onTableChange={onTableChange}
        title={t("Monthly Details")}
        handleSearch={handleSearch}
        dropdowns={dropdowns}
        downloadAction={downloadAction}
        expandRow={expandRow}
        noDataIndication={
          isLoadingData
            ? t("Loading data...")
            : t("No data for the selected period.")
        }
      />
    </div>
  );
};

export default MonthlyDetails;
