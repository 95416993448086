import React from 'react';
import Calendar from 'react-calendar';
import "../../scss/components/general/_calendar_month.scss";

const CalendarMonth = (props) => {
    return (
        <div className="calendar">           
            <Calendar       
                showNavigation={false} 
                calendarType={props.calendarType}       
                tileDisabled={props.isDayDisabled}
                defaultValue={props.currentDate}
                />
        </div>
    );
}

export default CalendarMonth;