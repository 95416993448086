import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import "../../scss/useradmin/_invite_user.scss";
import { IconArrowLeft } from "../../assets/icons";
import {
  selectUsers,
  selectUserType,
  selectPermissionGroup,
  selectClients,
  selectSelectedClients,
  selectInviteUsersStatus,
  inviteUsers,
} from "../../features/inviteUsers/inviteUsersSlice";
import { selectModuleConfig } from "../../features/moduleConfig/moduleConfigSlice";
import InviteSentModal from "./InviteSentModal";

const UserConfirm = (props) => {
  const users = useSelector(selectUsers);
  const userType = useSelector(selectUserType);
  const permissionGroup = useSelector(selectPermissionGroup);
  const clients = useSelector(selectClients);
  const selectedClients = useSelector(selectSelectedClients);
  const inviteUsersStatus = useSelector(selectInviteUsersStatus);
  const history = useHistory();

  const dispatch = useDispatch();
  const t = useTranslation().t;
  const moduleConfig = useSelector(selectModuleConfig);

  const [open, setOpen] = useState(false);

  const submit = async () => {
    const finalUsers = users.map((user) => {
      const finalUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        login: user.login,
        jobTitle: user.jobTitle,
        companyName: user.companyName,
      };

      if (user.id) finalUser.id = user.id;

      return finalUser;
    });

    const isSuccess = await dispatch(
      inviteUsers(finalUsers, permissionGroup.oktaName, selectedClients)
    );

    setOpen(isSuccess);
  };

  function toggleSuccessDialog() {
    setOpen((prev) => !prev);
    history.push("/admin/users");
  }

  const { stepIndicator } = props;
  const isInternalAdminFlow =
    userType === moduleConfig.internalAdmin.userType &&
    permissionGroup.oktaName === moduleConfig.internalAdmin.oktaName;

  return (
    <div className="InternalUserConfirm">
      <div className="MyProfile-header">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            {stepIndicator}
          </div>
        </div>
        <div className="card-body" style={{ minHeight: 700, maxHeight: 700 }}>
          <div
            className="d-flex justify-content-between"
            style={{ flexWrap: "wrap" }}
          >
            <div
              className="card card-one"
              style={{ flex: 1, minWidth: 300, marginRight: 80 }}
            >
              <div
                className="card-header"
                style={{
                  padding: 0,
                  paddingBottom: 10,
                  marginBottom: 10,
                  borderBottom: "0.5px solid #dee2e6",
                  borderRadius: "0px",
                }}
              >
                <span>{t("User(s)")}</span>
              </div>
              <div className="scrollable">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Job Title")}</th>
                      <th scope="col">{t("Email")}</th>
                      <th scope="col">{t("Permission")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => {
                      return (
                        <tr key={`user_${index}`}>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.jobTitle}</td>
                          <td>{user.email}</td>
                          <td>{permissionGroup.friendlyName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="card card-one mr-4"
              style={{ flex: 1, minWidth: 300 }}
            >
              <div
                className="card-header"
                style={{
                  padding: 0,
                  paddingBottom: 10,
                  marginBottom: 10,
                  borderBottom: "0.5px solid #dee2e6",
                  borderRadius: "0px",
                }}
              >
                {isInternalAdminFlow ? (
                  <span>{t("All Captives")}</span>
                ) : (
                  <span>{t("Captive(s)")}</span>
                )}
              </div>
              {isInternalAdminFlow ? (
                <></>
              ) : (
                <>
                  <div className="scrollable-list">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{t("Client")}</th>
                          <th
                            scope="col"
                            style={{ minWidth: 245, maxWidth: 245 }}
                          >
                            {t("Captive Name")}
                          </th>
                          <th scope="col">{t("Captive Code")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map((client) => {
                          const clientCaptiveIds = client.captives.map(
                            (cap) => cap.clientId
                          );

                          if (
                            selectedClients.some((sC) =>
                              clientCaptiveIds.includes(sC)
                            )
                          ) {
                            const selectedCaptives = client.captives.filter(
                              (cap) => selectedClients.includes(cap.clientId)
                            );

                            return (
                              <>
                                {selectedCaptives.map((sCap, index) => (
                                  <tr>
                                    <td>
                                      {index === 0 ? client.clientName : ""}
                                    </td>
                                    <td>{sCap.captiveName}</td>
                                    <td>{sCap.captiveCode}</td>
                                  </tr>
                                ))}
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <InviteSentModal isOpen={open} toggle={toggleSuccessDialog} />
        <div className="d-flex justify-content-between">
          <Link
            to={
              permissionGroup.oktaName === moduleConfig.internalAdmin.oktaName
                ? "/users/add/permissions"
                : "/users/add/captives"
            }
          >
            <i className="mr-2">
              <IconArrowLeft />
            </i>
            {t("Back")}
          </Link>
          <div>
            <Button
              color="primary"
              onClick={() => submit()}
              disabled={
                inviteUsersStatus.isLoading || inviteUsersStatus.isSuccess
              }
            >
              {inviteUsersStatus.isLoading
                ? t("Saving...")
                : t("Confirm & Save")}
            </Button>
            {inviteUsersStatus.isLoading ? (
              <p style={{ width: "115px", textAlign: "center" }}>
                Please be patient while we save this information.
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserConfirm;
