import React from "react";
import "../../scss/components/transactions/_transaction_status_indicator.scss";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import SuccessStatusIndicator from "./SuccessStatusIndicator";
import FailureStatusIndicator from "./FailureStatusIndicator";
import PendingStatusIndicator from "./PendingStatusIndicator";
import CurrentStatusIndicator from "./CurrentStatusIndicator";

function TransactionStatusIndicator(props) {
  const { t } = useTranslation();

  const tooltipTexts = [
    t("Request Received"),
    t("Letter Generated"),
    t("Request Sent to Bank"),
    t("Transfer Completed"),
    t("Request Letter Past Due"),
    t("Invalid Response from Bank"),
    t("Invalid Bank Email Address"),
  ];

  const stepRenderer = () => {
    switch (props.currentStep) {
      case 0:
        return (
          <>
            <CurrentStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></CurrentStatusIndicator>
            <PendingStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></PendingStatusIndicator>
            <PendingStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></PendingStatusIndicator>
            {/* <PendingStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></PendingStatusIndicator> */}
          </>
        );
      case 1:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <CurrentStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></CurrentStatusIndicator>
            <PendingStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></PendingStatusIndicator>
            {/* <PendingStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></PendingStatusIndicator> */}
          </>
        );
      case 2:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <PendingStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></PendingStatusIndicator>
            {/* <PendingStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></PendingStatusIndicator> */}
          </>
        );
      case 3:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></SuccessStatusIndicator>
            {/* <CurrentStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></CurrentStatusIndicator> */}
          </>
        );
      case 4:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></SuccessStatusIndicator>
            {/* <SuccessStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></SuccessStatusIndicator> */}
          </>
        );
      case 5:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></SuccessStatusIndicator>
            {/* <FailureStatusIndicator
              tooltipText={tooltipTexts[4]}
            ></FailureStatusIndicator> */}
          </>
        );
      case 6:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[2]}
            ></SuccessStatusIndicator>
            {/* <FailureStatusIndicator
              tooltipText={tooltipTexts[5]}
            ></FailureStatusIndicator> */}
          </>
        );
      case 7:
        return (
          <>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[0]}
            ></SuccessStatusIndicator>
            <SuccessStatusIndicator
              tooltipText={tooltipTexts[1]}
            ></SuccessStatusIndicator>
            <FailureStatusIndicator
              tooltipText={tooltipTexts[6]}
            ></FailureStatusIndicator>
            {/* <PendingStatusIndicator
              tooltipText={tooltipTexts[3]}
            ></PendingStatusIndicator> */}
          </>
        );
    }
  };

  return (
    <>
      <ReactTooltip place={"left"} type={"dark"} />
      <div className="transaction-status-indicator">{stepRenderer()}</div>
    </>
  );
}

export default TransactionStatusIndicator;
