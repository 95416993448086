import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../general/DataTable";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import { debounce } from "throttle-debounce";
import { DateUtils } from "../../utils/DateUtils";

const StatementsList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const [errorText, setErrorText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [statements, setStatements] = useState({
    data: [],
    page: 0,
    totalCount: 0,
  });

  const [searchParams, setSearchParams] = useState({
    cessionPeriod: "",
    clientName: "",
    captiveName: "",
    captiveCode: "",
    searchText: null,
    page: 1,
    pageSize: 10,
    sortBy: "cessionPeriod",
    descending: true,
  });

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setIsLoadingData(true);
      dispatch(async () => {
        try {
          const result = await props.getData({
            ...searchParams,
            page: searchParams.page - 1,
          });
          setStatements(result);
          setErrorText("");
        } catch (e) {
          setErrorText(
            t("Error loading list of statements. Please, try again later.")
          );
        } finally {
          setIsLoadingData(false);
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, searchParams]);

  const onTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      ...searchParams,
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect) => {
      if (isSelect === true) {
        selectedRows.push(row);
      } else {
        const idx = selectedRows.indexOf(row);
        selectedRows.splice(idx, 1);
      }

      setSelectedRows(selectedRows);
      props.handleSelectionChanged(selectedRows);
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        rows.forEach((row) => {
          selectedRows.push(row);
        });
      } else {
        rows.forEach((row) => {
          const idx = selectedRows.indexOf(row);

          if (idx !== -1) {
            selectedRows.splice(idx, 1);
          }
        });
      }

      setSelectedRows(selectedRows);
      props.handleSelectionChanged(selectedRows);
    },
  };

  const debouncedSearch = debounce(500, (searchValue) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: searchValue,
    });
  });

  const handleSearch = (e) => {
    e.persist();
    e.preventDefault();
    debouncedSearch(e.target.value);
  };

  const handleDropdownSelection = (id, value) => {
    let newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const dropdowns = [
    {
      id: "cessionPeriod",
      title: t("Cession Period"),
      values: props.periods.map((c) => {
        return { id: c, name: DateUtils.formatPeriod(c) };
      }),
      handleSelected: handleDropdownSelection,
      width: "165px",
    },
    {
      id: "clientNameId",
      title: t("Client"),
      values: props.clientsNames.map((c) => {
        return { id: c.id, name: c.name };
      }),
      handleSelected: handleDropdownSelection,
    },
    {
      id: "captiveName",
      title: t("Captive Name"),
      values: props.captivesNames.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "265px",
    },
    {
      id: "captiveCode",
      title: t("Captive Code"),
      values: props.captivesCodes.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
    },
  ];

  return (
    <div className="Statements List">
      {errorText && (
        <div className="text-center mb-3 text-danger">{errorText}</div>
      )}
      <DataTable
        columns={props.columns}
        data={statements.data}
        keyField={"id"}
        defaultSorted={[{ dataField: "cessionPeriod", order: "desc" }]}
        isLoading={false}
        defaultSortField="cessionPeriod"
        totalSize={statements.totalCount}
        pageSize={searchParams.pageSize}
        page={searchParams.page}
        onTableChange={onTableChange}
        title={props.title}
        handleSearch={handleSearch}
        dropdowns={dropdowns}
        selectRow={selectRow}
        noDataIndication={
          isLoadingData
            ? t("Loading data...")
            : t("No data for the selected period.")
        }
      />
    </div>
  );
};

export default StatementsList;
