import "../../scss/components/general/_data_table.scss";

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Loader from "../general/Loader";
import { useTranslation } from "react-i18next";
import { Form, Input, FormGroup } from "reactstrap";
import { IconSearch, IconInfo, IconDownload } from "../../assets/icons";
import { sortCaret } from "../general/BootstrapTableSettings";
import { DateUtils } from "../../utils/DateUtils";
import TransactionStatusIndicator from "../transactions/TransactionStatusIndicator";

export const accountFormatter = (_, row) => {
  return (
    <span>
      {row.bankName}, **{row.trustAccountNumber}
    </span>
  );
};

export const periodFormatter = (cell, _) => {
  return <span>{DateUtils.formatPeriod(cell)}</span>;
};

export const generateColumn = (
  dataFieldName,
  colTitle,
  sort = true,
  style = null,
  formatter = null
) => {
  return {
    dataField: dataFieldName,
    text: colTitle,
    sort: sort,
    sortCaret: sortCaret,
    style: style,
    headerStyle: style,
    formatter: formatter,
  };
};

export const generateActionsColumn = (formatterActions, formatExtraData) => {
  return {
    dataField: "formatterActions",
    text: "",
    formatter: formatterActions,
    classes: "edit-delete-column",
    style: { minWidth: "60px", maxWidth: "60px" },
    headerStyle: { minWidth: "60px", maxWidth: "60px" },
    formatExtraData: formatExtraData,
  };
};

export const generateActionsColumnForMonthlyDetails = (formatterActions) => {
  return {
    dataField: "formatterActions",
    text: "",
    formatter: formatterActions,
    classes: "edit-delete-column",
    style: { minWidth: "120px", maxWidth: "1200px" },
    headerStyle: { minWidth: "120px", maxWidth: "120px" },
  };
};

const DataTable = (props) => {
  const { t } = useTranslation();
  const page = props.page;
  const sizePerPage = props.pageSize;
  const totalSize = props.totalSize;

  const invokeDropdownHandler = (e, handler) => {
    const {
      target: { id, value },
    } = e;

    handler(id, value);
  };

  const getDropdown = (
    id,
    title,
    values,
    handleSelected,
    dropdownWidth,
    showPlaceholder = true
  ) => {
    return (
      <FormGroup key={id}>
        <Input
          type="select"
          name={title}
          id={id}
          onChange={(e) => invokeDropdownHandler(e, handleSelected)}
          style={{
            minWidth: dropdownWidth || "130px",
            maxWidth: dropdownWidth || "130px",
          }}
        >
          {showPlaceholder && (
            <option key="" value="">
              {title}
            </option>
          )}
          {values.map((v) => {
            return (
              <option key={v.id} value={v.id}>
                {v.name}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  const renderTooltip = (tooltipContent) => {
    return (
      <span className="table-header-tooltip-icon mr-3 mt-1">
        <IconInfo />
        <span className="table-header-tooltip">{tooltipContent}</span>
      </span>
    );
  };

  return (
    <div className="DataTable card">
      <div className="card-header d-flex justify-content-between">
        <span className="mr-3 mb-0">
          {props.title}{" "}
          {props.tooltipContent && renderTooltip(props.tooltipContent)}
        </span>

        {props.downloadAction && (
          <div className="icon-action">
            <i onClick={() => props.downloadAction()}>
              <IconDownload />
              {props.downloadText && (
                <span className="download-text ml-2">{props.downloadText}</span>
              )}
            </i>
          </div>
        )}
      </div>

      <div className="card-body">
        <Form
          className="search-form d-flex justify-content-lg-between flex-wrap flex-xl-wrap flex-lg-wrap flex-md-wrap flex-sm-wrap flex-xs-wrap wrap-text-iphone"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="d-flex justify-content-start flex-wrap flex-xl-wrap flex-lg-wrap flex-md-wrap flex-sm-wrap flex-xs-wrap wrap-text-iphone">
            {props.dropdowns.map((dropdown) => {
              return getDropdown(
                dropdown.id,
                dropdown.title,
                dropdown.values,
                dropdown.handleSelected,
                dropdown?.width,
                dropdown?.showPlaceholder
              );
            })}
          </div>
          <div>
            <FormGroup className="d-flex justify-content-end">
              <Input
                type="search"
                placeholder={t("Search...")}
                name="search"
                onChange={props.handleSearch}
              />
              <label className="floating-label search" htmlFor="search">
                {t("Search...")}
              </label>
              <div className="form-icon" onClick={props.handleSearch}>
                <i>
                  <IconSearch />
                </i>
              </div>
            </FormGroup>
          </div>
        </Form>
        {props.errorText && (
          <div className="text-center text-danger">{props.errorText}</div>
        )}

        <div className="table-responsive">
          <BootstrapTable
            remote
            keyField={props.keyField || "id"}
            data={props.data}
            columns={props.columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={props.onTableChange}
            hover
            striped={false}
            condensed
            noDataIndication={props.noDataIndication || t("No data available.")}
            bordered={false}
            hideSizePerPage={true}
            defaultSorted={props.defaultSorted}
            expandRow={props.expandRow}
            selectRow={props.selectRow}
          />
          {props.isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export const formatMoney = (number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
  });
  return `${formatter.format(number)}`;
};

export const formatMoneyNegative = (number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
  });
  return `${formatter.format(number * -1)}`;
};

export const formatPeriod = (date) => {
  return DateUtils.formatPeriod(date);
};

export const formatAccount = (bankName, accountNumber) => {
  return `${bankName}, **${accountNumber}`;
};

export const formatTransactionStatus = (currentStep) => {
  return <TransactionStatusIndicator currentStep={currentStep} />;
};

export default DataTable;
