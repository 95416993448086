import React from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, Button } from "reactstrap";
import "../../scss/components/general/_alert_box.scss";
import PropTypes from "prop-types";
import { IconRemove } from "../../assets/icons";
import loadable from "@loadable/component";

const importIcon = (iconName) =>
  loadable(() => import(`../../assets/icons/${iconName}`));

const AlertBox = (props) => {
  const {
    title,
    text,
    onClose,
    isOpen,
    isSuccess,
    alertIcon,
    isClass,
    buttonText,
  } = props;

  const history = useHistory();

  const routeChange = () => {
    let path = `/support`;
    history.push(path);
  };

  // revisit as we use the alert box to make sure this is how we want to do this.
  const returnAlertIcon = () => {
    let iconHeight = "50";
    let IconComponent;
    let color;
    let iconName;

    if (!alertIcon || alertIcon === "default") {
      if (isSuccess) {
        color = "#40b93C";
        iconName = "IconCheckmark";
      } else {
        color = "#FF9900";
        iconName = "IconWarning";
      }
      iconName = isSuccess ? "IconCheckmark" : "IconWarning";
      IconComponent = importIcon(iconName);
    } else {
      IconComponent = importIcon(alertIcon);
    }
    return <IconComponent height={iconHeight} color={color} />;
  };
  let alertIconSpinClass = isSuccess ? "alert-icon-spin" : false;
  let alertIconWarningTitleClass = isClass ? "alert-iconWarning-title" : false;
  let alertIconWarningTextClass = isClass ? "alert-iconWarning-text" : false;
  let alertIconWarningButtonClass = isClass
    ? "alert-iconWarning-button"
    : false;
  // default checkmark, warning, email

  return (
    <Modal isOpen={isOpen} centered autoFocus className="AlertBox">
      <ModalBody>
        <div onClick={onClose} className="close-button text-center py-3">
          <i>
            <IconRemove />
          </i>
        </div>
        <div className="alert-box-content text-center d-flex flex-wrap align-items-center">
          {alertIcon ? (
            <div
              className={`w-100 align-self-end alert-icon pt-3 ${alertIconSpinClass}`}
            >
              <i>{returnAlertIcon()}</i>
            </div>
          ) : (
            false
          )}

          {title ? (
            <h3
              className={`w-100 font-weight-bold fade-in mt-3 ${alertIconWarningTitleClass}`}
            >
              {title}
            </h3>
          ) : (
            false
          )}
          {text ? (
            <p
              className={`w-100 align-self-start fade-in ${alertIconWarningTextClass}`}
            >
              {text}
            </p>
          ) : (
            false
          )}
          {buttonText ? (
            <Button
              className={`mx-auto mt-4 mb-4 ${alertIconWarningButtonClass}`}
              color="primary"
              onClick={routeChange}
            >
              {buttonText}
            </Button>
          ) : (
            false
          )}
          {props.children}
        </div>
      </ModalBody>
    </Modal>
  );
};

AlertBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  alertIcon: PropTypes.string,
};

export default AlertBox;
