import React from "react";
import "../../scss/components/general/_summary_box.scss";
import { IconArrowRight } from "../../assets/icons";

const SummaryBox = (props) => {
  return (
    <div className="SummaryBox">
      <div className="row">
        <div className="col-2 icon">{props.icon}</div>
        <div className="col-8 description">
          {props.text}
          <br />
          <strong>{props.number}</strong>
        </div>
        <div className="col-2 icon details-icon">
          <i onClick={props.linkAction}>
            <IconArrowRight />
          </i>
        </div>
      </div>
    </div>
  );
};

export default SummaryBox;
