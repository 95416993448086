import React from "react";
import { CardTitle, CardText, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SimpleCard from "../general/SimpleCard";

const UserDetailsCard = (props) => {
  const {
    key = null,
    name = "",
    titleDepartment = "",
    email = "",
    active = false,
    selected = false,
    asSkeleton = false,
    action = null,
    actionText = "",
  } = props;

  return (
    <div>
      <SimpleCard key={key} active={active} selected={selected}>
        {asSkeleton ? (
          <Skeleton />
        ) : (
          <Row className="m-0 justify-content-between">
            <CardTitle>{name}</CardTitle>
            <Link className="card-action" onClick={action}>
              {actionText}
            </Link>
          </Row>
        )}
        <CardText>{asSkeleton ? <Skeleton /> : titleDepartment}</CardText>
        <CardText>{asSkeleton ? <Skeleton /> : email}</CardText>
      </SimpleCard>
    </div>
  );
};

export default UserDetailsCard;
