import api from ".";

const getTermsAndConditionsStatus = async () => {
    const fetchApiUrl = `${api.getApiUrl()}TermsAndConditions`;
    return api.get(fetchApiUrl, null, null);
  };

const markTermsAndConditionsAccepted = async () => {
    const fetchApiUrl = `${api.getApiUrl()}TermsAndConditions`;
    return api.post(fetchApiUrl, null, null);

}

export default {
    getTermsAndConditionsStatus,
    markTermsAndConditionsAccepted,
}