import { createSlice } from "@reduxjs/toolkit";

export const reportsSlice = createSlice({
  name: "reportsStore",
  initialState: {
    pages: [],
    appliedFilters: [],
    reportTitle: "",
  },
  reducers: {
    setPages: (state, action) => {
      state.pages = [...action.payload];
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilters = [...action.payload];
    },
    setReportTitle: (state, action) => {
      state.reportTitle = action.payload;
    },
  },
});

export const selectPages = (state) => state.reportsStore.pages;
export const selectAppliedFilters = (state) =>
  state.reportsStore.appliedFilters;
export const selectReportTitle = (state) => state.reportsStore.reportTitle;

// Don't expose to rest of the app.
const { setPages, setAppliedFilters, setReportTitle } = reportsSlice.actions;

export const setCurrentPages = (pages) => async (dispatch) => {
  dispatch(setPages(pages));
};

export const setCurrentAppliedFilters = (appliedFilters) => async (
  dispatch
) => {
  dispatch(setAppliedFilters(appliedFilters));
};

export const setCurrentReportTitle = (reportTitle) => async (dispatch) => {
  dispatch(setReportTitle(reportTitle));
};

export default reportsSlice.reducer;
