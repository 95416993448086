import React from "react";
import "../../scss/components/general/_loader.scss";
import { IconCircleArrows } from "../../assets/icons";

function Loader() {
  return (
    <div className="loader">
      <i>
        <IconCircleArrows color={"#2F353A"}/>
      </i>
    </div>
  );
}

export default Loader;
