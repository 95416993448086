import React from "react";
import { Card, CardBody } from "reactstrap";

import "../../scss/components/general/_simple_card.scss";

const SimpleCard = (props) => {
  const {
    key = null,
    active = false,
    selected = false,
    action = () => {}, // noop
    children,
  } = props;

  return (
    <Card
      key={key}
      className={`simple-card ${active ? "active" : ""} ${
        selected ? "selected" : ""
      }`}
      onClick={action}
    >
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default SimpleCard;
