import api from ".";

const getEntities = async () => {
  const fetchApiUrl = `${api.getApiUrl()}client/lookup/entities`;
  return api.get(fetchApiUrl, {}, {});
};

export default {
  getEntities,
};
