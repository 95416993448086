import api from ".";

const getReleaseRules = async (params) => {
    const fetchApiUrl = `${api.getApiUrl()}ReleaseRules`;
    return api.get(fetchApiUrl, params, {});
  };

const updateReleaseRule = async (params) => {
    const fetchApiUrl = `${api.getApiUrl()}ReleaseRules`;
    return api.post(fetchApiUrl, null, params);
  };

  export default {
    getReleaseRules,
    updateReleaseRule,
  }