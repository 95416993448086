import React from "react";

function IconRedo(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M12.0002 24.002C9.88353 24.0025 7.80439 23.4431 5.97373 22.3805C4.14307 21.318 2.62602 19.7901 1.57652 17.9519C0.527006 16.1138 -0.0176242 14.0307 -0.00211937 11.9141C0.0133855 9.79748 0.588474 7.72262 1.6648 5.90003C2.74112 4.07743 4.28039 2.57196 6.12642 1.53636C7.97245 0.500747 10.0596 -0.028149 12.176 0.00332717C14.2924 0.0348034 16.3629 0.625532 18.1773 1.71558C19.9917 2.80563 21.4856 4.35621 22.5072 6.21V0.75C22.5072 0.551088 22.5862 0.360322 22.7269 0.21967C22.8675 0.0790176 23.0583 0 23.2572 0C23.4561 0 23.6469 0.0790176 23.7875 0.21967C23.9282 0.360322 24.0072 0.551088 24.0072 0.75V8.25C24.0072 8.44891 23.9282 8.63968 23.7875 8.78033C23.6469 8.92098 23.4561 9 23.2572 9H15.7572C15.5583 9 15.3675 8.92098 15.2269 8.78033C15.0862 8.63968 15.0072 8.44891 15.0072 8.25C15.0072 8.05109 15.0862 7.86032 15.2269 7.71967C15.3675 7.57902 15.5583 7.5 15.7572 7.5H21.4862C20.6809 5.80222 19.4347 4.35167 17.8777 3.29968C16.3207 2.24769 14.5099 1.63282 12.6342 1.51921C10.7585 1.4056 8.88676 1.79742 7.21415 2.65379C5.54154 3.51016 4.12939 4.7997 3.12503 6.38787C2.12068 7.97603 1.56092 9.80462 1.50417 11.6829C1.44741 13.5611 1.89573 15.4202 2.80236 17.0661C3.70899 18.712 5.04071 20.0844 6.65856 21.0402C8.27641 21.996 10.1211 22.5001 12.0002 22.5C12.1991 22.5 12.3899 22.579 12.5305 22.7197C12.6712 22.8603 12.7502 23.0511 12.7502 23.25C12.7502 23.4489 12.6712 23.6397 12.5305 23.7803C12.3899 23.921 12.1991 24 12.0002 24V24.002Z"
      />
    </svg>
  );
}

export default IconRedo;
