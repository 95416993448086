import React, { useState, useEffect, Children } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../scss/components/layout/_sidebar.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Nav, Collapse, NavLink, NavItem } from "reactstrap";
import { useSelector } from "react-redux";
import { selectSideNavPages } from "../../features/pages/pagesSlice";
import { selectProfileMenuPages } from "../../features/pages/pagesSlice";
import { selectOtherPages } from "../../features/pages/pagesSlice";
import { useAuthContext } from "../../Auth";
import loadable from "@loadable/component";
import { selectCurrentUserInfo } from "../../features/users/userDetailsSlice.js";

const importIcon = (iconName) =>
  loadable(() => import(`../../assets/icons/${iconName}`));

const SideBar = (props) => {
  const t = useTranslation().t;
  const location = useLocation();
  const [width, setWidth] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [caretIcon, setcaretIcon] = useState("");
  const pages = useSelector(selectSideNavPages);
  const ProfileMenuPages = useSelector(selectProfileMenuPages);
  const OtherMenuPages = useSelector(selectOtherPages);
  const authContext = useAuthContext();

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnSidebarIcon = (iconName) => {
    const iconHeight = "14";
    const IconComponent = importIcon(iconName);
    return <IconComponent height={iconHeight} />;
  };

  const updateWindowDimensions = () => {
    if (width !== window.innerWidth) {
      setWidth(window.innerWidth);
    }
  };

  const onCollapse = (title) => {
    // event.preventDefault();
    if (title == "Administration") {
      setCollapsed(!collapsed);
      //classNames += " dropdown-toggle";
    } else setCollapsed(false);
  };
  const { isOpen, excludeLocations, isFromAutohub, toggle } = props;
  const isMobile = width <= 767;

  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });

  const currentUserInfo = useSelector(selectCurrentUserInfo);
  //console.log("currentUserInfo on sidebar " + JSON.stringify(currentUserInfo));
  const UserPermission = currentUserInfo && currentUserInfo.permission;
  //console.log("UserPermission " + UserPermission);
  //console.log("isFromAutohub on sidebar " + isFromAutohub);

  return !(excludeLocations.includes(location.pathname) || isActivate) ? (
    <div
      className={classNames(
        "sidebar",
        { "is-open": isMobile ? !isOpen : isOpen },
        "overflow-auto",
        "d-print-none"
      )}
    >
      <div className="side-menu">
        <h3>{t("Surplus Now")}</h3>

        <Nav vertical className="list-unstyled pb-3">
          {pages &&
            pages.map((p) => {
              //p.groups.length === 0 ||
              return (
                //p.isAutohubMenu == false ||
                (p.isAutohubMenu == true &&
                  isFromAutohub == true &&
                  authContext.isUserInAnyGroups(p.groups)) ||
                  UserPermission == "GA_RER AppRole_Ext_Admin" ||
                  UserPermission == "GA_RER AppRole_Int_Admin" ||
                  p.title != "Administration" ? (
                  <NavItem
                    key={p.id}
                    onClick={(e) => {
                      onCollapse(p.title);
                      if (isMobile) {
                        toggle();
                      }
                    }}
                    className={classNames({ "menu-open": collapsed })}
                  >
                    <NavLink
                      tag={Link}
                      to={p.clientRoute}
                      //className="text-uppercase dropdown-toggle"
                      className={
                        p.isAutohubMenu == true &&
                        isFromAutohub == true &&
                        p.children.length != 0
                          ? "text-uppercase dropdown-toggle"
                          : "text-uppercase"
                      }
                    >
                      <i className="d-inline-block">
                        {returnSidebarIcon(p.icon)}
                      </i>
                      {t(p.title)}
                    </NavLink>

                    {UserPermission === "GA_RER AppRole_Int_Admin"
                      ? p.children &&
                        p.children.map((x) => (
                          <Collapse
                            isOpen={collapsed}
                            navbar
                            className={classNames("items-menu")}
                          >
                            <Nav vertical className="list-unstyled ">
                              <NavItem
                                key={x.id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <NavLink
                                  tag={Link}
                                  to={x.clientRoute}
                                  className="text-uppercase"
                                >
                                  {t(x.title)}
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Collapse>
                        ))
                      : UserPermission === "GA_RER AppRole_Ext_Admin"
                      ? p.children &&
                        p.children.map((x) => {
                          return x.title == "Users & Permissions" ? (
                            <Collapse
                              isOpen={collapsed}
                              navbar
                              className={classNames("items-menu")}
                            >
                              <Nav vertical className="list-unstyled ">
                                <NavItem
                                  key={x.id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <NavLink
                                    tag={Link}
                                    to={"/admin/users"}
                                    className="text-uppercase"
                                  >
                                    {"Users and Permissions"}
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Collapse>
                          ) : (
                            " "
                          );
                        })
                      : ""}
                  </NavItem>
                ) : (
                  ""
                )
              );
            })}
        </Nav>
      </div>
    </div>
  ) : (
    ""
  );
};

export default SideBar;
