import api from ".";

const getPayeeAccounts = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount`;
  return api.get(fetchApiUrl, params, {});
};

const getPayeeAccountById = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount/${id}`;
  return api.get(fetchApiUrl, null, null);
};

const putPayeeAccount = async (params, challengeHistoryId) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount/${params.id}`;
  return api.put(fetchApiUrl, null, params, {
    "x-2fa-challenge": challengeHistoryId,
  });
};

const putPayeeAccountAllocations = async (params, id) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount/allocations/${id}`;
  return api.put(fetchApiUrl, null, params);
};

const postPayeeAccount = async (data, challengeHistoryId) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount`;
  return api.post(fetchApiUrl, null, data, {
    "x-2fa-challenge": challengeHistoryId,
  });
};

const deletePayeeAccount = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}PayeeAccount/${id}`;
  return api.del(fetchApiUrl, null, null);
};

export default {
  getPayeeAccounts,
  getPayeeAccountById,
  putPayeeAccount,
  putPayeeAccountAllocations,
  postPayeeAccount,
  deletePayeeAccount,
};
