import React from "react";

function IconTransferHorizontal(props) {
  let color = props.color ? props.color : "#fff";
  let height = props.height ? props.height : "20";

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      x="0px" 
      y="0px"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}>
 
   <path 
     fill={color}
     d="M20.25,18.75c-0.2,0-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061l1.72-1.72H10.5
     c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h10.939l-1.72-1.72C19.578,12.389,19.5,12.2,19.5,12s0.078-0.389,0.22-0.53
     s0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l3,3c0.07,0.07,0.125,0.152,0.163,0.245c0.003,0.008,0.007,0.017,0.01,0.026
     C23.984,14.822,24,14.911,24,15c0,0.087-0.016,0.174-0.046,0.258c-0.002,0.006-0.004,0.011-0.006,0.017
     c-0.042,0.104-0.097,0.186-0.167,0.256l-3,3C20.639,18.672,20.45,18.75,20.25,18.75z"/>
   <path
     fill={color} 
     d="M3.75,12.75c-0.2,0-0.389-0.078-0.53-0.22l-3-3c-0.07-0.07-0.125-0.152-0.163-0.245C0.053,9.278,0.05,9.269,0.047,9.26
     C0.016,9.174,0,9.088,0,9c0-0.089,0.016-0.176,0.048-0.261C0.05,8.732,0.053,8.725,0.055,8.718c0.04-0.097,0.095-0.179,0.166-0.249
     L3.22,5.47c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22C4.422,5.611,4.5,5.8,4.5,6S4.422,6.389,4.28,6.53l-1.72,1.72
     H13.5c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H2.561l1.72,1.72C4.422,11.611,4.5,11.8,4.5,12s-0.078,0.389-0.22,0.531
     C4.139,12.672,3.95,12.75,3.75,12.75z"/>
 </svg>
  );
}

export default IconTransferHorizontal;
