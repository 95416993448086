import api from ".";

const getBankContacts = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact?`;
  return api.get(fetchApiUrl, params, {});
};

const addBankContact = async (bankContact) => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact`;
  return api.post(fetchApiUrl, null, bankContact);
};

const editBankContact = async (bankContact) => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact/${bankContact.id}`;
  return api.put(fetchApiUrl, null, bankContact);
};

const deleteBankContact = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact/${id}`;
  return api.del(fetchApiUrl, null, null, {});
};

const getBankNames = async () => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact/lookup/banknames`;
  return api.get(fetchApiUrl, null, {});
};

const getBankContactsLookup = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}bankcontact/lookup/contacts`;
  return api.get(fetchApiUrl, params, {});
};

export default {
  getBankContacts,
  addBankContact,
  editBankContact,
  deleteBankContact,
  getBankNames,
  getBankContactsLookup,
};
