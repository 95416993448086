import { createSlice } from "@reduxjs/toolkit";
import clientsApi from "../../api/clients.api";

export const clientsSlice = createSlice({
  name: "clientsStore",
  initialState: {
    isLoading: false,
    clients: [],
    page: 1,
    totalCount: 0,
    showClientModal: false,
    clientReadOnly: true,
    isSaving: false,
    saveError: null,
    client: null,
    isDeleting: false,
    deleteSuccess: false,
    deletingId: null,
    deleteError: "",
    clientNames: [],
    isLoadingClientNames: false,
    captiveNames: [],
    isLoadingCaptiveNames: false,
    captiveCodes: [],
    isLoadingCaptiveCodes: false,
    lastLoadClientParams: {},
  },
  reducers: {
    loadClientsStart: (state) => {
      state.isLoading = true;
    },
    loadClientsSuccess: (state, action) => {
      state.clients = action.payload.pagedDataResponse.data;
      state.totalCount = action.payload.pagedDataResponse.totalCount;
      state.page = action.payload.pagedDataResponse.page + 1;
      state.lastLoadClientParams = action.payload.params;
      state.isLoading = false;
      state.client = null;
    },
    loadClientsFailure: (state) => {
      state.isLoading = false;
    },
    loadClientStart: (state) => {
      state.isLoading = true;
    },
    loadClientSuccess: (state, action) => {
      state.client = action.payload;
      state.isLoading = false;
    },
    loadClientFailure: (state) => {
      state.isLoading = false;
    },
    loadClientNamesStart: (state) => {
      state.isLoadingClientNames = true;
    },
    loadClientNamesSuccess: (state, action) => {
      state.clientNames = action.payload;
      state.isLoadingClientNames = false;
      state.captiveNames = [];
      state.isLoadingCaptiveNames = false;
      state.captiveCodes = [];
      state.isLoadingCaptiveCodes = false;
      state.client = null;
      state.clients = [];
    },
    loadClientNamesFailure: (state) => {
      state.isLoadingClientNames = false;
    },
    loadCaptiveNamesStart: (state) => {
      state.isLoadingCaptiveNames = true;
    },
    loadCaptiveNamesSuccess: (state, action) => {
      state.captiveNames = action.payload;
      state.captiveCodes = [];
      state.isLoadingCaptiveCodes = false;
      state.isLoadingCaptiveNames = false;
      state.client = null;
      state.clients = [];
    },
    loadCaptiveNamesFailure: (state) => {
      state.isLoadingCaptiveNames = false;
    },
    loadCaptiveCodesStart: (state) => {
      state.isLoadingCaptiveCodes = true;
    },
    loadCaptiveCodesSuccess: (state, action) => {
      state.captiveCodes = action.payload;
      state.isLoadingCaptiveCodes = false;
      state.client = null;
      state.clients = [];
    },
    loadCaptiveCodesFailure: (state) => {
      state.isLoadingCaptiveCodes = false;
    },
    resetDropdowns: (state) => {
      state.clientNames = [];
      state.isLoadingClientNames = false;
      state.captiveNames = [];
      state.isLoadingCaptiveNames = false;
      state.captiveCodes = [];
      state.isLoadingCaptiveCodes = false;
      state.client = null;
      state.clients = [];
    },
    resetClient: (state) => {
      state.client = null;
    },
  },
});

// the state is the Root state, so you need to reference the usersStore here
export const selectClients = (state) => state.clientsStore.clients;
export const selectPage = (state) => state.clientsStore.page;
export const selectTotal = (state) => state.clientsStore.totalCount;
export const selectIsLoading = (state) => state.clientsStore.isLoading;
export const selectIsSaving = (state) => state.clientsStore.isSaving;
export const selectClient = (state) => state.clientsStore.client;
export const selectClientReadOnly = (state) =>
  state.clientsStore.clientReadOnly;
export const selectShowClientModal = (state) =>
  state.clientsStore.showClientModal;
export const selectlastLoadClientParams = (state) =>
  state.clientsStore.lastLoadClientParams;
export const selectSaveError = (state) => state.clientsStore.saveError;
export const selectIsDeleting = (state) => state.clientsStore.isDeleting;
export const selectDeleteSuccess = (state) => state.clientsStore.deleteSuccess;
export const selectDeleteError = (state) => state.clientsStore.deleteError;
export const selectClientNames = (state) => state.clientsStore.clientNames;
export const selectIsLoadingClientNames = (state) =>
  state.clientsStore.isLoadingClientNames;
export const selectCaptiveNames = (state) => state.clientsStore.captiveNames;
export const selectIsLoadingCaptiveNames = (state) =>
  state.clientsStore.isLoadingCaptiveNames;
export const selectCaptiveCodes = (state) => state.clientsStore.captiveCodes;
export const selectIsLoadingCaptiveCodes = (state) =>
  state.clientsStore.isLoadingCaptiveCodes;

// Don't expose to rest of the app.
const {
  loadClientsStart,
  loadClientsSuccess,
  loadClientsFailure,
  loadClientStart,
  loadClientSuccess,
  loadClientFailure,
  loadClientNamesStart,
  loadClientNamesSuccess,
  loadClientNamesFailure,
  loadCaptiveNamesStart,
  loadCaptiveNamesSuccess,
  loadCaptiveNamesFailure,
  loadCaptiveCodesStart,
  loadCaptiveCodesSuccess,
  loadCaptiveCodesFailure,
} = clientsSlice.actions;

export const loadClients = (params) => async (dispatch) => {
  dispatch(loadClientsStart());
  try {
    let pagedDataResponse;
    if (params.captiveCode === "") {
      pagedDataResponse = {
        data: [],
        page: 0,
        totalCount: 0,
      };
    } else {
      pagedDataResponse = await clientsApi.getClients(params);
    }
    dispatch(loadClientsSuccess({ pagedDataResponse, params }));
  } catch (err) {
    console.error(err);
    dispatch(loadClientsFailure());
  }
};

export const loadClientNames = (params) => async (dispatch) => {
  dispatch(loadClientNamesStart());
  try {
    let dataResponse = await clientsApi.getClientNames(params);
    dispatch(loadClientNamesSuccess(dataResponse));
  } catch (err) {
    console.error(err);
    dispatch(loadClientNamesFailure());
  }
};

export const loadCaptiveNames = (params) => async (dispatch) => {
  dispatch(loadCaptiveNamesStart());
  try {
    let dataResponse;
    if (params.clientNameId === "") {
      dataResponse = [];
    } else {
      dataResponse = await clientsApi.getCaptiveNames(params);
    }
    dispatch(loadCaptiveNamesSuccess(dataResponse));
  } catch (err) {
    console.error(err);
    dispatch(loadCaptiveNamesFailure());
  }
};

export const loadCaptiveCodes = (params) => async (dispatch) => {
  dispatch(loadCaptiveCodesStart());
  try {
    let dataResponse;
    if (params.captiveName === "") {
      dataResponse = [];
    } else {
      dataResponse = await clientsApi.getCaptiveCodes(params);
    }
    dispatch(loadCaptiveCodesSuccess(dataResponse));
  } catch (err) {
    console.error(err);
    dispatch(loadCaptiveCodesFailure());
  }
};

export const loadClient = (id) => async (dispatch) => {
  dispatch(loadClientStart());
  try {
    let res = await clientsApi.getClient(id);
    dispatch(loadClientSuccess(res));
  } catch (err) {
    console.error(err);
    dispatch(loadClientFailure());
  }
};

export const resetDropdowns = () => (dispatch) => {
  dispatch(clientsSlice.actions.resetDropdowns());
};

export const resetClient = () => (dispatch) => {
  dispatch(clientsSlice.actions.resetClient());
};

export default clientsSlice.reducer;
