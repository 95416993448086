import { createSlice } from "@reduxjs/toolkit";
import moduleApi from "../../api/module.api";

export const moduleConfigSlice = createSlice({
  name: "moduleConfigStore",
  initialState: {
    isLoading: false,
    config: null, // TODO: Consider initially loading these from local storage to improve initial load time
    loadError: null,
  },
  reducers: {
    loadConfigStart: (state) => {
      state.isLoading = true;
    },
    loadConfigSuccess: (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    },
    loadConfigFailure: (state, action) => {
      state.isLoading = false;
      state.loadError = action.payload.message;
    },
  },
});

export const selectIsLoading = (state) => state.moduleConfigStore.isLoading;
export const selectLoadError = (state) => state.moduleConfigStore.loadError;
export const selectModuleConfig = (state) => state.moduleConfigStore.config;

export const getPermissionGroupByName = (config, permissionGroupName) => {
  return config?.permissionGroups?.find(
    (g) => g.oktaName === permissionGroupName
  );
};

// Don't expose to rest of the app.
const {
  loadConfigStart,
  loadConfigSuccess,
  loadConfigFailure,
} = moduleConfigSlice.actions;

export const loadModuleConfig = () => async (dispatch) => {
  dispatch(loadConfigStart());
  try {
    let response = await moduleApi.getConfig();
    dispatch(loadConfigSuccess(response));
  } catch (err) {
    console.error(err);
    dispatch(loadConfigFailure({ message: "Unable to load module config." }));
  }
};

export default moduleConfigSlice.reducer;
