import { DateTime } from "luxon";

class DateUtils {
  fromLocalDateString = (dateString) => {
    return dateString ? DateTime.fromISO(dateString).toJSDate() : null;
  };

  toLocalDateString = (date) => {
    return date ? DateTime.fromJSDate(date).toISODate() : null;
  };

  fromInstantString = (dateString) => {
    return dateString ? DateTime.fromISO(dateString).toJSDate() : null;
  };

  toInstantString = (date) => {
    return date ? DateTime.fromJSDate(date).toUTC().toISO() : null;
  };

  today = () => {
    return DateTime.local().startOf("day").toJSDate();
  };

  todayFormatted = () => {
    return DateTime.local().startOf("day").toFormat(
      "yyyy_MM_dd");
  };

  formatPeriod = (dateString) => {
    return DateTime.fromJSDate(this.fromLocalDateString(dateString)).toFormat(
      "MMMM yyyy"
    );
  };

  formatLocalDate = (dateString) => {
    return DateTime.fromJSDate(this.fromLocalDateString(dateString)).toFormat(
      "yyyy-MM-dd"
    );
  };

  formatLocalDateTime = (dateString, format) => {
    return DateTime.fromJSDate(this.fromInstantString(dateString)).toFormat(      
      format || "MM/dd/yyyy h:mm a ZZZZ"
    );
  };
}

let dateUtils = new DateUtils();

export { dateUtils as DateUtils };
