import React from "react";
import CalendarMonth from "../../components/general/CalendarMonth";
import { isWithinInterval, isWeekend, subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import "../../scss/components/transactions/_cessions_calendar.scss";

const CessionsCalendar = (props) => {
  const { t } = useTranslation();

  const {
    culture,
    calendarType,
    currentDate,
    cessionPeriod,
    remainingTransferDays,
  } = props;

  function inBlackoutPeriod({ date }) {
    const enddate = new Date(props.blackoutPeriod.endDate);
    return (
      //!isWeekend(date) &&  Removed this condition as Blackout Period Includes Weekend as well
      props.blackoutPeriod !== null &&
      isWithinInterval(date, {
        start: new Date(props.blackoutPeriod.startDate),
        end: new Date(enddate.getTime() + 86400000 - 1),
      })
    );
  }

  return (
    <div className="CessionsCalendar col-xxl-12 col-xl-12 col-lg-6 col-md-12 col-sm-12">
      <span className="cessions-tooltip">
        <p>
          {t("Transfer funds period is open.")}
          <i className="transfers-block"></i>
        </p>
        <p>
          {t(
            "Transfer period is closed to eliminate potential issues with funds in transit."
          )}
          <i className="blackout-block"></i>
        </p>
      </span>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <span>
            <strong>{t("Cession period")}</strong>
          </span>
          <span>
            {cessionPeriod.toLocaleString(culture, { month: "long" })}{" "}
            {cessionPeriod.toLocaleString(culture, { year: "numeric" })}
          </span>
        </div>
        <div className="card-body">
          <CalendarMonth
            isDayDisabled={inBlackoutPeriod}
            calendarType={calendarType}
            currentDate={currentDate}
          />

          <div className="d-flex justify-content-end pl-1 pr-1">
            <p>
              <span className="transfer-days">{remainingTransferDays}</span>
            </p>
            <p className="pt-1">
              <span>
                {t(
                  "Business days remaining to transfer funds in the current cession period."
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CessionsCalendar;
