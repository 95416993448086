import React, { useEffect } from "react";
import "../../App.scss";
import Footer from "./Footer";
import { Container } from "reactstrap";
import { Route, withRouter } from "react-router-dom";
import Login from "../login/Login";
import LoginSupport from "../login/LoginSupport";
import TermsAndConditions from "../login/TermsAndConditions";
import classNames from "classnames";
import loadable from "@loadable/component";
import { LoginCallback } from "@okta/okta-react";
import HomePage from "../../pages/HomePage";
import ReleaseRulesPage from "../../pages/ReleaseRulesPage";
import BankStatementsPage from "../../pages/BankStatementsPage";
import CessionStatementsPage from "../../pages/CessionStatementsPage";
import RouteWhenMemberOfAny from "../general/RouteWhenMemberOfAny";

import InviteUserSteps from "../useradmin/InviteUserSteps";
import BankContactList from "../bankcontacts/BankContactList";
import ClientList from "../clients/ClientList";
import { useSelector, useDispatch } from "react-redux";
import { selectModuleConfig } from "../../features/moduleConfig/moduleConfigSlice";
import {
  selectOtherPages,
  selectProfileMenuPages,
  selectSideNavPages,
} from "../../features/pages/pagesSlice";
import { useAuthContext } from "../../Auth";
import { loadUser2FAStatus } from "../../features/users/user2FASlice";

const UserList = loadable(() => import("../useradmin/UserList"));
const UserProfile = loadable(() => import("../useradmin/UserProfile"));
const Support = loadable(() => import("../../pages/SupportPage"));

const ReportPage = loadable(() => import("../../pages/ReportPage"));
const ActivateUser = loadable(() => import("../login/ActivateUser"));

const BankAccounts = loadable(() =>
  import("../../components/bankaccounts/BankAccounts")
);

const TransferRequest = loadable(() =>
  import("../../components/transferrequest/TransferRequest")
);

const TransferRequestDetails = loadable(() =>
  import("../../components/transferrequest/TransferRequestDetails")
);

const SystemActivityPage = loadable(() =>
  import("../../pages/SystemActivityPage")
);

function Content(props) {
  const { sidebarIsOpen, excludeLocations, location } = props;
  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });
  let isReportPage = location.pathname.startsWith("/report");
  const moduleConfig = useSelector(selectModuleConfig);
  const pages = [
    ...useSelector(selectSideNavPages),
    ...useSelector(selectProfileMenuPages),
    ...useSelector(selectOtherPages),
  ];

  const getPermissionGroupsForRoute = (path) => {
    const matchingPage = pages.find((p) => p.clientRoute === path);
    if (matchingPage) {
      return matchingPage.groups;
    } else {
      return moduleConfig.permissionGroups.map((g) => g.oktaName);
    }
  };

  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const { isUserInAnyGroups } = useAuthContext();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(loadUser2FAStatus());
    }
  }, [dispatch, authContext.isAuthenticated]);

  return (
    <Container
      className={classNames(
        "content",
        { "is-open": sidebarIsOpen },
        { "report-page": isReportPage },
        "d-flex",
        "flex-wrap",
        "justify-content-center",
        "p-0",
        "mw-100"
      )}
    >
      <div
        className={
          !(excludeLocations.includes(location.pathname) || isActivate)
            ? isReportPage
              ? "w-100 pl-xs-4 pl-md-5 py-3 inner-content"
              : "w-100 px-xs-4 px-md-5 py-3 inner-content"
            : "w-100 p-0"
        }
      >
        {moduleConfig && (
          <>
            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/banksandletters")}
              exact
              path="/admin/banksandletters"
              render={({ component }) => component()}
              component={BankContactList}
            ></RouteWhenMemberOfAny>
            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/users")}
              exact
              path="/admin/users"
              render={({ component }) => component()}
              component={UserList}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/clientinfo")}
              exact
              path="/admin/clientinfo"
              render={({ component }) => component()}
              component={ClientList}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/users")}
              path="/users/add"
              render={({ component }) => component()}
              component={InviteUserSteps}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={moduleConfig.permissionGroups.map((g) => g.oktaName)}
              exact
              path="/user-profile/:userId?"
              render={({ component }) =>
                component({
                  canAccessBankAccountsPage: isUserInAnyGroups(
                    getPermissionGroupsForRoute("/bankaccounts")
                  ),
                  canAccessCessionStatementsPage: isUserInAnyGroups(
                    getPermissionGroupsForRoute("/cessionstatements")
                  ),
                  canRequestTransfers: isUserInAnyGroups(
                    getPermissionGroupsForRoute("/transferrequest")
                  ),
                  canAccessReleaseRulesPage: isUserInAnyGroups(
                    getPermissionGroupsForRoute("/releaserules")
                  ),
                })
              }
              component={UserProfile}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/users")}
              exact
              path="/support"
              render={({ component }) => component()}
              component={Support}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/")}
              exact
              path="/"
              render={({ component }) => component()}
              component={HomePage}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/bankstatements")}
              exact
              path="/bankstatements"
              render={({ component }) => component()}
              component={BankStatementsPage}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/cessionstatements")}
              exact
              path="/cessionstatements"
              render={({ component }) => component()}
              component={CessionStatementsPage}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/releaserules")}
              exact
              path="/releaserules"
              render={({ component }) => component()}
              component={ReleaseRulesPage}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/bankaccounts")}
              exact
              path="/bankaccounts"
              render={({ component }) => component()}
              component={BankAccounts}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/transferrequest")}
              exact
              path="/transferrequest/:clientId/:cessionPeriodBalanceId"
              render={({ component }) => component()}
              component={TransferRequest}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/transferrequest")}
              exact
              path="/transferrequest/:id"
              render={({ component }) => component()}
              component={TransferRequestDetails}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/admin/systemactivity")}
              exact
              path="/admin/systemactivity"
              render={({ component }) => component()}
              component={SystemActivityPage}
            ></RouteWhenMemberOfAny>

            <RouteWhenMemberOfAny
              groups={getPermissionGroupsForRoute("/report/balances_report")}
              exact
              path="/report/:reportId/:nav?"
              render={({ component }) => component()}
              component={ReportPage}
            ></RouteWhenMemberOfAny>
          </>
        )}
        <Route path="/login" component={Login} />
        {/* <Route path="/login-support" component={LoginSupport} />
        <Route path="/signin-oidc" component={LoginCallback} />
        <Route path="/termsandconditions" component={TermsAndConditions} /> */}
        <Route path="/activate/:activationToken?" component={ActivateUser} />
      </div>

      {/* <div className="w-100 p-0 inner-content">


      </div> */}
      <Footer
        sidebarIsOpen={sidebarIsOpen}
        excludeLocations={excludeLocations}
      />
    </Container>
  );
}

export default withRouter(Content);
