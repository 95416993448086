import React, { useState } from "react";
import { List, arrayMove } from "react-movable";
import "../../scss/components/releaserules/_payee_accounts_list.scss";

const PayeeAccountsList = (props) => {
  const [trustAccounts, setTrustAccounts] = useState(props.trustAccounts);

  const renderItem = (account) => {
    return (
      <div className="">
        <div className="row">
          <div className="col-6">
            <strong>{props.client}</strong>
          </div>
          <div className="col-6">
            {account.bankName}, *{account.trustAccountNumber}
          </div>
        </div>
        {account.payeeAccounts.map((a, index) => (
          <div key={index} className="row">
            <div className="col-6">{a.nickName}</div>
            <div className="col-4">({a.paymentPreference})</div>
            <div className="col-2">{a.allocationPercentage}%</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="PayeeAccountsList">
      <List
        values={trustAccounts}
        onChange={({ oldIndex, newIndex }) => {
          setTrustAccounts(arrayMove(trustAccounts, oldIndex, newIndex));
          props.setAccountsOrder(
            arrayMove(props.trustAccounts, oldIndex, newIndex)
          );
        }}
        renderList={({ children, props, isDragged }) => (
          <ul
            {...props}
            className="payee-account-container"
            style={{
              ...props.style,
              cursor: isDragged ? "grabbing" : undefined,
            }}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => (
          <li
            {...props}
            className="payee-account-item"
            style={{ ...props.style, cursor: isDragged ? "grabbing" : "grab" }}
          >
            {renderItem(value)}
          </li>
        )}
      />
    </div>
  );
};

export default PayeeAccountsList;
