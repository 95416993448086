import React, { useEffect, useState } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import SideBar from "./components/layout/SideBar";
import Content from "./components/layout/Content";
import TopBar from "./components/layout/TopBar";
import ProfileMenu from "./components/layout/ProfileMenu";
import NotificationsMenu from "./components/layout/NotificationsMenu";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import "./App.scss";
import { Route, withRouter, useHistory } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { useDispatch, useSelector } from "react-redux";
import { loadPages } from "./features/pages/pagesSlice";
import { loadModuleConfig } from "./features/moduleConfig/moduleConfigSlice";
import { selectCurrentUserInfo } from "../src/features/users/userDetailsSlice.js";
import { useTranslation } from "react-i18next";
import PageLoader from "../src/components/general/PageLoader";
import Login from "../src/components/login/Login";
import { LoginCallback } from "@okta/okta-react";
import LoginSupport from "../src/components/login/LoginSupport";
import TermsAndConditions from "../src/components/login/TermsAndConditions";

//import TagManager from "react-gtm-module";

function App(props) {
  console.log("App Loading started");
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [profileMenuIsOpen, setProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => setProfileMenuOpen(!profileMenuIsOpen);

  const [notificationsMenuIsOpen, setNotificationsMenuOpen] = useState(false);
  const toggleNotificationsMenu = () =>
    setNotificationsMenuOpen(!notificationsMenuIsOpen);

  const [oktaAuth, setOktaAuth] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const history = useHistory();

  const [isSSOComplete, setSSOComplete] = useState(false);

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    //var originalUri =  "http://localhost:3000/signin-oidc";
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const onAuthRequired = () => {
    props.history.push("/login");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.clear();
    console.log("Use Effect Invoked");
    const oktaConfig = {
      issuer: config.oktaIssuerUrl,
      clientId: config.oktaClientId,
      redirectUri: config.oktaSigninRedirectUrl,
      features: {
        passwordlessAuth: true,
      },
    };
    // setTimeout(() => {
    //   console.log("Set Timeout Triggered");
    //   setIsLoaded(false);
    // }, 3000);
    setOktaAuth(new OktaAuth(oktaConfig));
    dispatch(loadPages());
    dispatch(loadModuleConfig());
    setIsLoaded(true);
  }, [dispatch]);

  const { location } = props;

  const excludeLocations = [
    "/login",
    "/login-support",
    "/activate",
    "/termsandconditions",
  ];
  const isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });

  const { t } = useTranslation();

  let isFromiFrame = false;

  const iFrameURL = document.getElementById("autohubReinsurance")?.src;
  const prevURL = window.location.ancestorOrigins[0];

  //const prevURL = "https://dev-reinsurance.assurantauto.com/";
  //const prevURL = "https://autohubin2-warrantygroup.cs61.force.com/autohub/s/";
  // console.log("prevURL " + prevURL);

  // console.log("Process on App.js " + process.env.NODE_ENV);
  sessionStorage.setItem("currentEnv", process.env.NODE_ENV);
  sessionStorage.setItem("currentPage", location.pathname);

  if (
    prevURL &&
    (prevURL.includes("warrantygroup") ||
      prevURL.includes("twg") ||
      prevURL.includes("sandbox") ||
      prevURL.includes("www.assurantauto.com"))
  ) {
    sessionStorage.setItem("isFromiFrame", "true");
    isFromiFrame = true;
    window.isFromiFrame = true;
    //console.log("sessionStorage " + isFromiFrame);
  } else {
    sessionStorage.setItem("isFromiFrame", "false");
    window.isFromiFrame = false;
    console.log("isFromiFrame " + isFromiFrame);
    window.location = config.AutoHubURL;
  }

  let ifAutohub = sessionStorage.getItem("isFromiFrame");

  const isFullScreen =
    excludeLocations.includes(location.pathname) || isActivate;
  //console.log("isLoaded " + isLoaded + "isSSOComplete " + isSSOComplete);
  if (!isLoaded) {
    return <PageLoader></PageLoader>;
  } else {
    //   if (isSSOComplete) {
    return (
      <div className="App w-100">
        {oktaAuth && (
          // <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={'http://localhost:3000/signin-oidc'}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {!isLoaded && <PageLoader />}
            <AuthProvider>
              {ifAutohub !== "true" && (
                <TopBar
                  toggleSidebar={toggleSidebar}
                  toggleProfileMenu={toggleProfileMenu}
                  excludeLocations={excludeLocations}
                  toggleNotificationsMenu={toggleNotificationsMenu}
                />
              )}

              <ProfileMenu
                toggle={toggleProfileMenu}
                isOpen={profileMenuIsOpen}
                excludeLocations={excludeLocations}
              />
              {notificationsMenuIsOpen && (
                <NotificationsMenu
                  toggle={toggleNotificationsMenu}
                  isOpen={notificationsMenuIsOpen}
                  excludeLocations={excludeLocations}
                />
              )}
              <main
                className={
                  isFullScreen ? "full-screen" : "d-flex align-items-stretch"
                }
              >
                <SideBar
                  toggle={toggleSidebar}
                  isOpen={sidebarIsOpen}
                  excludeLocations={excludeLocations}
                  isFromAutohub={ifAutohub === "true" ? true : false}
                />
                <Content
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                  excludeLocations={excludeLocations}
                />
              </main>
            </AuthProvider>
            <Route path="/login" component={Login} />
            <Route path="/login-support" component={LoginSupport} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
          </Security>
        )}
      </div>
    );
  }
}

export default withRouter(App);
