import React from "react";

function IconArrowRight(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M12.75,23.25c-0.2,0-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061l9.22-9.22H0.75C0.336,12.75,0,12.414,0,12
		s0.336-0.75,0.75-0.75h20.689l-9.22-9.22C12.078,1.889,12,1.7,12,1.5s0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22
		s0.389,0.078,0.53,0.22l10.5,10.5c0.07,0.07,0.125,0.152,0.163,0.245c0.003,0.008,0.007,0.017,0.01,0.026
		C23.984,11.822,24,11.911,24,12c0,0.087-0.016,0.174-0.047,0.258c-0.002,0.006-0.004,0.011-0.006,0.016
		c-0.042,0.104-0.098,0.187-0.168,0.257L13.28,23.03C13.139,23.172,12.95,23.25,12.75,23.25z"
      ></path>
    </svg>
  );
}

export default IconArrowRight;
