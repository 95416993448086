import { createSlice } from "@reduxjs/toolkit";
import pageApi from "../../api/page.api";

export const pagesSlice = createSlice({
  name: "pagesStore",
  initialState: {
    isLoading: false,
    sideNavPages: [], // TODO: Consider initially loading these from local storage to improve initial load time
    otherPages: [],
    profileMenuPages: [],
    loadError: null,
  },
  reducers: {
    loadPagesStart: (state) => {
      state.isLoading = true;
    },
    loadPagesSuccess: (state, action) => {
      state.sideNavPages = action.payload.sideNavPages;
      state.profileMenuPages = action.payload.profileMenuPages;
      state.otherPages = action.payload.otherPages;
      state.isLoading = false;
    },
    loadPagesFailure: (state, action) => {
      state.isLoading = false;
      state.loadError = action.payload.message;
    },
  },
});

export const selectIsLoading = (state) => state.pagesStore.isLoading;
export const selectLoadError = (state) => state.pagesStore.loadError;
export const selectSideNavPages = (state) => state.pagesStore.sideNavPages;
export const selectOtherPages = (state) => state.pagesStore.otherPages;
export const selectProfileMenuPages = (state) =>
  state.pagesStore.profileMenuPages;

// Don't expose to rest of the app.
const {
  loadPagesStart,
  loadPagesSuccess,
  loadPagesFailure,
} = pagesSlice.actions;

export const loadPages = () => async (dispatch) => {
  dispatch(loadPagesStart());
  try {
    let response = await pageApi.getPages();
    dispatch(loadPagesSuccess(response));
  } catch (err) {
    console.error(err);
    dispatch(loadPagesFailure({ message: "Unable to load pages for UI." }));
  }
};

export default pagesSlice.reducer;
