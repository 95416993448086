import IconTrash from "./IconTrash";
import IconPencil from "./IconPencil";
import IconCheckmark from "./IconCheckmark";
import IconAdd from "./IconAdd";
import IconDownload from "./IconDownload";
import IconEye from "./IconEye";
import IconSearch from "./IconSearch";
import IconBuilding from "./IconBuilding";
import IconBank from "./IconBank";
import IconBankTransfer from "./IconBankTransfer";
import IconBankWithArrows from "./IconBankWithArrows";
import IconBanksConnected from "./IconBanksConnected";
import IconBell from "./IconBell";
import IconMobileMenu from "./IconMobileMenu";
import IconVerticalMenu from "./IconVerticalMenu";
import IconToolBox from "./IconToolBox";
import IconTags from "./IconTags";
import IconTransferHorizontal from "./IconTransferHorizontal";
import IconUser from "./IconUser";
import IconEmail from "./IconEmail";
import IconRemove from "./IconRemove";
import IconDocument from "./IconDocument";
import IconDocumentEdit from "./IconDocumentEdit";
import IconCircleArrows from "./IconCircleArrows";
import IconDollar from "./IconDollar";
import IconLock from "./IconLock";
import IconUnlock from "./IconUnlock";
import IconWarning from "./IconWarning";
import IconSortArrowUp from "./IconSortArrowUp";
import IconSortArrowDown from "./IconSortArrowDown";
import IconPrint from "./IconPrint";
import IconPhone from "./IconPhone";
import IconLogout from "./IconLogout";
import IconArrowLeft from "./IconArrowLeft";
import IconSendEmail from "./IconSendEmail";
import IconCheckCircle from "./IconCheckCircle";
import IconSMS from "./IconSMS";
import IconInfo from "./IconInfo";
import IconArrowRight from "./IconArrowRight";
import IconSubtract from "./IconSubtract";
import IconRedo from "./IconRedo";
import IconParaghraphLeftAlign from "./IconParaghraphLeftAlign";
import IconListNumbers from "./IconListNumbers";

export {
  IconTrash,
  IconPencil,
  IconCheckmark,
  IconAdd,
  IconEye,
  IconSearch,
  IconBuilding,
  IconBank,
  IconBankWithArrows,
  IconBanksConnected,
  IconBankTransfer,
  IconBell,
  IconMobileMenu,
  IconVerticalMenu,
  IconToolBox,
  IconTags,
  IconTransferHorizontal,
  IconUser,
  IconEmail,
  IconRemove,
  IconDocument,
  IconDocumentEdit,
  IconCircleArrows,
  IconDollar,
  IconLock,
  IconUnlock,
  IconWarning,
  IconSortArrowUp,
  IconSortArrowDown,
  IconPrint,
  IconPhone,
  IconLogout,
  IconArrowLeft,
  IconSendEmail,
  IconCheckCircle,
  IconSMS,
  IconInfo,
  IconArrowRight,
  IconDownload,
  IconSubtract,
  IconRedo,
  IconParaghraphLeftAlign,
  IconListNumbers,
};
