import { createSlice } from "@reduxjs/toolkit";
import { statusData } from "../../api/index.js";
import userApi from "../../api/user.api";

const {
  initialStatus,
  loadStatus,
  successStatus,
  failureStatus,
  copyStatus,
} = statusData;

export const user2FASlice = createSlice({
  name: "user2FAStore",
  initialState: {
    isEnrolled: false,
    lastDigitsOfPhoneNumber: null,
    loadUser2FAStatus: copyStatus(initialStatus),
  },
  reducers: {
    loadUser2FAStart: (state) => {
      state.loadUser2FAStatus = copyStatus(loadStatus);
    },
    loadUser2FASuccess: (state, action) => {
      state.loadUser2FAStatus = copyStatus(successStatus);
      state.isEnrolled = action.payload.isEnrolled;
      state.lastDigitsOfPhoneNumber = action.payload.lastDigitsOfPhoneNumber;
    },
    loadUser2FAFailure: (state) => {
      state.loadUser2FAStatus = copyStatus(failureStatus);
    },
  },
});

// the state is the Root state, so you need to reference the userDetailsStore here
// SELECT
export const selectUser2FAIsEnrolled = (state) => state.user2FAStore.isEnrolled;
export const selectUser2FALastDigitsOfPhoneNumber = (state) =>
  state.user2FAStore.lastDigitsOfPhoneNumber;
export const selectUser2FAIsVerified = (state) => state.user2FAStore.isVerified;
export const selectLoadUser2FAStatus = (state) =>
  state.user2FAStore.loadUser2FAStatus;
export const selectChallengeHistoryId = (state) =>
  state.user2FAStore.challengeHistoryId;

// Don't expose to rest of the app.
const {
  loadUser2FAStart,
  loadUser2FASuccess,
  loadUser2FAFailure,
} = user2FASlice.actions;

export const loadUser2FAStatus = () => async (dispatch) => {
  dispatch(loadUser2FAStart());
  try {
    const user2FAStatus = await userApi.getUser2FAStatus();
    dispatch(loadUser2FASuccess(user2FAStatus));
  } catch (err) {
    console.error(err);
    dispatch(loadUser2FAFailure());
  }
};

export default user2FASlice.reducer;
