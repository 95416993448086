import React, { createContext, Component, useContext } from "react";
import { withOktaAuth } from "@okta/okta-react";
import api from "./api";
import termsAndConditionsApi from "./api/termsAndConditions.api";
import Login from "./components/login/Login";
import PageLoader from "../src/components/general/PageLoader";

const AuthContext = createContext({
  user: null,
  groups: [],
  isAuthenticated: false,
  isPending: false,
  isUserInAnyGroups: (requiredGroups) => false,
  termsAndConditionsAccepted: false,
});

export default AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext = (Component) => {
  return (props) => (
    <AuthContext.Consumer>
      {(value) => <Component {...props} authContext={value} />}
    </AuthContext.Consumer>
  );
};

const excludeLocations = [
  //"/login",
  "/login-support",
  "/activate",
  "/termsandconditions",
];
const isActivate = excludeLocations.some(function (loc) {
  return window.location.pathname.startsWith(loc);
});

console.log("isActivate " + isActivate);

class AuthController extends Component {
  state = {
    user: null,
    groups: [],
    isAuthenticated: false,
    getAccessToken: () => "",
    isUserInAnyGroups: (requiredGroups) =>
      this.isUserInAnyGroups(requiredGroups),
    termsAndConditionsAccepted: false,
    checkTermsAndConditions: () => this.checkTermsAndConditions(),
  };
  loadingUserDetails = false;

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  isUserInAnyGroups(requiredGroups) {
    var groups = this.state.groups;
    return (
      !requiredGroups ||
      requiredGroups.length === 0 ||
      requiredGroups.filter((x) => groups.includes(x)).length > 0
    );
  }

  async checkTermsAndConditions() {
    const termsAndConditionsAccepted = await termsAndConditionsApi.getTermsAndConditionsStatus();
    termsAndConditionsAccepted.accepted = true;
    this.setState({
      ...this.state,
      termsAndConditionsAccepted:
        termsAndConditionsAccepted && termsAndConditionsAccepted.accepted,
    });
  }

  async checkAuthentication() {
    if (this.props.authState) {
      const isAuthenticated = this.props.authState.isAuthenticated;
      if (
        isAuthenticated !== this.state.isAuthenticated &&
        !this.loadingUserDetails
      ) {
        this.loadingUserDetails = true;
        try {
          const user = await this.props.oktaAuth.getUser();
          api.setAuthFunction(
            this.props.oktaAuth.getAccessToken.bind(this.props.oktaAuth)
          );
          const termsAndConditionsAccepted = await termsAndConditionsApi.getTermsAndConditionsStatus();
          termsAndConditionsAccepted.accepted = true;
          this.setState({
            isAuthenticated,
            user,
            groups: user ? user.groups : [],
            getAccessToken: this.props.oktaAuth.getAccessToken.bind(
              this.props.oktaAuth
            ),
            termsAndConditionsAccepted:
              termsAndConditionsAccepted && termsAndConditionsAccepted.accepted,
          });
          console.log(
            "this.props.oktaAuth.getAccessToken " +
              this.props.oktaAuth.getAccessToken
          );
          //this.props.callBackSetLoader(true);
        } finally {
          this.loadingUserDetails = false;
        }
      }
    }
  }

  render() {
    return this.props.authState && this.props.authState.accessToken ? (
      <AuthContext.Provider value={this.state}>
        {/* {this.props.authState && this.props.authState.accessToken ?  this.props.children :null} */}
        {this.props.children}
      </AuthContext.Provider>
    ) : (
      !isActivate && <Login />
    );
  }
}

export const UserTypes = {
  Internal: "Internal",
  External: "External",
};

export const AuthProvider = withOktaAuth(AuthController);
