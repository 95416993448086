import config from "../config";

let _authFunction = () => {
  return "";
};

const setAuthFunction = (authFunction) => {
  _authFunction = authFunction;
};

const getApiUrl = () => {
  return config.apiUrl;
};

const getApiToken = () => {
  let token = _authFunction();
  return token;
};

const post = async (url, params, body, additionalHeaders) => {
  return sendJson(url, "POST", params, body, additionalHeaders);
};

const put = async (url, params, body, additionalHeaders) => {
  return sendJson(url, "PUT", params, body, additionalHeaders);
};

const del = async (url, params, body, additionalHeaders) => {
  return sendJson(url, "DELETE", params, body, additionalHeaders);
};

const sendJson = async (url, method, params, body, additionalHeaders) => {
  const newUrl = new URL(url);
  if (params) {
    Object.keys(params)
      .filter((key) => params[key] != null)
      .forEach((key) => newUrl.searchParams.append(key, params[key]));
  }

  const headers = {
    ...additionalHeaders,
    authorization: `Bearer ${getApiToken()}`,
    "content-type": "application/json",
  };
  const response = await fetch(newUrl, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  });
  const contentType = response.headers.get("content-type");
  if (response.ok) {
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return await response.json();
    } else {
      return await response.text();
    }
  }
  let error = new Error("network error");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    let errorBody = await response.json();
    error.response = errorBody;
  }
  throw error;
};

const get = async (url, params, additionalHeaders) => {
  const newUrl = new URL(url);
  if (params) {
    Object.keys(params)
      .filter((key) => params[key] != null)
      .forEach((key) => newUrl.searchParams.append(key, params[key]));
  }

  const headers = {
    ...additionalHeaders,
    authorization: `Bearer ${getApiToken()}`,
  };

  const response = await fetch(newUrl, {
    method: "GET",
    headers: headers,
  });
  const contentType = response.headers.get("content-type");
  if (response.ok) {
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return await response.json();
    } else {
      return await response.text();
    }
  }
  let error = new Error("network error");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    let errorBody = await response.json();
    error.response = errorBody;
  }
  throw error;
};

const download = async (url, params, mimeType, fileName, additionalHeaders) => {
  const newUrl = new URL(url);
  if (params) {
    Object.keys(params)
      .filter((key) => params[key] != null)
      .forEach((key) => newUrl.searchParams.append(key, params[key]));
  }

  const headers = {
    ...additionalHeaders,
    authorization: `Bearer ${getApiToken()}`,
    accept: mimeType,
  };

  const response = await fetch(newUrl, {
    method: "GET",
    headers: headers,
  });
  const contentType = response.headers.get("content-type");

  if (response.ok) {
    const blob = await response.blob();
    let downloadFileName = fileName;
    if (!downloadFileName) {
      const contentDisposition = response.headers.get("content-disposition");
      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          downloadFileName = matches[1].replace(/['"]/g, "");
        }
      }
    }
    const anchor = document.createElement("a");
    anchor.download = downloadFileName;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.dataset.downloadurl = [mimeType, anchor.download, anchor.href].join(
      ":"
    );
    anchor.click();
    return;
  }
  let error = new Error("network error");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    let errorBody = await response.json();
    error.response = errorBody;
  }
  throw error;
};

const abortableGet = (url, params, additionalHeaders) => {
  const controller = new AbortController();
  const newUrl = new URL(url);
  if (params) {
    Object.keys(params)
      .filter((key) => params[key] != null)
      .forEach((key) => newUrl.searchParams.append(key, params[key]));
  }

  const headers = {
    ...additionalHeaders,
    authorization: `Bearer ${getApiToken()}`,
  };
  const promise = fetch(newUrl, {
    signal: controller.signal,
    method: "GET",
    headers: headers,
  });

  return {
    promise,
    controller,
  };
};

const suppressApiErrors = () => {};

const initialStatus = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
};

const loadStatus = {
  ...initialStatus,
  isLoading: true,
};

const successStatus = {
  ...initialStatus,
  isSuccess: true,
};

const failureStatus = {
  ...initialStatus,
  isFailure: true,
};

const copyStatus = (status) => ({ ...status });

export const statusData = {
  initialStatus,
  loadStatus,
  successStatus,
  failureStatus,
  copyStatus,
};

export default {
  setAuthFunction,
  getApiUrl,
  get,
  download,
  post,
  put,
  del,
  suppressApiErrors,
  abortableGet,
  statusData,
};
