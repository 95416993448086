import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable, {
  formatMoney,
  formatPeriod,
  generateColumn,
  generateActionsColumn,
} from "../general/DataTable";
import { IconTransferHorizontal } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";

import { useAuthContext } from "../../Auth";
import cessionApi from "../../api/cession.api";
import { useHistory } from "react-router";
import { selectModuleConfig } from "../../features/moduleConfig/moduleConfigSlice";
import { selectCurrentUserInfo } from "../../features/users/userDetailsSlice.js";
import { loadUserInfo } from "../../features/users/userDetailsSlice";
import AlertBox from "../general/AlertBox";

window.dataLayer = window.dataLayer || [];

let PageLocation = window.location.origin + "/autoHub/excess_reserve";
let PageTitle = "surplus now ";

// console.log("location.pathname " + window.location.pathname);
// console.log("Page Name = " + "transactions");
// console.log("Page Location = " + PageLocation + "/" + "transactions");
// console.log("Page Title = " + PageTitle + "transactions");

window.dataLayer.push({
  event: "page_view",
  page_data: {
    breadcrumb: "",
    page_category: "",
    page_category2: "",
    page_name: "transactions",
    page_location: PageLocation + "/" + "transactions",
    page_referrer: "",
    page_title: PageTitle + "transactions",
    module: "surplus now",
    user_login_state: "authenticated",
    dealer_name: "",
  },
});

const AvailableBalances = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorText, setErrorText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const moduleConfig = useSelector(selectModuleConfig);

  const currentUserInfo = useSelector(selectCurrentUserInfo);

  const [showClientAdminAlertBox, setClientAdminFailureAlertBox] =
    useState(false);

  const [isBlackoutPeriod, setIsBlackoutPeriod] = useState(false);
  // const isClientAdminCountMoreThanTwo =
  // currentUserInfo && currentUserInfo.isClientAdminCountMoreThanTwo;

  const makeTransfer = (row) => {
    console.log(
      "row.isClientAdminCountMoreThanTwo in makeTransfer " +
        row.isClientAdminCountMoreThanTwo
    );
    if (!row.isClientAdminCountMoreThanTwo) {
      setClientAdminFailureAlertBox(true);
      return;
    }
    history.push(`/transferrequest/${row.clientId}/${row.id}`);
  };

  const closeClientAdminAlertBox = () => {
    setClientAdminFailureAlertBox(false);
    //history.goBack();
  };

  const formatterActions = (_cell, row) => {
    //const disabledClass = props.remainingTransferDays <= 0 ? "action-disabled" : "";
    let disabledClass = row.isInBlackoutPeriod == true ? "action-disabled" : "";
    return (
      <div className={`icon-action ${disabledClass}`}>
        <i onClick={() => makeTransfer(row)}>
          <IconTransferHorizontal />
        </i>
      </div>
    );
  };

  const downloadAction = async () => {
    await cessionApi.downloadAvailableBalances({
      ...searchParams,
      page: 0,
      pageSize: 1500,
    });
  };

  const columns = [
    generateColumn(
      "cessionPeriod",
      t("Cession Period"),
      true,
      { minWdith: "155px", maxWidth: "155px" },
      formatPeriod
    ),
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "265px",
      maxWidth: "265px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn(
      "endingBalance",
      t("Available Balance"),
      false,
      {},
      formatMoney
    ),
    generateActionsColumn(formatterActions),
  ];

  const [page, setPage] = useState({ data: [], page: 0, totalCount: 0 });

  const [searchParams, setSearchParams] = useState({
    mostRecentPeriod: true,
    clientName: "",
    captiveName: "",
    captiveCode: "",
    searchText: null,
    page: 1,
    pageSize: 6,
    sortBy: "clientName",
    descending: true,
  });

  const handleSearch = (e) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: e.target.value,
    });
  };

  const onTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      ...searchParams,
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  const handleDropdownSelection = (id, value) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const dispatch = useDispatch();
  const authContext = useAuthContext();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setIsLoadingData(true);
      dispatch(async () => {
        try {
          const result = await cessionApi.getPeriodsSummary({
            ...searchParams,
            page: searchParams.page - 1,
          });

          if (result.totalCount > 0) {
            setIsBlackoutPeriod(
              JSON.stringify(result.data[0].isInBlackoutPeriod)
            );

            setPage(result);
          } else {
            setPage({ data: [], page: 0, totalCount: 0 });
          }
        } catch (e) {
          setErrorText(t("Error loading available balances data."));
        } finally {
          setIsLoadingData(false);
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, searchParams, t]);

  useEffect(() => {
    console.log("AuthContext UseEffect " + JSON.stringify(authContext.user));
    if (authContext.user.sub) {
      dispatch(
        loadUserInfo(authContext.user.sub, authContext.user, moduleConfig)
      );
    }
  }, [authContext, dispatch, moduleConfig, authContext.user.sub]); //authContext.user.sub

  const dropdowns = [
    {
      id: "clientNameId",
      title: t("Client"),
      values: props.clientsNames.map((c) => {
        return { id: c.id, name: c.name };
      }),
      handleSelected: handleDropdownSelection,
    },
    {
      id: "captiveName",
      title: t("Captive Name"),
      values: props.captivesNames.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "265px",
    },
    {
      id: "captiveCode",
      title: t("Captive Code"),
      values: props.captivesCodes.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
    },
  ];

  const tooltipContent = (
    <div>
      <p>{t("Available Balance")}</p>
      <p>
        {t(
          "The balance shown is the amount available to be transferred as per the current period cession statement."
        )}
      </p>
      <p>
        {t(
          '"Cession in progress" means that the new cession is in the progress of being created. Once completed, the balance will be reflected here.'
        )}
      </p>
    </div>
  );

  return (
    <>
      {showClientAdminAlertBox && (
        <AlertBox
          title={t("Admin Required")}
          text={t(
            "As a fraud prevention measure at least one additional admin from your company must be added by the Assurant Reinsurance team before you can transfer funds or make account changes."
          )}
          onClose={closeClientAdminAlertBox}
          isOpen={showClientAdminAlertBox}
          isSuccess={false}
          alertIcon="IconWarning"
          isClass={true}
          buttonText={t("Contact support")}
        />
      )}
      <div className="AvailableBalancesList  col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <DataTable
          errorText={errorText}
          columns={columns}
          data={page.data}
          defaultSorted={[{ dataField: "clientName", order: "asc" }]}
          isLoading={false}
          defaultSortField="clientName"
          totalSize={page.totalCount}
          pageSize={searchParams.pageSize}
          page={searchParams.page}
          onTableChange={onTableChange}
          title={t("Available Balances Per Current Ceding Statement")}
          handleSearch={handleSearch}
          dropdowns={dropdowns}
          downloadAction={downloadAction}
          tooltipContent={tooltipContent}
          noDataIndication={
            isLoadingData
              ? t("Loading data...")
              : t("No data for the selected Captive.")
          }
        />
      </div>
    </>
  );
};

export default AvailableBalances;
