
import React from "react";
import { IconDownload } from "../../assets/icons";
import "../../scss/components/statements/_documents_download.scss";

const DocumentsDownload = (props) => {
    return (
        <div className="DocumentsDownload">
          <div className="row">
            <i className="icon"><IconDownload /></i>
            <span className="text-link" onClick={props.onClick}> <strong>{props.selectedDocuments.length}</strong> {props.text} </span>
          </div>
          {props.selectedDocuments.map((d, idx) => {
            return(<div className="row" key={idx}>{d}</div>);
            }
          )}
        </div>  
      );
}

export default DocumentsDownload;