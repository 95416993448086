import api from ".";

const inviteUsers = (users, permissionGroup, clients) => {
  const fetchApiUrl = `${api.getApiUrl()}users`;

  return api.post(fetchApiUrl, null, {
    users,
    permissionGroup,
    clients,
  });
};

const resendUserInvite = (userId) => {
  const fetchApiUrl = `${api.getApiUrl()}users/resendInvitation`;

  return api.post(fetchApiUrl, null, { userId });
};

const checkEmail = (email) => {
  const encodeEmail = encodeURIComponent(email);
  const fetchApiUrl = `${api.getApiUrl()}users?pageSize=1&searchText=${encodeEmail}`;
  return api.get(fetchApiUrl);
};

const loadAssurantUsers = (searchValue) => {
  const fetchApiUrl = `${api.getApiUrl()}users/internal?search=${encodeURI(
    searchValue
  )}`;
  return api.abortableGet(fetchApiUrl);
};

const loadClients = () => {
  const fetchApiUrl = `${api.getApiUrl()}users/clients`;
  return api.get(fetchApiUrl);
};

export default {
  inviteUsers,
  resendUserInvite,
  checkEmail,
  loadAssurantUsers,
  loadClients,
};
