import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
import { debounce } from "throttle-debounce";

import "../../scss/useradmin/_invite_user.scss";
import { IconSearch } from "../../assets/icons";

import {
  selectUsers,
  addUser,
  removeUser,
  loadAssurantUsers,
} from "../../features/inviteUsers/inviteUsersSlice";
import UserDetailsCard from "./UserDetailsCard";

const SearchUsers = () => {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const t = useTranslation().t;

  const [searchData, setSearchData] = useState({
    users: [],
    searchValue: "",
    searchCount: 0,
    loading: false,
    controller: null,
    promise: null,
  });

  const isUserSelected = (user) => {
    return users.findIndex((u) => u.id === user.id) > -1;
  };

  const selectUser = (user) => {
    const isSelected = isUserSelected(user);

    if (isSelected) {
      dispatch(removeUser(user));
    } else {
      dispatch(addUser({ ...user, immutable: true }));
    }

    setSearchData({
      users: [],
      searchValue: "",
      searchCount: 0,
      loading: false,
      controller: null,
      promise: null,
    });

    document.getElementById("clear-search-input").value = "";
  };

  const renderUsersList = (users) => {
    return users
      ? users.map((user) => {
          const isSelected = isUserSelected(user);

          return (
            <UserDetailsCard
              key={user.id}
              name={`${user.firstName} ${user.lastName}`}
              email={user.email}
              titleDepartment={`${!!user.jobTitle ? user.jobTitle : ""}${
                !!user.jobTitle && !!user.companyName ? " / " : ""
              }${!!user.companyName ? user.companyName : ""}`}
              active={isSelected}
              action={() => selectUser(user)}
              actionText={isSelected ? t("Remove") : t("Add")}
            />
          );
        })
      : null;
  };

  const debouncedLoadUserList = debounce(500, async (value) => {
    const newSearchData = await dispatch(
      loadAssurantUsers({
        ...searchData,
        searchValue: value,
      })
    );

    setSearchData(newSearchData);

    if (newSearchData.promise) {
      const response = await newSearchData.promise;

      if (response.ok) {
        const result = await response.json();

        setSearchData({
          ...newSearchData,
          users: result.users,
          loading: false,
          promise: null,
          controller: null,
        });
      } else {
        setSearchData({
          ...newSearchData,
          users: [],
          searchCount: 0,
          loading: false,
          promise: null,
          controller: null,
        });
      }
    }
  });

  const handleSearch = (e) => {
    e.persist();

    debouncedLoadUserList(e.target.value);
  };

  return (
    <div>
      <div className="d-flex">
        <FormGroup className="d-flex justify-content-end mb-0">
          <Input
            type="search"
            placeholder={t("Search...")}
            name="search"
            onChange={handleSearch}
            id="clear-search-input"
          />
          <div className="form-icon" onClick={handleSearch}>
            <i>
              <IconSearch />
            </i>
          </div>
        </FormGroup>
      </div>
      <div className="scroll-box">
        {searchData.loading ? (
          <div className="pt-2 text-center">
            <UserDetailsCard key={"skeleton-card"} asSkeleton={true} />
          </div>
        ) : searchData.users?.length === 0 && !!searchData.searchValue ? (
          <div className="pt-2 text-center">
            {t("No matching users found.")}
          </div>
        ) : (
          renderUsersList(searchData.users)
        )}
      </div>
    </div>
  );
};

export default SearchUsers;
