import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable, {
  generateColumn,
  generateActionsColumn,
  formatAccount,
} from "../general/DataTable";
import ToggleButton from "../general/ToggleButton";
import {
  IconPencil,
  IconUnlock,
  IconLock,
  IconAdd,
  IconSubtract,
  IconBankWithArrows,
} from "../../assets/icons";
import BootstrapTable from "react-bootstrap-table-next";
import { debounce } from "throttle-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../Auth";
import relaseRulesApi from "../../api/releaseRules.api";
import ReleaseRulesWizard from "../releaserules/ReleaseRulesWizard";
import releaseRulesApi from "../../api/releaseRules.api";
import { selectCurrentUserInfo } from "../../features/users/userDetailsSlice.js";

window.dataLayer = window.dataLayer || [];

let PageLocation = window.location.origin + "/autoHub/excess_reserve";
let PageTitle = "surplus now ";

// console.log("location.pathname " + window.location.pathname);
// console.log("Page Name = " + "release rules");
// console.log("Page Location = " + PageLocation + "/" + "release_rules");
// console.log("Page Title = " + PageTitle + "release rules");

window.dataLayer.push({
  event: "page_view",
  page_data: {
    breadcrumb: "",
    page_category: "",
    page_category2: "",
    page_name: "release rules",
    page_location: PageLocation + "/" + "release_rules",
    page_referrer: "",
    page_title: PageTitle + "release rules",
    module: "surplus now",
    user_login_state: "authenticated",
    dealer_name: "",
  },
});

const ReleaseRulesList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const [dataPage, setDataPage] = useState({
    data: [],
    page: 0,
    totalCount: 0,
  });
  const [isLoadingData, setIsLoadingData] = useState(false);

  const currentUserInfo = useSelector(selectCurrentUserInfo);
  // console.log(
  //   "currentUserInfo on Release Rules Page " + JSON.stringify(currentUserInfo)
  // );

  const tooltipContent = (
    <div>
      <p>{t("Release Rules")}</p>
      <p>
        {t(
          "In this area you can decide if you want releases from your trust accounts to be processed manually or automatically."
        )}
      </p>
      <p>
        {t(
          "In order for funds to be auto-released, the account availability must be 'unlocked'."
        )}
        <IconUnlock height={14} color={"green"} />
      </p>
      <p>
        {t(
          "If the auto-release status is 'off', releases will be only processed with manual requests."
        )}
      </p>
      <p>
        {t(
          "If auto-release is 'on', you decide the frequency and the system will release funds automatically."
        )}
      </p>
      <p>
        {t(
          "IMPORTANT: If you have not yet added payee accounts and set your allocations under 'Bank Accounts', this will need to be completed before you can set up an auto release."
        )}
      </p>
    </div>
  );

  const [errorText, setErrorText] = useState("");
  const [searchParams, setSearchParams] = useState({
    clientNameId: null,
    captiveName: "",
    searchText: null,
    page: 1,
    pageSize: 6,
    sortBy: "",
    descending: true,
  });

  const fetchReleaseRulesData = async () => {
    setIsLoadingData(true);
    try {
      const result = await relaseRulesApi.getReleaseRules({
        ...searchParams,
        page: searchParams.page - 1,
      });
      setDataPage(result);
      setErrorText("");
    } catch (e) {
      setErrorText(
        t("Error loading list of release rules. Please, try again later.")
      );
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      dispatch(async () => {
        await fetchReleaseRulesData();
      });
    }
  }, [authContext.isAuthenticated, searchParams]);

  const handleEditReleaseRule = (e, row) => {
    e.stopPropagation();
    setSelectedRule(row);
    setWizardStep(1);
    setShowWizard(true);
  };

  const accountLockedFormatter = (cell) => {
    console.log("Cell on Release Rules " + cell);
    if (cell) {
      return <IconLock color={"black"} />;
    } else {
      return <IconUnlock color={"green"} />;
    }
  };

  const switchToManual = (row) => {
    const newRule = {
      clientId: row.id,
      schedule: "Manual",
      trustAccountOrder: row.trustAccounts.map((tra) => {
        return { trustAccountId: tra.id, order: null };
      }),
    };

    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          await releaseRulesApi.updateReleaseRule(newRule);
          await fetchReleaseRulesData();
        } catch (e) {
          setErrorText(
            t("Error updating release rule settings. Please, try again later.")
          );
        } finally {
          setWizardStep(0);
          setShowWizard(false);
          setSelectedRule(null);
        }
      });
    }
  };

  const switchToAutomatic = (row) => {
    setWizardStep(0);
    setSelectedRule(row);
    setShowWizard(true);
  };

  const persistAutoRelease = (row, releaseConfig) => {
    const newRule = {
      clientId: row.id,
      schedule: releaseConfig.schedule || row.autoRelease,
      trustAccountOrder: releaseConfig.accountsOrder.map((acc, index) => {
        return { trustAccountId: acc.id, order: index };
      }),
    };

    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          await releaseRulesApi.updateReleaseRule(newRule);
          await fetchReleaseRulesData();
        } catch (e) {
          setErrorText(
            t("Error updating release rule settings. Please, try again later.")
          );
        } finally {
          handleCloseWizard();
        }
      });
    }
  };

  const handleToggleChange = (newState, row) => {
    if (newState) {
      switchToAutomatic(row);
      row.autoRelease = null;
    } else {
      switchToManual(row);
    }
  };

  const hasNoPayeeAccountsConfigured = (row) => {
    return (
      row.trustAccounts.some(
        (tra) =>
          tra.payeeAccounts.length > 0 &&
          tra.payeeAccounts.some((pa) => pa.allocationPercentage > 0)
      ) === false
    );
  };

  const statusFormatter = (cell, row) => {
    return (
      <span
        className={
          hasNoPayeeAccountsConfigured(row) ||
          !row.isClientAdminCountMoreThanTwo
            ? "action-disabled"
            : ""
        }
      >
        <ToggleButton
          toggleActive={cell !== "Manual"}
          handleToggleChange={(newState) => handleToggleChange(newState, row)}
        />
      </span>
    );
  };

  const scheduleFormatter = (cell, row) => {
    return (
      <span>
        <IconBankWithArrows
          arrowsColor={row.autoRelease === "Manual" ? "" : "green"}
        />{" "}
        {row.autoRelease}
      </span>
    );
  };

  const formatterActions = (_cell, row) => {
    const disabledClass =
      row.autoRelease === "Manual" || !row.isClientAdminCountMoreThanTwo
        ? "action-disabled"
        : "";
    return (
      <div className={`icon-action ${disabledClass}`}>
        <i onClick={(e) => handleEditReleaseRule(e, row)}>
          <IconPencil height={20} />
        </i>
      </div>
    );
  };

  const columns = [
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "185px",
      maxWidth: "185px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn(
      "autoRelease",
      t("Auto Release"),
      false,
      {},
      statusFormatter
    ),
    generateColumn("", t("Schedule"), false, {}, scheduleFormatter),
    generateActionsColumn(formatterActions),
  ];

  const handleDropdownSelection = (id, value) => {
    let newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams[id] = value;
    setSearchParams(newSearchParams);
  };

  const dropdowns = [
    {
      id: "clientNameId",
      title: t("Client"),
      values: props.clientsNames.map((c) => {
        return { id: c.id, name: c.name };
      }),
      handleSelected: handleDropdownSelection,
      width: "185px",
    },
    {
      id: "captiveName",
      title: t("Captive Name"),
      values: props.captivesNames.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "185px",
    },
    {
      id: "captiveCode",
      title: t("Captive Code"),
      values: props.captivesCodes.map((c) => {
        return { id: c, name: c };
      }),
      handleSelected: handleDropdownSelection,
      width: "185px",
    },
  ];

  const debouncedSearch = debounce(500, (searchValue) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      searchText: searchValue,
    });
  });

  const handleSearch = (e) => {
    e.persist();
    e.preventDefault();
    debouncedSearch(e.target.value);
  };

  const onTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setSearchParams({
      ...searchParams,
      page: page,
      pageSize: sizePerPage,
      sortBy: sortField,
      descending: sortOrder !== "asc",
    });
  };

  function accountFormatter(_, row) {
    return (
      <span
        className={
          row.payeeAccounts.length === 0 ||
          row.payeeAccounts.some((pa) => pa.allocationPercentage > 0) === false
            ? "action-disabled"
            : ""
        }
      >
        {formatAccount(row.bankName, row.trustAccountNumber)}
      </span>
    );
  }

  function autoReleasePriorityFormatter(cell, _) {
    return `${cell === null ? t("N/A") : cell + 1}`;
  }

  const expandedColumns = [
    generateColumn("", "", false, { minWidth: "20px", maxWidth: "20px" }),
    generateColumn("", "", false, { minWidth: "120px", maxWidth: "120px" }),
    generateColumn(
      "trustAccountNumber",
      t("Account #"),
      false,
      { minWidth: "140px", maxWidth: "140px" },
      accountFormatter
    ),
    generateColumn(
      "isLocked",
      t("Available"),
      false,
      {},
      accountLockedFormatter
    ),
    generateColumn(
      "autoReleasePriority",
      t("Auto release"),
      false,
      {},
      autoReleasePriorityFormatter
    ),
    generateColumn("", "", false, {}),
    generateColumn("", "", false, {}),
    generateActionsColumn("", "", false, {}),
  ];

  const expandRow = {
    renderer: function RowDetails(row) {
      return (
        <div className="AccountsList">
          <BootstrapTable
            remote
            keyField={"id"}
            data={row.trustAccounts}
            columns={expandedColumns}
            hover
            noDataIndication={t(
              "No trust accounts associated with the selected captive."
            )}
            bordered={false}
            hideSizePerPage={true}
          />
        </div>
      );
    },
    showExpandColumn: true,
    expandHeaderColumnRenderer: function RowExpandHeaderColumn() {
      return <></>;
    },
    expandColumnRenderer: function RowExpandColumn({ expanded }) {
      if (expanded) {
        return <IconSubtract height="11"></IconSubtract>;
      }
      return <IconAdd height="11"></IconAdd>;
    },
  };

  const [wizardStep, setWizardStep] = useState(0);
  const [showWizard, setShowWizard] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);

  const handleCloseWizard = async () => {
    setWizardStep(0);
    setShowWizard(false);
    setSelectedRule(null);

    await fetchReleaseRulesData();
  };

  const handleContinueWizard = () => {
    setWizardStep(wizardStep + 1);
  };

  return (
    <div className="ReleaseRulesList">
      {errorText && <div className="text-center text-danger">{errorText}</div>}
      {showWizard && (
        <ReleaseRulesWizard
          wizardStep={wizardStep}
          showWizard={showWizard}
          handleCloseWizard={handleCloseWizard}
          handleContinueWizard={handleContinueWizard}
          selectedRule={selectedRule}
          persistAutoRelease={persistAutoRelease}
          autoReleaseAccounts={selectedRule?.trustAccounts.filter(
            (tra) =>
              tra.payeeAccounts.length > 0 &&
              tra.payeeAccounts.some((pa) => pa.allocationPercentage > 0)
          )}
        />
      )}
      <DataTable
        columns={columns}
        data={dataPage.data}
        keyField={"id"}
        defaultSorted={[{ dataField: "id", order: "desc" }]}
        isLoading={false}
        defaultSortField="id"
        totalSize={dataPage.totalCount}
        pageSize={searchParams.pageSize}
        page={searchParams.page}
        onTableChange={onTableChange}
        title={t("Release Rules")}
        handleSearch={handleSearch}
        dropdowns={dropdowns}
        expandRow={expandRow}
        tooltipContent={tooltipContent}
        noDataIndication={
          isLoadingData
            ? t("Loading data...")
            : t("No data for the selected period.")
        }
      />
    </div>
  );
};

export default ReleaseRulesList;
