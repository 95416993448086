import React from "react";

function IconDocumentEdit(props) {
  let color = props.color ? props.color : "#fff";
  let height = props.height ? props.height : "30";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M11.251,24c-0.2,0-0.389-0.078-0.53-0.22c-0.178-0.177-0.255-0.431-0.205-0.677l0.75-3.75
        c0.029-0.146,0.1-0.279,0.205-0.384l7.63-7.63c0.54-0.541,1.26-0.839,2.026-0.839c0.763,0,1.48,0.296,2.02,0.832
        c1.09,1.119,1.099,2.89,0.036,4.045c-0.003,0.004-0.018,0.019-0.021,0.022l-7.63,7.63c-0.104,0.105-0.238,0.176-0.384,0.205
        l-3.749,0.75C11.349,23.995,11.3,24,11.251,24z M12.207,22.294l2.424-0.485l7.463-7.463c0.499-0.548,0.504-1.426-0.001-1.945
        c-0.003-0.003-0.018-0.018-0.021-0.022C21.832,12.14,21.491,12,21.127,12l-0.001-0.375l0,0.375c-0.364,0-0.707,0.142-0.964,0.4
        l-7.47,7.47L12.207,22.294z"
      />
      <path
        fill={color}
        d="M2.251,21c-1.241,0-2.25-1.009-2.25-2.25V2.25C0.001,1.009,1.011,0,2.251,0H12.88c0.601,0,1.165,0.234,1.59,0.658
        l2.872,2.872c0.425,0.425,0.659,0.99,0.659,1.59V8.25c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75V5.121
        c0-0.197-0.08-0.39-0.219-0.53L13.41,1.719C13.268,1.578,13.08,1.5,12.88,1.5H2.251c-0.414,0-0.75,0.336-0.75,0.75v16.5
        c0,0.414,0.336,0.75,0.75,0.75h6c0.414,0,0.75,0.336,0.75,0.75S8.665,21,8.251,21H2.251z"
      />
    </svg>
  );
}

export default IconDocumentEdit;
