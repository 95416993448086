import api from ".";

const getPages = async () => {
  const fetchApiUrl = `${api.getApiUrl()}page`;
  return api.get(fetchApiUrl, null, {});
};

export default {
  getPages,
};
