import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IconCheckmark } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { reset } from "../../features/inviteUsers/inviteUsersSlice";
import { IconRemove } from "../../assets/icons";

const InviteSentModal = (props) => {
  const { isOpen, toggle } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation().t;

  return (
    <div>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalBody className="px-5 py-4">
          <div onClick={toggle} className="close-button text-center py-3">
            <i>
              <IconRemove />
            </i>
          </div>
          <div className="text-center d-flex flex-wrap align-items-center pb-3">
            <div className="w-100 align-self-end checkmark pt-3">
              <i>
                <IconCheckmark color="#40b93C" />
              </i>
            </div>
            <h3 className="w-100 font-weight-bold fade-in mt-3 pb-3">
              {t("Invite Sent")}
            </h3>
            <p className="w-100 align-self-start fade-in">
              {t("An email has been sent to the users you invited")}
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-2 pl-4 pr-4">
            <div className="p-1">
              <Button
                className="mb-2"
                color="primary"
                onClick={() => {
                  toggle();
                  dispatch(reset());
                  history.push("/users/add/info");
                }}
              >
                {t("Add Another User")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InviteSentModal;
