import { createSlice } from "@reduxjs/toolkit";
import emailBodyApi from "../../api/emailBody.api";

export const emailBodiesSlice = createSlice({
  name: "emailBodiesStore",
  initialState: {
    isLoading: false,
    emailBodies: [],
    isSaving: false,
    saveError: null,
  },
  reducers: {
    loadEmailBodiesStart: (state) => {
      state.isLoading = true;
    },
    loadEmailBodiesSuccess: (state, action) => {
      state.emailBodies = action.payload.data;
      state.isLoading = false;
    },
    loadEmailBodiesFailure: (state) => {
      state.isLoading = false;
    },
    saveEmailBodyStart: (state) => {
      state.isSaving = true;
      state.saveError = null;
    },
    saveEmailBodySuccess: (state, action) => {
      state.emailBodies = [
        ...state.emailBodies.filter((body) => body.id !== action.payload.id),
        action.payload,
      ];
      state.isSaving = false;
      state.saveError = null;
    },
    saveEmailBodyFailure: (state, action) => {
      state.isSaving = false;
      state.saveError = action.payload.message;
    },
  },
});

// the state is the Root state, so you need to reference the emailBodiesStore here
export const selectEmailBodies = (state) => state.emailBodiesStore.emailBodies;
export const selectIsLoading = (state) => state.emailBodiesStore.isLoading;
export const selectIsSaving = (state) => state.emailBodiesStore.isSaving;
export const selectSaveError = (state) => state.emailBodiesStore.saveError;

// Don't expose to rest of the app.
const {
  loadEmailBodiesStart,
  loadEmailBodiesSuccess,
  loadEmailBodiesFailure,
  saveEmailBodyStart,
  saveEmailBodySuccess,
  saveEmailBodyFailure,
} = emailBodiesSlice.actions;

export const loadEmailBodies = () => async (dispatch) => {
  dispatch(loadEmailBodiesStart());
  try {
    let pagedDataResponse = await emailBodyApi.getEmailBodies();
    dispatch(loadEmailBodiesSuccess(pagedDataResponse));
  } catch (err) {
    console.error(err);
    dispatch(loadEmailBodiesFailure());
  }
};

export const editEmailBody = (emailBody) => async (dispatch) => {
  dispatch(saveEmailBodyStart());
  try {
    let editedEmailBody = await emailBodyApi.editEmailBody(emailBody);
    dispatch(saveEmailBodySuccess(editedEmailBody));
  } catch (err) {
    console.error(err);
    dispatch(saveEmailBodyFailure({ message: "Error saving" }));
  }
  dispatch(loadEmailBodies({}));
};

export default emailBodiesSlice.reducer;
