import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import { Loader } from "react-bootstrap-typeahead";
import i18n from "../../i18n";
import { IconCheckmark } from "../../assets/icons";

const ConfirmationBox = (props) => {
  const {
    title,
    text,
    buttonText,
    isOpen,
    onConfirm,
    onCancel,
    isValid,
    isProcessing,
    errorText,
    isSuccess,
    successText,
    successTitle,
    onSuccessConfirm,
    successConfirmButtonText,
  } = props;

  return (
    <div className="ConfirmationBox">
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalBody className="">
          {isSuccess && (successTitle || successText) && (
            <div className="text-center d-flex flex-wrap align-items-center pt-5 pb-3">
              <div className="w-100 align-self-end checkmark pt-3">
                <i>
                  <IconCheckmark color="#40b93C" />
                </i>
              </div>
              <h3 className="w-100 font-weight-bold fade-in mt-3">
                {successTitle}
              </h3>
              <p className="w-100 align-self-start fade-in">{successText}</p>
            </div>
          )}
          {!isSuccess && (title || text) ? (
            <div className="text-center d-flex flex-wrap align-items-center pt-5 pb-3">
              {title ? <h3 className="w-100 font-weight-bold">{title}</h3> : ""}
              {text ? <p className="w-100 align-self-start">{text}</p> : ""}
            </div>
          ) : (
            ""
          )}
          {errorText && (
            <div className="text-center text-danger d-flex flex-wrap align-items-center pt-5 pb-3">
              {errorText}
            </div>
          )}
          {props.children}
          {!isSuccess && (
            <div className="d-flex flex-wrap justify-content-between pb-2 pl-4 pr-4">
              <div className="p-1">
                <Button
                  className="mb-2"
                  color="mediumgray"
                  outline
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
              <div className="p-1">
                <Button
                  className="mb-2"
                  color="primary"
                  onClick={onConfirm}
                  disabled={!isValid || isProcessing}
                >
                  {" "}
                  {isProcessing && <Loader color="#2F353A" size="5" />}
                  {buttonText}
                </Button>
              </div>
            </div>
          )}
          {isSuccess && (
            <div className="d-flex flex-wrap justify-content-center pb-2 pl-4 pr-4">
              <div className="p-1">
                <Button
                  className="mb-2"
                  color="primary"
                  onClick={onSuccessConfirm ?? onCancel}
                >
                  {" "}
                  {successConfirmButtonText}
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

ConfirmationBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool,
  isSuccess: PropTypes.bool.isRequired,
  successTitle: PropTypes.string.isRequired,
  successText: PropTypes.string.isRequired,
  onSuccessConfirm: PropTypes.func,
  successConfirmButtonText: PropTypes.string,
  errorText: PropTypes.string.isRequired,
};

ConfirmationBox.defaultProps = {
  buttonText: i18n.t("Submit"),
  isProcessing: false,
  successConfirmButtonText: i18n.t("Ok"),
};

export default ConfirmationBox;
