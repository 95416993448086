import "../../scss/components/transactions/_pending_releases.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../general/Loader";
import { IconDocumentEdit } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import transferRequestApi from "../../api/transferRequest.api";
import { useHistory } from "react-router-dom";
import { Input, Modal, ModalBody } from "reactstrap";
import { IconRemove } from "../../assets/icons";
import {
  formatAccount,
  formatTransactionStatus,
  generateColumn,
  generateActionsColumn,
  formatMoney,
} from "../general/DataTable";

const PendingReleases = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const history = useHistory();

  const [transactions, setTransactions] = useState([]);
  const [showPastDueOnly, setShowPastDueOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const displayTransactionDetails = (row) => {
    history.push("/transferrequest/" + row.id);
  };

  const formatterActions = (_cell, row) => {
    return (
      <div className="icon-action">
        <i onClick={() => displayTransactionDetails(row)}>
          <IconDocumentEdit height={20} />
        </i>
      </div>
    );
  };

  const columns = [
    generateColumn("clientName", t("Client")),
    generateColumn("captiveName", t("Captive Name"), true, {
      minWidth: "265px",
      maxWidth: "265px",
    }),
    generateColumn("captiveCode", t("Captive Code")),
    generateColumn("trustAccountNumber", t("Account #"), false, {}, (_, row) =>
      formatAccount(row.bankName, row.trustAccountNumber)
    ),
    generateColumn("requestedAmount", t("Requested"), false, {}, formatMoney),
    generateColumn("status", t("Status"), false, {}, formatTransactionStatus),
    generateActionsColumn(formatterActions),
  ];

  useEffect(() => {
    if (authContext.isAuthenticated && props.isOpen) {
      setIsLoading(true);
      dispatch(async () => {
        try {
          const requests = await transferRequestApi.getTransactionsRequests({
            OnlyShowPastDue: showPastDueOnly,
          });
          setTransactions(requests.data);
          setIsLoading(false);
          setErrorText("");
        } catch (e) {
          setTransactions([]);
          setIsLoading(false);
          setErrorText(t("Error loading data. Please, try again later."));
        }
      });
    }
  }, [authContext.isAuthenticated, dispatch, props.isOpen, showPastDueOnly, t]);

  const onShowPastDueOnlyChange = (value) => {
    setShowPastDueOnly(value);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      autoFocus
      className="PendingReleasesModal"
    >
      <ModalBody className="">
        <div onClick={props.onCancel} className="close-button text-center">
          <i>
            <IconRemove />
          </i>
        </div>
        <div className="PendingReleases card">
          {errorText && (
            <div className="text-center text-danger">{errorText}</div>
          )}
          <div className="card-header d-flex justify-content-between">
            <h4>{t("Release details")}</h4>
            <h4>
              {props.currentPeriod.toLocaleString(props.culture, {
                month: "long",
              })}{" "}
              {props.currentPeriod.toLocaleString(props.culture, {
                year: "numeric",
              })}
            </h4>
          </div>
          <div className="card-body table-responsive">
            <div className="checkboxes">
              <Input
                type="checkbox"
                id="checkbox-showpastdue"
                name="checkbox"
                checked={showPastDueOnly}
                onChange={(e) => onShowPastDueOnlyChange(e.target.checked)}
              ></Input>
              <label htmlFor="checkbox-showpastdue">
                {t("Only show transfers past due")}
              </label>
            </div>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
              <BootstrapTable
                remote
                keyField={"id"}
                data={transactions}
                columns={columns}
                hover
                condensed
                noDataIndication={
                  isLoading
                    ? t("Loading data...")
                    : t("No data for the selected period.")
                }
                bordered={false}
                hideSizePerPage={true}
              />
            </div>
            {isLoading && <Loader />}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PendingReleases;
