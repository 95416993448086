import api from ".";

const postTransaction = async (data, challengeHistoryId) => {
  const fetchApiUrl = `${api.getApiUrl()}Transaction`;
  return api.post(fetchApiUrl, null, data, {
    "x-2fa-challenge": challengeHistoryId,
  });
};

const getTransaction = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}Transaction/${id}`;
  return api.get(fetchApiUrl, null);
};

const getTransactionsRequests = async (params) => {
  const fetchApiUrl = `${api.getApiUrl()}transaction/requests`;
  return api.get(fetchApiUrl, params, {});
};

const downloadLetter = async (id) => {
  const fetchApiUrl = `${api.getApiUrl()}transaction/${id}/letter`;
  return api.download(fetchApiUrl, null, "application/octet-stream", null, {});
};

export default {
  postTransaction,
  getTransaction,
  getTransactionsRequests,
  downloadLetter,
};
