import React, { useState } from "react";
import "../../scss/login/_login.scss";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { IconDownload, IconArrowLeft } from "../../assets/icons";
import termsAndConditionsApi from "../../api/termsAndConditions.api";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../Auth";
import { useOktaAuth } from "@okta/okta-react";
import { FORMATTED_TEXT } from "../../assets/terms_and_conditions_text";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorText, setErrorText] = useState("");
  const { oktaAuth } = useOktaAuth();

  const mostlyAtTheBottomSafetyFactor = 50; //px
  const [scrolledTillTheEnd, setScrolledTillTheEnd] = useState(false);
  const handleAgreementScroll = (e) => {
    const element = e.target;
    const scrolledTillTheEnd =
      element.scrollHeight - element.scrollTop <=
      element.clientHeight + mostlyAtTheBottomSafetyFactor;
    if (scrolledTillTheEnd) {
      setScrolledTillTheEnd(true);
    }
  };

  const handleAccepted = (e) => {
    e.preventDefault();

    if (authContext.isAuthenticated) {
      dispatch(async () => {
        try {
          const response = await termsAndConditionsApi.markTermsAndConditionsAccepted();
          if (response.success) {
            await authContext.checkTermsAndConditions();
            history.push("/");
          } else {
            setErrorText(
              t("Your acceptance couldn't be saved. Please, try again later.")
            );
          }
        } catch (e) {
          setErrorText(
            t("Your acceptance couldn't be saved. Please, try again later.")
          );
        }
      });
    }
  };

  const isViewOnly = authContext.isAuthenticated === false;

  const navigateToLoginPage = () => {
    if (authContext.isAuthenticated) {
      oktaAuth.signOut("/");
    }
    history.push("/");
  };

  return (
    <div className="terms-and-conditions login login-support">
      <div className="login-footer">
        &copy; {new Date().getFullYear()}{" "}
        {t("Assurant, Inc. All Rights Reserved")}
      </div>
      <div className="okta-wrapper">
        <div id="okta-sign-in" className="auth-container main-container">
          <div className="okta-sign-in-header auth-header login-support-header">
            <img
              src="../images/assurant-logo.svg"
              className="auth-org-logo"
              alt=""
            />
          </div>
          <div className="auth-content">
            <div className="auth-content-inner">
              <h2 className="okta-form-title o-form-head">
                {t("Digital Blueprint")}
              </h2>
              <h3 className="okta-form-subtitle o-form-head">
                {t("Terms of Use")}
              </h3>
              <div>
                {errorText && (
                  <div className="text-center text-danger">{errorText}</div>
                )}
              </div>
              <div>
                <a
                  href="../terms_and_conditions_text.pdf"
                  download="terms_and_conditions.pdf"
                  className="icon-action mb-3 float-right"
                >
                  <IconDownload />
                </a>
              </div>
              <form className="form" onSubmit={handleAccepted}>
                <div
                  className="agreement-text-wrapper"
                  onScroll={handleAgreementScroll}
                >
                  <div
                    className="agreement-text-container"
                    dangerouslySetInnerHTML={{ __html: FORMATTED_TEXT }}
                  ></div>
                </div>
                {!isViewOnly && (
                  <div className="mt-3 text-right">
                    <Button
                      color={scrolledTillTheEnd ? "primary" : "disabled"}
                      disabled={scrolledTillTheEnd === false}
                    >
                      {t("I agree")}
                    </Button>
                  </div>
                )}
              </form>
              <div className="auth-footer row">
                <a
                  className="link help js-back mt-3 text-left custom-button-link"
                  onClick={navigateToLoginPage}
                  href="/login"
                >
                  <i className="mr-2">
                    <IconArrowLeft />
                  </i>
                  {t("BACK")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
