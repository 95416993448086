import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withAuthContext } from "../../Auth";
import AddUsers from "./AddUsers";
import UserPermissions from "./UserPermissions";
import UserConfirm from "./UserConfirm";
import SelectClientPositions from "./SelectClientPositions";
import AlertBox from "../general/AlertBox";
import { withTranslation } from "react-i18next";
import StepIndicator from "../general/StepIndicator";

class InviteUserSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
      permissionGroup: "",
      renderAlertBox: false,
      submitted: false,
    };
  }

  closeAlertBox = () => {
    if (this.state.submitStatus) {
      this.props.history.push("/users");
    }
    this.setState({ renderAlertBox: false });
  };

  render() {
    let { t } = this.props;

    const indicatorSteps = [
      {
        stepLabel: "User Info",
        stepCompleted: false,
        back: "",
        next: "",
      },
      {
        stepLabel: "Permissions",
        stepCompleted: false,
        back: "",
        next: "",
      },
      {
        stepLabel: "Captives",
        stepCompleted: false,
        back: "",
        next: "",
      },
      {
        stepLabel: "Confirm",
        stepCompleted: false,
        back: "",
        next: "",
      },
    ];

    return (
      <>
        <Route exact path="/users/add/info">
          <AddUsers
            stepIndicator={
              <StepIndicator steps={indicatorSteps} currentStep={0} />
            }
          />
        </Route>
        <Route exact path="/users/add/permissions">
          <UserPermissions
            stepIndicator={
              <StepIndicator steps={indicatorSteps} currentStep={1} />
            }
          />
        </Route>
        <Route exact path="/users/add/captives">
          <SelectClientPositions
            stepIndicator={
              <StepIndicator steps={indicatorSteps} currentStep={2} />
            }
            previousStep="/users/add/permissions"
            nextStep="/users/add/confirm"
          />
        </Route>

        <Route exact path="/users/add/confirm">
          <UserConfirm
            stepIndicator={
              <StepIndicator steps={indicatorSteps} currentStep={3} />
            }
          />
        </Route>

        <AlertBox
          title={
            this.state.submitStatus ? t("Invite Sent") : t("Invite Not Sent")
          }
          text={
            this.state.submitStatus
              ? t("An email has been sent to each user.")
              : t("Something went wrong!")
          }
          onClose={this.closeAlertBox}
          isOpen={this.state.renderAlertBox}
          isSuccess={this.state.submitStatus}
        ></AlertBox>
      </>
    );
  }
}

export default withTranslation()(withRouter(withAuthContext(InviteUserSteps)));
