import React from "react";
import { IconCheckCircle } from "../../assets/icons";
import "../../scss/components/general/_step_indicator.scss";

function StepIndicator(props) {
  return (
    <div className="progress-indicator">
      {props.steps.map((step, index) => {
        let completedClass = step.stepCompleted ? "completed" : "";
        let currentClass = "";
        let priorClass = "";

        if (index <= props.currentStep) {
          priorClass = "prior";
        }
        if (index === props.currentStep) {
          currentClass = "current";
        }

        return (
          <div
            key={index}
            className={`step ${completedClass} ${currentClass} ${priorClass}`}
          >
            <div className="step-indicator">
              <hr />
              {step.stepCompleted ? (
                <i className="circle-completed">
                  <IconCheckCircle height="12" color="#fff" />
                </i>
              ) : (
                <div className="circle"></div>
              )}
              <hr />
            </div>
            <div className="step-label">{step.stepLabel}</div>
          </div>
        );
      })}
    </div>
  );
}

export default StepIndicator;
