import { createSlice } from "@reduxjs/toolkit";
import { UserTypes } from "../../Auth";
import inviteUsersApi from "../../api/inviteUsers.api";

export const inviteUsersSlice = createSlice({
  name: "inviteUsersStore",
  initialState: {
    userType: UserTypes.External,
    users: [],
    permissionGroup: "",
    clients: [],
    selectedClients: [],
    loadClientsStatus: {
      isLoading: false,
      isSuccess: false,
      isFailure: false,
    },
    inviteUsersStatus: {
      isLoading: false,
      isSuccess: false,
      isFailure: false,
    },
  },
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    removeUser: (state, action) => {
      state.users = state.users.filter((u) => u.email !== action.payload.email);
    },
    updateUser: (state, action) => {
      state.users = state.users.map((user, i) =>
        i === action.payload.index ? action.payload.user : user
      );
    },
    setPermissionGroup: (state, action) => {
      state.permissionGroup = action.payload;
    },
    loadClientsStart: (state) => {
      state.clients = [];
      state.loadClientsStatus = {
        isLoading: true,
        isSuccess: false,
        isFailure: false,
      };
    },
    loadClientsSuccess: (state, action) => {
      state.clients = action.payload;
      state.loadClientsStatus = {
        isLoading: false,
        isSuccess: true,
        isFailure: false,
      };
    },
    loadClientsFailure: (state) => {
      state.clients = [];
      state.loadClientsStatus = {
        isLoading: false,
        isSuccess: false,
        isFailure: true,
      };
    },
    setSelectedClients: (state, action) => {
      state.selectedClients = action.payload;
    },
    inviteUsersStart: (state) => {
      state.inviteUsersStatus = {
        isLoading: true,
        isSuccess: false,
        isFailure: false,
      };
    },
    inviteUsersSuccess: (state) => {
      state.inviteUsersStatus = {
        isLoading: false,
        isSuccess: true,
        isFailure: false,
      };
    },
    inviteUsersFailure: (state) => {
      state.inviteUsersStatus = {
        isLoading: false,
        isSuccess: false,
        isFailure: true,
      };
    },
    reset: (state) => {
      state.userType = UserTypes.External;
      state.users = [];
      state.permissionGroup = "";
      state.clients = [];
      state.selectedClients = [];
      state.loadClientsStatus = {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
      };
      state.inviteUsersStatus = {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
      };
    },
  },
});

// the state is the Root state, so you need to reference the usersStore here
export const selectUserType = (state) => state.inviteUsersStore.userType;
export const selectUsers = (state) => state.inviteUsersStore.users;
export const selectPermissionGroup = (state) =>
  state.inviteUsersStore.permissionGroup;
export const selectClients = (state) => state.inviteUsersStore.clients;
export const selectClientsStatus = (state) =>
  state.inviteUsersStore.loadClientsStatus;
export const selectSelectedClients = (state) =>
  state.inviteUsersStore.selectedClients;
export const selectInviteUsersStatus = (state) =>
  state.inviteUsersStore.inviteUsersStatus;

export const setUserType = (userType) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.setUserType(userType));
};

export const addUser = (user) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.addUser(user));
};

export const updateUser = (index, user) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.updateUser({ index, user }));
};

export const removeUser = (user) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.removeUser(user));
};

export const setPermissionGroup = (permissionGroup) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.setPermissionGroup(permissionGroup));
};

export const checkEmail = (email, users, indexOfUser) => async (dispatch) => {
  const errorTypes = {
    validation: false,
    network: false,
  };

  // remove selected user from array
  const filteredUsers = users.filter(
    (u, index) => indexOfUser < 0 || index !== indexOfUser
  );

  // if email is found within users (excluding selected user) show alert box
  if (
    filteredUsers &&
    filteredUsers.filter((u) => u.email.toUpperCase() === email.toUpperCase())
      .length
  ) {
    return {
      ...errorTypes,
      validation: true,
    };
  }

  let response;
  try {
    response = await inviteUsersApi.checkEmail(email);
  } catch (err) {
    return {
      ...errorTypes,
      network: true,
    };
  }

  if (
    response?.users &&
    response.users.length &&
    response.users.some(
      (user) => user.email.toLowerCase() === email.toLowerCase()
    )
  ) {
    return {
      ...errorTypes,
      validation: true,
    };
  }

  return true;
};

export const loadClients = () => async (dispatch) => {
  dispatch(inviteUsersSlice.actions.loadClientsStart());

  try {
    const clients = await inviteUsersApi.loadClients();
    dispatch(inviteUsersSlice.actions.loadClientsSuccess(clients));
  } catch (err) {
    dispatch(inviteUsersSlice.actions.loadClientsFailure());
  }
};

export const loadAssurantUsers = (searchData) => async (dispatch) => {
  const { searchCount, searchValue, controller } = searchData;

  let newSearchData = {
    users: [],
    searchValue: "",
    searchCount: 0,
    loading: true,
    controller: null,
    promise: null,
  };

  if (controller) controller.abort();

  if (searchValue) {
    newSearchData = { ...newSearchData, searchCount: searchCount + 1 };

    try {
      const {
        promise,
        controller: newController,
      } = inviteUsersApi.loadAssurantUsers(searchValue);

      newSearchData = {
        ...newSearchData,
        searchValue,
        searchCount: searchCount - 1,
        controller: newController,
        promise,
      };
    } catch (err) {
      newSearchData = {
        ...newSearchData,
        users: [],
        searchValue,
        loading: false,
      };
    }
  } else {
    newSearchData = {
      ...newSearchData,
      users: [],
      searchValue,
      loading: false,
    };
  }

  return newSearchData;
};

export const setSelectedClients = (selectedClients) => (dispatch) => {
  dispatch(inviteUsersSlice.actions.setSelectedClients(selectedClients));
};

export const inviteUsers = (users, permissionGroup, selectedClients) => async (
  dispatch
) => {
  dispatch(inviteUsersSlice.actions.inviteUsersStart());
  try {
    await inviteUsersApi.inviteUsers(users, permissionGroup, selectedClients);
    dispatch(inviteUsersSlice.actions.inviteUsersSuccess());
    dispatch(inviteUsersSlice.actions.reset());
    return true;
  } catch (err) {
    console.error(err);
    dispatch(inviteUsersSlice.actions.inviteUsersFailure());

    return false;
  }
};

export const reset = () => (dispatch) =>
  dispatch(inviteUsersSlice.actions.reset());

export default inviteUsersSlice.reducer;
