import React from "react";

function IconSortArrowDown(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M12 23.997a.755.755 0 01-.26-.047l-.022-.008a.732.732 0 01-.249-.165l-3.749-3.75a.744.744 0 010-1.06.744.744 0 011.06 0l2.47 2.47V.747a.75.75 0 011.5 0v20.689l2.47-2.47a.744.744 0 011.06 0 .749.749 0 010 1.06l-3.75 3.75a.763.763 0 01-.246.164l-.027.01a.725.725 0 01-.257.047z"
      ></path>
    </svg>
  );
}

export default IconSortArrowDown;
