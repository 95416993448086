import React, {useState} from "react";
import "../../scss/components/general/_toggle_button.scss";

const ToggleButton = (props) => {
  const [toggleActive, setToggleActive] = useState(props.toggleActive);

  const onToggle = (e, handleToggleChange) => {
    e.stopPropagation();

    const newState = !toggleActive;
    setToggleActive(newState);
    if(handleToggleChange) {
        handleToggleChange(newState);
    }
  }

  const onOrOff = props.toggleActive ? "on" : "off";

    return (
      <>
        <div className={`toggle-button ${onOrOff}`}>
          <div className="toggle-container" onClick={(e) => onToggle(e, props.handleToggleChange)}>
            <div className="toggle-button"></div>
          </div>
          <div className="toggle-state">{onOrOff}</div>
        </div>
      </>
    );
}

export default ToggleButton;
