import React from "react";

function IconCheckmark(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M6.347 24.003a2.95 2.95 0 01-2.36-1.187L.15 17.7a.748.748 0 01.6-1.2c.235 0 .459.112.6.3l3.839 5.118a1.442 1.442 0 001.42.562c.381-.068.712-.281.933-.599L22.636.32a.748.748 0 111.228.86L8.772 22.739a2.93 2.93 0 01-1.9 1.217c-.173.031-.35.047-.525.047z"
      ></path>
    </svg>
  );
}

export default IconCheckmark;
