import React from "react";

function IconSubtract(props) {
  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        fill={color}
        d="M0.75,12.788c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h22.5c0.414,0,0.75,0.336,0.75,0.75
      s-0.336,0.75-0.75,0.75H0.75z"
      ></path>
    </svg>
  );
}

export default IconSubtract;
