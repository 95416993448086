import { createSlice } from "@reduxjs/toolkit";
import userApi from "../../api/user.api";

export const usersSlice = createSlice({
  name: "usersStore",
  initialState: {
    isLoading: false,
    users: [],
    page: 1,
    totalCount: 0,
    companies: [],
    isLoadingCompanies: false,
    isResendingInvite: false,
    resendInviteId: null,
    resendInviteSuccess: false,
    resendInviteError: null,
    isDeleting: false,
    deleteSuccess: false,
    deletingId: null,
    deleteError: null,
  },
  reducers: {
    loadUsersStart: (state) => {
      state.isLoading = true;
    },
    loadUsersSuccess: (state, action) => {
      state.users = action.payload.users;
      state.totalCount = action.payload.totalCount;
      state.page = action.payload.page + 1;
      state.isLoading = false;
    },
    loadUsersFailure: (state) => {
      state.isLoading = false;
    },
    loadCompaniesStart: (state) => {
      state.isLoadingCompanies = true;
      state.companies = [];
    },
    loadCompaniesSuccess: (state, action) => {
      state.companies = action.payload;
      state.isLoadingCompanies = false;
    },
    loadCompaniesFailure: (state) => {
      state.companies = [];
      state.isLoadingCompanies = false;
    },
    resetResendInvite: (state) => {
      state.isResendingInvite = false;
      state.resendInviteSuccess = false;
      state.resendInviteId = null;
      state.resendInviteError = null;
    },
    resendInviteStart: (state, action) => {
      state.isResendingInvite = true;
      state.resendInviteSuccess = false;
      state.resendInviteId = action.payload.id;
      state.resendInviteError = null;
    },
    resendInviteSuccess: (state) => {
      state.isResendingInvite = false;
      state.resendInviteSuccess = true;
      state.resendInviteId = null;
      state.resendInviteError = null;
    },
    resendInviteFailure: (state, action) => {
      state.isResendingInvite = false;
      state.resendInviteSuccess = false;
      state.resendInviteId = null;
      state.resendInviteError = action.payload.message;
    },
    resetUserDelete: (state) => {
      state.isDeleting = false;
      state.deleteSuccess = false;
      state.deletingId = null;
      state.deleteError = null;
    },
    deleteUserStart: (state, action) => {
      state.isDeleting = true;
      state.deleteSuccess = false;
      state.deletingId = action.payload.id;
      state.deleteError = null;
    },
    deleteUserSuccess: (state) => {
      state.isDeleting = false;
      state.deleteSuccess = true;
      state.deletingId = null;
      state.deleteError = null;
    },
    deleteUserFailure: (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = false;
      state.deletingId = null;
      state.deleteError = action.payload.message;
    },
  },
});

// the state is the Root state, so you need to reference the usersStore here
export const selectUsers = (state) => state.usersStore.users;
export const selectUsersPage = (state) => state.usersStore.page;
export const selectUsersTotal = (state) => state.usersStore.totalCount;
export const selectIsLoading = (state) => state.usersStore.isLoading;
export const selectIsLoadingCompanyNames = (state) =>
  state.usersStore.isLoadingCompanies;
export const selectCompanies = (state) => state.usersStore.companies;
export const selectIsResendingInvite = (state) =>
  state.usersStore.isResendingInvite;
export const selectResendInviteSuccess = (state) =>
  state.usersStore.resendInviteSuccess;
export const selectResendInviteId = (state) => state.usersStore.resendInviteId;
export const selectResendInviteError = (state) =>
  state.usersStore.resendInviteError;
export const selectIsDeleting = (state) => state.usersStore.isDeleting;
export const selectDeleteSuccess = (state) => state.usersStore.deleteSuccess;
export const selectDeleteError = (state) => state.usersStore.deleteError;

// Don't expose to rest of the app.
const {
  loadUsersStart,
  loadUsersSuccess,
  loadUsersFailure,
  loadCompaniesStart,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  resetResendInvite,
  resendInviteStart,
  resendInviteSuccess,
  resendInviteFailure,
  resetUserDelete,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
} = usersSlice.actions;

// Darn you React and your THUNKS!
export const loadUsers = (params) => async (dispatch) => {
  dispatch(loadUsersStart());
  try {
    let pagedDataResponse = await userApi.getUsers(params);
    dispatch(loadUsersSuccess(pagedDataResponse));
  } catch (err) {
    console.error(err);
    dispatch(loadUsersFailure());
  }
};

export const loadCompanies = (params) => async (dispatch) => {
  dispatch(loadCompaniesStart());
  try {
    let dataResponse = await userApi.getCompanies(params);
    dispatch(loadCompaniesSuccess(dataResponse.companies));
  } catch (err) {
    console.error(err);
    dispatch(loadCompaniesFailure());
  }
};

export const resendInvite = (id) => async (dispatch) => {
  dispatch(resendInviteStart({ id }));
  try {
    await userApi.resendInvite(id);
    dispatch(resendInviteSuccess());
  } catch (err) {
    console.error(err);
    dispatch(resendInviteFailure({ message: "Error re-sending invite" }));
  }
  dispatch(loadUsers({})); // TODO: need to remember the parameters here. Will likely need to push that state into the store
};

export const resetResendInviteStatus = () => (dispatch) => {
  dispatch(resetResendInvite());
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch(deleteUserStart({ id }));
  try {
    await userApi.deleteUser(id);
    dispatch(deleteUserSuccess());
  } catch (err) {
    console.error(err);
    dispatch(deleteUserFailure({ message: "Error deleting user" }));
  }
  dispatch(loadUsers({})); // TODO: need to remember the parameters here. Will likely need to push that state into the store
};

export const resetUserDeleteStatus = () => (dispatch) => {
  dispatch(resetUserDelete());
};

export default usersSlice.reducer;
